import React, { useEffect, useState } from "react";

// Tu función asíncrona
const ObtenerDatosSelect = async (params = {})=> {
    const { id, valor = "dominioId" } = params;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        [valor]: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch("https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev/catalog/queryCategoryItems", requestOptions);
        const result = await response.json();
        if (Array.isArray(result.items)) {
            return result.items;
        } else {
            console.error("result.items is not an array:", result.items);
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
};

const OpcionesSelect = (props) => {
  
    const {id, valor} = props;
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await ObtenerDatosSelect({id, valor}); // Llamas a la función con el id pasado como prop
            setItems(data); // Actualizas el estado con los datos obtenidos
        };

        fetchData();
    }, [id]); // Se vuelve a ejecutar cuando `id` cambia

    return (
        <>
            {items.map((data, index) => (
                <option key={index} value={ data.ID}>{data.DESCRIPCION}</option>
            ))}
        </>
    );
};

export default OpcionesSelect;
