import React,{useState} from 'react'
import {Row, Col, Form, Button} from "react-bootstrap"
import { useFormik } from 'formik';
import * as yup from 'yup';
import { View } from '@aws-amplify/ui-react'; 
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';

import { useNavigate } from 'react-router-dom';
import "../../App.css";
const steps = ['Información', 'Formas de pago'];
export const FormaConstanciaPagoVehicular = () => { 
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
    const [metodoPago, setMetodoPago] = useState('pago1');
    
    // eslint-disable-next-line no-unused-vars
    const handleNext = () => {
        setActiveStep((preActiveStep) => preActiveStep + 1); 
    };

    const handleBack = () => {
        setActiveStep((preActiveStep) => preActiveStep - 1); 
    };

    const validationSchema = yup.object().shape({ 
        placaActual : yup.string().required("Este campo es obligatorio"),
        digitosSerie : yup.string(),
    });
    const formik = useFormik({
        validationSchema,
        onSubmit: values => {
            console.log(values);
        },
        initialValues: {
            placaActual: "",
            digitosSerie: ""
        }
    });

    return (
        <>
         <div  className='HeaderTramite'>
                     <View className='FormTitle'>Constancias de Pago Vehicular</View>
                     <View className='FormSubTitle'>Constancias de Pago Vehicular</View>
                     <View className='FormDescripcion'>
                         A personas fisicas, personas morales, unidades economicas y entidades gubernamentales que generen
                         emisiones de gases de efecto invernadero en sus procesos productivos y operaciones dentro del
                         territorio del Estado de Durango, México.
                     </View>
                 </div>
                 <View className="ViewPrincipal">
                     <View className="ViewContent">
                         <Form noValidate onSubmit={formik.handleSubmit} className='ViewActa'>
                         <Box sx={{ width: '100%' }}>
                                 <Stepper activeStep={activeStep} className='mb-3'>
                                     {steps.map((label, index) => {
                                         const stepProps = {};
                                         const labelProps = {};
     
     
                                         return (
                                             <Step key={label} {...stepProps}>
                                                 <StepLabel  {...labelProps}>{label}</StepLabel>
                                             </Step>
                                         );
                                     })}
                                 </Stepper>
                                 {activeStep === steps.length ?
                                     (
                                         <React.Fragment>
                                             <Typography sx={{ mt: 2, mb: 1 }}>
                                                 <label>Descripcion: Pulsa el boton para generar tu orden de pago.</label>
                                             </Typography>
                                             <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ justifyContent: "space-between" }}>
                                                 <Button
                                                     variant='secondary'
                                                     disabled={activeStep === 0}
                                                     onClick={handleBack}
     
                                                 >
                                                     Anterior
                                                 </Button>
     
                                                 <Button type='submit' variant='secondary'>
                                                     Enviar
                                                 </Button>
                                             </Box>
                                         </React.Fragment>
                                     ) : (
                                         <React.Fragment>
                                             {activeStep === 0 ?
                                                 <Box>
                             <Row className='mb-3'>
                                 <Form.Group as={Col} className='position-relative'>
                                     <Form.Label>Placa Actual*</Form.Label>
                                     <Form.Control
                                         type='text'
                                         name='placaActual'
                                         placeholder='Placa Actual'
                                         value={formik.values.placaActual}
                                         onChange={formik.handleChange}
                                         isValid={formik.touched.placaActual && !formik.errors.placaActual}
                                         isInvalid={formik.touched.placaActual && !!formik.errors.placaActual}
                                     />
                                     
                                     <Form.Control.Feedback type='invalid' tooltip>{formik.errors.placaActual}</Form.Control.Feedback>
                                 </Form.Group>
                              
                             </Row>
                             <Row className='mb-3'>
                                 <Form.Group as={Col} className='position-relative'>
                                     <Form.Label>Últimos 5 dígitos del número de serie</Form.Label>
                                     <Form.Control
                                         type='text'
                                         name='digitosSerie'
                                         placeholder='Últimos 5 dígitos del número de serie'
                                         value={formik.values.digitosSerie}
                                         onChange={formik.handleChange}
                                         isValid={formik.touched.digitosSerie && !formik.errors.digitosSerie}
                                         isInvalid={formik.touched.digitosSerie && !!formik.errors.digitosSerie}
                                     />
                                     <Form.Control.Feedback type='invalid' tooltip>{formik.errors.digitosSerie}</Form.Control.Feedback>
                                 </Form.Group>
                             </Row>
                            
                             </Box>
                                                 :
                                                 activeStep === 1 &&
                                                 (<FormPagos metodoPago={metodoPago} setMetodoPago={setMetodoPago}/>)
                                             }
                                         </React.Fragment>
                                     )
     
                                 }
     
                             </Box>
                             <Row className="mb-3 mt-3">
                                 <Form.Group as={Col} className="d-grid gap-2">
                                     <Button type='submit' variant='secondary' >SIGUIENTE</Button>
                                 </Form.Group>
                             </Row>
     
     
                             <Row className="mb-3">
                                 <Form.Group as={Col} className="d-grid gap-2">
                                     <Button onClick={() => navigate("/")} variant="light"> Regresar</Button>
                                 </Form.Group>
     
                             </Row>
                         </Form>
                     </View>
                 </View>
                
        </>
       )
            
}