import React from 'react';
import { GrLanguage } from 'react-icons/gr';

import './Timeline.css'
const TimelineEvent = ({ date, message }) => {
  // Fecha proporcionada
const fechaString = date;


// Formatear la fecha según tus preferencias
const opcionesDeFormato = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true,  // Cambiado a false para usar formato de 24 horas
  timeZone: 'UTC',  // Asegura que la hora se interpreta como UTC
  

};
const formatoFinal = new Intl.DateTimeFormat('es-MX', opcionesDeFormato).format(new Date(fechaString));


  
  
  return (
        <div className="chat-message">
        <div className="icon-container">
          <div className="line"></div>
          <div className="icon">
            <GrLanguage />
          </div>
        </div>
        <div className="content">
          <p className="message" >{message}</p>
          <div className="date">{formatoFinal}</div>
       

        </div>
      </div>
    );
  };

export default TimelineEvent;
