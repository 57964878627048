import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import {getCurrentUser,fetchUserAttributes} from 'aws-amplify/auth';
import { generateClient } from '@aws-amplify/api';
import Row from 'react-bootstrap/Row';
import {  useFormik } from 'formik';
import { publishMessage } from '../../graphql/mutations';
import * as yup from 'yup';
import { Button as ButtonB } from 'react-bootstrap';
import ResumenCard from './ResumenCard';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';
import { onMessageReceived } from '../../graphql/subscriptions';
import { useTramiteDataGeneral } from '../../TramiteContext'
import {
  Button,
  Loader,
  View,
} from "@aws-amplify/ui-react";
import { toast, ToastContainer } from 'react-toastify';
import { Backdrop, CircularProgress } from '@mui/material';
import DeclaracionesAnt from './DeclaracionesAnt';
import ModalActions from './ModalActions';
import NameFieldValid from './NameFieldValid';
import numeral from 'numeral';
import Iframe from 'react-iframe';
import PantallaExitoImpuestos from '../PantallaExitoImpuestos';
import imagenTarjeta from '../../assets/mensaje_tarjeta.png';
import { DeclaracionesAntAnual } from './DeclaracionesAntAnual';


const steps = ['Información', 'Pago'];
const tipo = {
  PRESUPUESTO: 'presupuesto',
  RECIBO: 'Recibo',
  PRE: 'precalculo',
  REFERENCIA: 'pago2',
  PAGO: 'pago1'
}
const client = generateClient();
export default function FormGases() {
  const [activeStep, setActiveStep] = useState(0);
  const [metodoPago, setMetodoPago] = useState('pago1');
  const [skipped, setSkipped] = React.useState(new Set());
  const [infoRFC, setInfoRFC] = useState({});
  const [authToken, setAuthToken] = useState('')
  const [taskToken, setTaskToken] = useState('')
  const { setCalculo, setDeclaracion, calculo } = useTramiteDataGeneral()
  const [calculoData, setCalculoData] = useState({})
  const [declaracionesA, setDeclaracionesA]= useState("");//tipo de declaraciones
  const [declaracionesImp, setDeclaracionesImp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPago, setLoadingPago] = useState(false);
  const [loadingRFC, setLoadingRFC] = useState(false);
  const [idForm, setIdForm] = useState('Not Assign');
  const [recalc, setRecalc] = useState(false)
  const [recalcRFC, setRecalcRFC] = useState(false)
  const [reDecs, setReDecs] = useState(false)
  const [pagoExitoModal, setPagoExitoModal] = useState(false)
  const [pagoFallidoModal, setPagoFallidoModal] = useState(false)
  const [decLimiteAlcanzado, setDecLimiteAlcanzado] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [tipoAccion, setTipoAccion] = useState(tipo.PRE)
  const [candadoFechaAnual, setCandadoFechaAnual] = useState(false)

  //nuevas consultas
  const [payloadDeclaraciones, setPayloadDeclaraciones] = useState(null)
  const [payloadCalculo, setPayloadCalculo] = useState(null)
  const [payloadPago, setPayloadPago] = useState(null)
  const SEI_API_ENDPOINT = process.env.REACT_APP_SEI_API_ENDPOINT 
  const [ligaPagoMit, setLigaPagoMit] = useState(null)
  const [advertenciaPago, setAdvertenciaPago] = useState(null)
  const [continuarPago, setContinuarPago] = useState(false)
  const [pantallaExito, setPantallaExito] = useState(false)
  const [respuestaPago, setRespuestaPago] = useState({});
  const [respuestaRecibo, setRespuestaRecibo] = useState({})
  const [showModal, setShowModal] = useState(false);
//  const [declaracionesMensuales, setDeclaracionesMensuales] = useState([]);

  useEffect(() => {
    generateID()
  }, [])
  const generateID = async () => {
    const user = await getCurrentUser();
    const id = `ImpuestoGases${user.username}_${Date.now()}`
    setIdForm(id)
    return id
  }

  const validationSchema = yup.object().shape({
    RFC: yup.string().required("Este campo es obligatorio").matches(/^([A-Z\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido'),
    anno: yup.string().required("Este campo es obligatorio").matches(/^(19|20)\d{2}$/, 'El año debe estar en el formato YYYY'),
    periodo: yup.string().required("Este campo es obligatorio"),
    compensacion: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo dos decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    multas: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo dos decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    pagosProvisionales: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo dos decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    bioxidoDeCarbono:  yup.number().typeError("El valor debe ser un numero")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    metano: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    oxidoNitroso: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GCn: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GHfc1: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GHfc2: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GHfc3: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GHfc4: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GHfc5: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GHfc6: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GHfc7: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GPfc1: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GPfc2: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GPfc3: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GPfc4: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos'),
    GPfc5: yup.number().typeError("El valor debe ser un número")
      .test('decimal-places', 'Debe tener máximo seis decimales', function (value) {
        if (value !== undefined) {
          return /^[0-9]+(\.[0-9]{0,6})?$/.test(value.toString());
        }
        return true;
      })
      .min(0, 'Los números deben ser positivos')
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: values => {
    },
    initialValues: {
      RFC: '',
      declaraciones: '',
      periodo: '',
      compensacion: "",
      multas:"",
      pagosProvisionales:"",
      bioxidoDeCarbono:"",
      metano:"",
      oxidoNitroso:"",
      GCn:"",//Carbón Negro
      GHfc1:"", //Hidrofluorocarbonos_CHF3
      GHfc2:"",//Hidrofluorocarbonos_CHF2CF3
      GHfc3:"",//Hidrofluorocarbonos_CH2FCF3
      GHfc4:"",//Hidrofluorocarbonos_CH3CHF2
      GHfc5:"",//Hidrofluorocarbonos_CF3CHFCF3
      GHfc6:"",//Hidrofluorocarbonos_CF3CH2CF3
      GHfc7:"",//Hidrofluorocarbonos_CF3CHFCHFCF2CF3
      GPfc1:"",//Perfluorocarbonos_CF4
      GPfc2:"",//Perfluorocarbonos_C2F6
      GPfc3:"",//Perfluorocarbonos_C4F10
      GPfc4:"",//Perfluorocarbonos_C6F14
      GPfc5:""//Perfluorocarbonos_SF6
    }

  });

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with leading zero
    const year = today.getFullYear(); // Get the full year (4 digits)

    return `${day}/${month}/${year}`;
  }
  const resetFormManual = async () => {
    formik.resetForm();
    setReDecs(false)
    setRecalc(false)
    setRecalcRFC(false)
    setCalculo(null)
    setCalculoData(null)
    setDeclaracion(null)
    setDeclaracionesImp([])
    setInfoRFC(null)
    return await generateID();
  }
  const resetFormAnno = (anno) => {
    formik.resetForm();
    setReDecs(false)
    setRecalc(false)
    setCalculo(null)
    setCalculoData(null)
    setDeclaracion(null)
    setDeclaracionesImp([])
    formik.setFieldValue('declaraciones', formik.values.declaraciones)//
    formik.setFieldValue('RFC', infoRFC.rfc)//
    formik.setFieldValue('anno', anno)
    formik.setFieldValue('periodo', "")
  }

  const resetFormPeriodo = (per, anno) => {
    formik.resetForm();
    setReDecs(false)
    setRecalc(false)
    setCalculo(null)
    setCalculoData(null)
    setDeclaracion(null)
    setDeclaracionesImp([])
    formik.setFieldValue('declaraciones', formik.values.declaraciones)//
    formik.setFieldValue('RFC', infoRFC.rfc)
    formik.setFieldValue('periodo', per)
    formik.setFieldValue('anno', anno)
  }



  useEffect(() => {
    if (declaracionesImp && declaracionesImp.length >= 4) {
      setDecLimiteAlcanzado(true)
      setReDecs(false);
    } else {
      setDecLimiteAlcanzado(false);
    }
  }, [declaracionesImp])

  function calcularSumaTotal() {
    // Verificar si declaracionesImp es un arreglo no vacío
    if (Array.isArray(declaracionesImp) && declaracionesImp.length > 0) {
      // Sumar los valores del campo "total"
      const sumaTotal = declaracionesImp.reduce((acumulador, declaracion) => {
        // Convertir el campo "total" a número antes de sumarlo
        const total = parseFloat(declaracion.total) || 0;
        return acumulador + total;
      }, 0);

      return sumaTotal;
    } else {
      // Si el arreglo está vacío o no es un arreglo, retornar 0
      return 0;
    }
  }

  const validarRFCendpoint = async (RFC) => {
    setLoadingRFC(true)
    if (recalcRFC) {
      const rest = await resetFormManual();
      formik.setFieldValue('RFC', RFC)
    }
    const authEndpoint = `${SEI_API_ENDPOINT}/sei/auth`;
    const validateEndpoint = `${SEI_API_ENDPOINT}/sei/declaraciones/validate`;

    const requestData = {
      rfc: RFC,
      clavePadron: "IEGA"
    };

    try {
      // Primera consulta para obtener la autenticación
      const authResponse = await fetch(authEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({})
      });

      if (!authResponse.ok) {
        throw new Error(`Error en la primera consulta: ${authResponse.status}`);
      }

      const authData = await authResponse.json();
      // console.log("DATA consulta", authData);
      setAuthToken(authData.token)
      // Segunda consulta utilizando el token de autenticación obtenido
      const validateResponse = await fetch(validateEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authData.token}`
        },
        body: JSON.stringify(requestData)
      });

      if (!validateResponse.ok) {
        // console.log("VAL", validateResponse);
        const errorData = await validateResponse.json();
        setErrorMessage(errorData.error)
        setPagoFallidoModal(true)
        setRecalcRFC(false)
        throw new Error(`Error en la segunda consulta: ${validateResponse.status}`);
      }

      const validateData = await validateResponse.json();

      // Aquí puedes manejar la respuesta de la segunda consulta (validateData)
      // console.log("Respuesta de la segunda consulta:", validateData);
      setInfoRFC(validateData.response)
      setPayloadDeclaraciones(validateData.payload)
      setRecalcRFC(true)
      setLoadingRFC(false)

     /*  if (validateData.response.periodicidad.tipoPeriodo === "ANUAL") {
        declaracionesEndpoint("", 1, 12, validateData.response, null, authData.token)
      } */
    } catch (error) {
      console.error("Error en las consultas:", error.message);
      setLoadingRFC(false)
    }
  };

  const declaracionesEndpoint = async (mes, start = null, end = null, rfcData = null, task = null, authts = null) => {
    let anno = formik.values.anno
    let taskT = task ? task : taskToken
    let authT = authts ? authts : authToken
    const info = rfcData ? rfcData : infoRFC
    let perio = end ? end : mes
    if (start && end) {
      formik.setFieldValue("periodo", end)
    }
    const data = formik.values
    //console.log(data);
    let mesinicio = 0;
    let mesfin = 0;
    if (formik.values.declaraciones === "MENSUAL") {
      mesinicio = mes
      mesfin = mes
    }

    if (formik.values.declaraciones === "ANUAL" || (start && end)) {
     /*  const an = new Date().getFullYear() */
      mesinicio = 1;
      mesfin = 12;
       /* formik.setFieldValue("anno", an); */
       /* anno = an; */
  }

    const endpoint = `${SEI_API_ENDPOINT}/sei/declaraciones/complementarias`;
    setLoading(true)
    const requestData = {
      padron: {
        id: info.padronesDTO.id,
        nombrePadron: info.padronesDTO.nombre,
        clave: "IEGA"
      },
      periodicidad: {
        id: info.periodicidad.id,
        tipoPeriodo: formik.values.declaraciones,
        valor: info.periodicidad.valor,
        esActivo: true
      },
      mdCaracteristicaId: `${info.mdCaracteristicasImpuestosId}`, // Reemplaza "{{mdCaracteristicaId}}" con el valor real
      rfc: `${info.rfc}`, // Reemplaza "{{rfc}}" con el valor real
      annio: anno,
      mesInicio: validateField(Number(mesinicio), false),
      mesFin: validateField(Number(mesfin), false)
    };
    //console.log("respuesta", requestData);
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authT}`
        },
        body: JSON.stringify(requestData)
      });
      setLoading(false)
      if (!response.ok) {
        const errorData = await response.json();
        console.log("-------ERROR", errorData.error)
        setErrorMessage(errorData.error)
        setShowModal(true)
        setLoading(false)
      }

      const responseData = await response.json();

      responseData.response.declaracionesImpPortal.sort(function (a, b) {
        return a.tipoDeclaracion - b.tipoDeclaracion;
    });
      // Aquí puedes manejar la respuesta de la consulta (responseData)
      console.log("Respuesta de la consulta:", responseData);
      if(formik.values.declaraciones === "ANUAL"){
       
        setDeclaracionesA(responseData.response.pagoProvisional)
        setDeclaracion(responseData.response.declaracionesImpPortal)
      }else{
        setDeclaracion(responseData.response.declaracionesImpPortal)
      }
      setDeclaracionesImp(responseData.response.declaracionesImpPortal)
      setPayloadCalculo(responseData.payload)
      setReDecs(true)
      // if (responseData.response.descuentosImpPortal.length > 0) {
      //   if (responseData.response.descuentosImpPortal[0].referenciaEdoCTA !== null) {
      //     setDescuentoValor(responseData.response.descuentosImpPortal[0].referenciaEdoCTA)
      //     showDescuento(true)
      //   }
      // }
      setLoading(false)
      setLoadingPago(false)
    } catch (error) {
      console.error("Error en la consulta:", error);
    }
  }

  const calcularServicioSei = async (generarEstado = false) => {
    const data = formik.values
      setLoading(true)

      let mesinicio = 0;
      let mesfin = 0;
      if (formik.values.declaraciones === "MENSUAL") {
        mesinicio = data.periodo
        mesfin = data.periodo
      }
      // if (infoRFC.periodicidad.tipoPeriodo === "TRIMESTRAL") {
      //   mesinicio = Number(data.periodo) - 2
      //   mesfin = data.periodo
      // }
      if (formik.values.declaraciones === "ANUAL") {
        mesinicio = 1
        mesfin = 12
      }
      const endpoint = `${SEI_API_ENDPOINT}/sei/declaraciones/calculate`;

      const requestData = {
        padron: {
          id: infoRFC.padronesDTO.id,
          nombrePadron: infoRFC.padronesDTO.nombre,
          clave: "IEGA"
        },
        generaEstadoCuenta: generarEstado,
        mdCaracteristicaId: infoRFC.mdCaracteristicasImpuestosId,
        declaraciones: {

          rfc: infoRFC.rfc,
          annio: validateField(data.anno, false),
          mesInicio: validateField(Number(mesinicio), false),
          mesFin: validateField(Number(mesfin), false),
          compensacion: validateField(Number(data.compensacion)),
          saldoAFavor: validateField(Number(data.saldoFavor)),
          multas: validateField(Number(data.multas)),
          pagosProvisionales: validateField(Number(data.pagosProvisionales)),
          GBc: validateField(Number(data.bioxidoDeCarbono)),
          GMet: validateField(Number(data.metano)),
          GOni: validateField(Number(data.oxidoNitroso)),
          GCn: validateField(Number(data.GCn)),
          GHfc1: validateField(Number(data.GHfc1)),
          GHfc2: validateField(Number(data.GHfc2)),
          GHfc3: validateField(Number(data.GHfc3)),
          GHfc4: validateField(Number(data.GHfc4)),
          GHfc5: validateField(Number(data.GHfc5)),
          GHfc6: validateField(Number(data.GHfc6)),
          GHfc7: validateField(Number(data.GHfc7)),
          GPfc1: validateField(Number(data.GPfc1)),
          GPfc2: validateField(Number(data.GPfc2)),
          GPfc3: validateField(Number(data.GPfc3)),
          GPfc4: validateField(Number(data.GPfc4)),
          GPfc5: validateField(Number(data.GPfc5))
        }

      };  //console.log(requestData);

      try {
        const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}`
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        setLoading(false)
        const errorData = await response.json();
        setErrorMessage(errorData.error)
        setPagoFallidoModal(true)
        throw new Error(`Error en la consulta: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(responseData);

      setCalculo({ ...responseData.response, baseImpuesto: formik.values.base, otrosIncentivos: formik.values.incentivos })
      setCalculoData(responseData.response)
      setPayloadPago(responseData.payload)
      setRecalc(true)
      setLoading(false)
      if (!generarEstado) {
        setLoading(false)
      }
    } catch (error) {
      console.error("Error en la consulta:", error.message);
  }
  };

  const generarTransaccion = async (tipoP) => {
    const user = await fetchUserAttributes();
    try {
      const transaccionEndpoint = `${SEI_API_ENDPOINT}/sei/putEvent`;
      const conceptos = calculoData.conceptos?.map(cp => {
        return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
      })
      const data = formik.values
      let mesinicio = 0;
      let mesfin = 0;
      let anno = formik.values.anno;
      let tipoDec = "";

      if (formik.values.declaraciones === "MENSUAL") {
        mesinicio = data.periodo
        mesfin = data.periodo
        const longitudArreglo = declaracionesImp.length;
        tipoDec = "Mensual " + longitudArreglo;
      }
      // if (infoRFC.periodicidad.tipoPeriodo === "TRIMESTRAL") {
      //   mesinicio = Number(data.periodo) - 2
      //   mesfin = data.periodo
      // }
      if (formik.values.declaraciones === "ANUAL") {
        mesinicio = 1
        mesfin = 12
        tipoDec = "Anual";
        // const an = new Date().getFullYear()
        // formik.setFieldValue("anno", an)
        // anno = an
      }
      

      // if (declaracionesImp.length === 0) {
        
      // } else {
      //   // Obtener la longitud del arreglo
        
      // }

      const response = await fetch(transaccionEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: idForm,
          payload: {
            data: {
              rfc: infoRFC.rfc,
              email: user.email,
              clavePadron: "IEGA",//infoRFC.padronesDTO.id,
              padron: {
                nombrePadron: infoRFC.padronesDTO.nombre
              },
              tipoDeclaracion: tipoDec,
              user:user.name !== undefined ? user.name : "Contribuyente" ,
              estadoCuenta: {
                serie: calculo.estadoCuenta.serie,
                identificador: calculo.estadoCuenta.identificador,
                loteId: calculo.estadoCuenta.loteId,
                tramiteId: calculo.estadoCuenta.tramiteId,
                fechaVencimiento: calculo.estadoCuenta.fechaVencimiento,
                total: calculo.calculos.total
              },
              serie: calculo.estadoCuenta.serie,
              periodicidad: {
                tipoPeriodo: formik.values.declaraciones
              },
              annio: validateField(anno, false),
              mesInicio: validateField(Number(mesinicio), false),
              mesFin: validateField(Number(mesfin), false),
              conceptos: conceptos,
              razonSocial: infoRFC.razonSocial,
              tipoPeriodo: formik.values.declaraciones
            },
            authToken: authToken,
            userId: user.sub,
            type: "declaraciones",
            "validationRequested": true,
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        // Manejar el error según tus necesidades
        console.error("Error en la transacción:", errorData.error);
        setLoadingPago(false)
        return;
      }

      const responseData = await response.json();
      // Manejar la respuesta según tus necesidades
      if (tipoP === tipo.PAGO || tipoP === tipo.RECIBO || tipoP === tipo.REFERENCIA) {
        console.log("RESP");
        const resp = await GenerarLigaPago(tipoP);
        //setLoading(false)
        return responseData
      }
      //setLoading(false)
      return responseData
    } catch (error) {
      setLoadingPago(false);
      console.error("Error al realizar la transacción:", error.message);
      return error
    }
  };

  const llamarPago = async (tipoP) => {
    if (!infoRFC) {
      return
    }
    procesoPago(tipoP)
  }

  const procesoPago = async (tipoP) => {

    if (tipoP === tipo.PAGO || tipoP === tipo.REFERENCIA) {
      const resp = await generarTransaccion(tipoP);
      return
    }
    GenerarLigaPago(tipoP)
  }

  useEffect(() => {
    if (tipoAccion === tipo.PAGO || tipoAccion === tipo.RECIBO || tipoAccion === tipo.REFERENCIA || tipoAccion === tipo.PRESUPUESTO) {

      setTimeout(async () => {
        await llamarPago(tipoAccion);
      }, 2000);
    }
  }, [calculo])
  /*useEffect(() => {
    if (callFinal) {
      if (metodoPago === "pago1") {
        setTipoAccion(tipo.PAGO)
        generarTransaccion(tipo.PAGO)
      } else {
        setTipoAccion(tipo.REFERENCIA)
        generarTransaccion(tipo.REFERENCIA)

      }
      setLoading(true)
      //calcularServicioSei(true);
    }
  }, [callFinal])*/

  const validateField = (value, setString = true) => {
    return value === "" || value === 0 ? null : setString ? `${value}` : Number(value)
  }

  const GenerarLigaPago = async (tipoPago = null) => {
      setLoadingPago(true);
      const user = await fetchUserAttributes();

    // metodoPago : pago1 : card - pago2 : - pago3 :
    // prepara conceptos con lo de calculo
    const opcionPago = tipoPago ? tipoPago : metodoPago
    const conceptos = calculoData?.conceptos?.map(cp => {
      return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
    })
    //preparar otras referencias
    const otrasRef = calculoData?.estadoCuenta?.referencias?.map(referencia => {
      const partes = referencia.recaudacion.split('-');
      const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
      const convenio = parteDespuesDelGuion
      return { banco: referencia.recaudacion, convenio: convenio ? convenio : '', referencia: referencia.referencia }
    })
    let mesinicio = 0;
    let mesfin = 0;
    let tipoDec = "";
    if (formik.values.declaraciones === "MENSUAL") {
      mesinicio = formik.values.periodo
      mesfin = formik.values.periodo
      const longitudArreglo = declaracionesImp.length;
      if (declaracionesImp.length > 0 && declaracionesImp[0].id === null) {
        tipoDec = "Normal"
      } else {
        // Obtener la longitud del arreglo
        const longitudArreglo = declaracionesImp.length;
        tipoDec = "Complementaria " + longitudArreglo
      } 
     
    }

    if (formik.values.declaraciones === "ANUAL") {
      mesinicio = 1
      mesfin = 12
      tipoDec = "Anual";
    }


    const variables = opcionPago === "pago1" ?
      {
        "id": idForm,
        "payload": {
          "paymentMethod": "card",
          "user":user.name !== undefined ? user.name : "Contribuyente" ,
          "email": user.email,
          "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO DE EMISIÓN DE GASES A LA ATMÓSFERA",
          "reference": `${calculoData.estadoCuenta?.referencias[0].referencia}`,//"202300011471739766260",
          "monto": `${calculoData.calculos?.total}`,//"0.01",
          authToken,
          taskToken,
          type: "declaraciones",
        }
      }
      :
      opcionPago === "pago2" || opcionPago === "pago3" || opcionPago === "pago4" ?
      {
        "id": idForm,
        "payload": {

          "paymentMethod": "spei",
          "data": {
            "user":user.name !== undefined ? user.name : "Contribuyente" ,
            "loteId": `${calculoData.estadoCuenta?.loteId}`,
            "total_pagar": calculoData.calculos?.total,
            "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
            "movimiento": "IMPUESTO A LA EMISION DE GASES",
            "rfc": infoRFC.rfc,
            "fecha_generacion": getCurrentDate(),
            "fecha_vigencia": `${formatearFecha(calculoData.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
            "contribuyente": infoRFC?.rfc?.length === 13 ? infoRFC.nombre : infoRFC.razonSocial,
            "referencia": `${calculoData.estadoCuenta?.referencias[0].referencia}`,
            "otras_referencias": otrasRef,
            //"periodo":infoRFC.periodicidad.tipoPeriodo === "MENSUAL" ? `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}` :`${nombresMeses[Number(mesinicio) - 1]}-${nombresMeses[Number(mesfin) - 1]} ${formik.values.anno}`,
            "periodo": infoRFC.periodicidad.tipoPeriodo === "MENSUAL" || infoRFC.periodicidad.tipoPeriodo === "ANUAL" ? `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}` : `${nombresMeses[Number(mesinicio) - 1]}-${nombresMeses[Number(mesfin) - 1]} ${formik.values.anno}`,


          },
          "email": user.email,
          "procedure": "referencia_pago"
        }
      }
        : opcionPago === "Recibo" ?
        {
          "id": idForm,
          "payload": {
            "data": {
              user: user.name !== undefined ? user.name : "Contribuyente",
              email: user.email,
              clavePadron: "IEGA",//infoRFC.padronesDTO.id,
              padron: {
                nombrePadron: infoRFC.padronesDTO.nombre
              },
              tipoDeclaracion: tipoDec,
              estadoCuenta: {
                serie: calculo.estadoCuenta.serie,
                identificador: calculo.estadoCuenta.identificador,
                loteId: calculo.estadoCuenta.loteId,
                tramiteId: calculo.estadoCuenta.tramiteId,
                fechaVencimiento: calculo.estadoCuenta.fechaVencimiento
              },
              serie: calculo.estadoCuenta.serie,
              periodicidad: {
                tipoPeriodo: infoRFC.periodicidad.tipoPeriodo
              },
              annio: validateField(formik.values.anno, false),
              mesInicio: validateField(Number(mesinicio), false),
              mesFin: validateField(Number(mesfin), false),
              conceptos: conceptos,
              razonSocial: infoRFC.razonSocial,
              tipoPeriodo: infoRFC.periodicidad.tipoPeriodo,
              "docData": {
                "user": user.name !== undefined ? user.name : "Contribuyente",
                "loteId": `${calculoData.estadoCuenta?.loteId}`,
                "identificador": `${calculoData.estadoCuenta?.identificador}`,
                "serie": `${calculoData.estadoCuenta?.serie}`,
                "contribuyente": infoRFC?.rfc?.length === 13 ? infoRFC.nombre : infoRFC.razonSocial,
                "fecha_generacion": getCurrentDate(),
                "fecha_pago": getCurrentDate(),
                "estatus": "PAGADO",
                //"num_transaccion": calculoData.estadoCuenta?.loteId,
                "rfc": infoRFC.rfc,
                "referencia": `${calculoData.estadoCuenta?.referencias[0].referencia}`,
                "fecha_vigencia": `${formatearFecha(calculoData.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
                "movimiento": "IMPUESTO DE EMISIÓN DE GASES A LA ATMÓSFERA",
                "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO DE EMISIÓN DE GASES A LA ATMÓSFERA",
                "tramiteId": calculoData.estadoCuenta?.tramiteId,
                //"num_empleados": null,
                "instrumento_pago": "referencia bancaria",
                "base_impuesto": `${formik.values.base}`,
                "periodo":infoRFC.periodicidad.tipoPeriodo === "MENSUAL" ? `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}` :`${nombresMeses[Number(mesinicio) - 1]}-${nombresMeses[Number(mesfin) - 1]} ${formik.values.anno}`,
                "tipo_declaracion": tipoDec,//infoRFC.periodicidad.tipoPeriodo,
                "otros_conceptos": conceptos,
                "total": `${calculoData.calculos?.total}`,
                "saldo_favor":`${calculoData.calculos?.saldoAFavor !== undefined ? calculoData.calculos?.saldoAFavor : "0"}`,

              }
            },
            "email": user.email,
            "procedure": "recibo_pago",
            "validationRequested": true,
            "recalculate": false,
            "type": "declaraciones",
            userId: user.sub,
            authToken,
            taskToken
          },
        }
          :
          {
            "id": idForm,
            "payload": {
              "paymentMethod": "spei",
              "data": {
                "user":user.name !== undefined ? user.name : "Contribuyente" ,
                "anno": validateField(formik.values.anno, false),
                "contribuyente": infoRFC?.rfc?.length === 13 ? infoRFC.nombre : infoRFC.razonSocial,
                "fecha_generacion": getCurrentDate(),
                "rfc": infoRFC.rfc,
                "bioxido_carbono": formik.values.bioxidoDeCarbono || "0",
                "metano": formik.values.metano || "0",
                "oxido_nitroso": formik.values.oxidoNitroso || "0",
                "carbono_negro": formik.values.GCn || "0",
                "CHF3": formik.values.GHfc1 || "0",
                "CHF2CF3": formik.values.GHfc2 || "0",
                "CH2FCF3": formik.values.GHfc3 || "0",
                "CH3CHF2": formik.values.GHfc4 || "0",
                "CF3CHFCF3": formik.values.GHfc5 || "0",
                "CF3CH2CF3": formik.values.GHfc6 || "0",
                "CF3CHFCHFCF2CF3": formik.values.GHfc7 || "0",
                "CF4": formik.values.GPfc1 || "0",
                "C2F6": formik.values.GPfc2 || "0",
                "C4F10": formik.values.GPfc3 || "0",
                "C6F14": formik.values.GPfc4 || "0", 
                "SF6": formik.values.GPfc5 || "0",
                "fecha_vigencia": `${formatearFecha(calculoData?.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
                "movimiento": "IMPUESTO DE EMISIÓN DE GASES A LA ATMÓSFERA",
                "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO DE EMISIÓN DE GASES A LA ATMÓSFERA",
                "instrumento_pago": "referencia bancaria",
                "periodo": tipoDec === "Anual" ? `${formik.values.anno}` : `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}`,
                "tipo_declaracion": tipoDec,
                "otros_conceptos": conceptos,
                "pagoProvisional": declaracionesA  ,
                "total_pagar": `${calculoData?.calculos?.total}`,
                "monto_anterior": calcularSumaTotal(),
              },
              "email": user.email,
              "procedure": "presupuesto_gases"
            }
           
          }; 

    const Data = await client.graphql({
      query: publishMessage,
      variables: {
        ...variables,
        payload: JSON.stringify(variables.payload),
      },
    }); //console.log("payload", Data)
  }
  const formatearFecha = (fecha) => {
    if (fecha) {
      const date = new Date(fecha);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return null;
  };
 
  const handleNext = async () => {
    await generateID(); 
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  let nombresMeses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const [message, setMessage] = useState(null);
  useEffect(() => {
    const subscription = client.graphql({ query: onMessageReceived, variables: { id: idForm } })
      .subscribe({
        next: (event) => {
          const receivedMessage = event.data.onMessageReceived;
          //parse json on payload response. - authToken - taskToken --- rfc
          setMessage(receivedMessage);
          const dataRec = JSON.parse(receivedMessage.payload)
          if (dataRec.taskToken) {
            setTaskToken(dataRec.taskToken)
          }
          if (dataRec?.error) {
            setErrorMessage(dataRec?.error)
            setPagoFallidoModal(true)
            if (dataRec.step === "validarRfc") {
              setRecalcRFC(false)
              setLoading(false)
            }
            //alert("Hubo un error intentalo de nuevo")
            return
          }
          if (dataRec.status === "PAGO VALIDADO") {
            //activar loader
            console.log("Validar pago");
            setRespuestaPago(dataRec)
            setLoadingPago(true)

          }
          if (dataRec?.procedure === "paymentUrl") {
            if (dataRec.response.cd_response === "error") {
              //AlertsAction("error")
              setPagoExitoModal(false)
              setPagoFallidoModal(true)
              setLoadingPago(false);
              setLoading(false)
              return
            }
            if (dataRec.response.cd_response === "success") {
              //AlertsAction("success")
              //setPagoExitoModal(true)
              setPagoFallidoModal(false)
              setLigaPagoMit(dataRec.response.nb_url)
              setLoadingPago(false);
              setLoading(false);
            }
          }
          if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_gases" || dataRec.Procedure === "recibo_pago") {
            if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_gases") {
              window.open(dataRec.TXTBody);
              setLoadingPago(false)
            }
            if (dataRec.Procedure === "recibo_pago") {
              //activar vista de exito
              setPantallaExito(true)
              setRespuestaRecibo(dataRec)
              setLoadingPago(false)
              setLoading(false)
              return
            }
            if (dataRec.Procedure === "referencia_pago") {
              setLoadingPago(false)
              setLoading(false)
              setPagoExitoModal(true)
              setPagoFallidoModal(false)
              return
            }/* else {
              setCallFinal(true)
            }*/
            return
          }
        },
        error: (error) => {
          console.error('Subscription error', error);
        },
      });


    return () => {
      subscription.unsubscribe();
    };
  }, [idForm]);

  const handleBlur = field => {
    formik.setFieldTouched(field, true);
  };
  function validarCadena(cadena) {
    const expresionRegular = /^([A-Z\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/;

    return expresionRegular.test(cadena);
  }
  const handleRFCChange = (e) => {
    const { name, value } = e.target;

    if (name === "RFC") {
      formik.setFieldValue(name, value.toUpperCase());
      if (value.length === 12 && name === "RFC") {
        if (validarCadena(value)) {
          validarRFCendpoint(value)
          formik.values.RFC = value
        } else {
          formik.values.RFC = value
          formik.setFieldError(name, "RFC no valido");
        }
      } else if (value.length === 13 && name === "RFC") {
        if (validarCadena(value)) {
          formik.values.RFC = value
          validarRFCendpoint(value)
        } else {
          formik.values.RFC = value
          formik.setFieldTouched(name, true)
        }
      }
    }
    /* onBlur={() => { handleBlur('RFC'); validarRFCendpoint(formik.values.RFC); }} */
  };

  const validateRFC = (value) => {
    // Asegúrate de que la longitud no supere 13 caracteres
    if (value.length > 13) {
      return value.slice(0, 13); // Truncar la cadena a 13 caracteres
    }
    return value.trim().toUpperCase();
  };

  const generarOpcionesMeses = () => {
    const annoSeleccionado = Number(formik.values.anno) || 2023
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth(); // Sumamos 1 porque los meses van de 0 a 11 en JavaScript

    // Verificar si el año seleccionado es igual al año actual
    if (annoSeleccionado === fechaActual.getFullYear()) {
      // Si es igual, solo regresar opciones para los meses antes del mes actual
      const opciones = Array.from({ length: mesActual }, (_, index) => (
        <option key={index + 1} value={index + 1}>{obtenerNombreMes(index + 1)}</option>
      ));

      return opciones;
    } else {
      // Si es diferente, regresar todas las opciones
      const opciones = Array.from({ length: 12 }, (_, index) => (
        <option key={index + 1} value={index + 1}>{obtenerNombreMes(index + 1)}</option>
      ));

      return opciones;
    }
  }

  // const generarOpcionesTrimestres = () => {
  //   const annoSeleccionado = Number(formik.values.anno) || 2023
  //   const fechaActual = new Date();
  //   const mesActual = fechaActual.getMonth(); // Sumamos 1 porque los meses van de 0 a 11 en JavaScript
  //   const trimestres = [3, 6, 9, 12];

  //   // Filtrar trimestres disponibles que sean antes del mes actual
  //   const trimestresDisponibles = trimestres.filter(trimestre => trimestre < mesActual);

  //   // Verificar si el año seleccionado es igual al año actual
  //   if (annoSeleccionado === fechaActual.getFullYear()) {
  //     // Si es igual, solo regresar opciones para los trimestres disponibles
  //     const opciones = trimestresDisponibles.map(trimestre => (
  //       <option key={trimestre} value={trimestre}>
  //         {obtenerNombreMes(trimestre - 2)} - {obtenerNombreMes(trimestre)}
  //       </option>
  //     ));

  //     return opciones;
  //   } else {
  //     // Si es diferente, regresar todas las opciones
  //     const opciones = trimestres.map(trimestre => (
  //       <option key={trimestre} value={trimestre}>
  //         {obtenerNombreMes(trimestre - 2)} - {obtenerNombreMes(trimestre)}
  //       </option>
  //     ));

  //     return opciones;
  //   }
  // }
  const handleInputChange = (e) => {
    const {name,value} = e.target.value;

    // Truncar o redondear el valor a 6 decimales
    const truncatedValue = parseFloat(value).toFixed(6);

    // Actualizar el estado solo si el valor no excede los 6 decimales
    if (!isNaN(truncatedValue)) {
      formik.setFieldValue(name, value);
    }
  };
  function obtenerNombreMes(mes) {
    const nombresMeses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    return nombresMeses[mes - 1];
  }
  const [band, setBand]= useState(true);
  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    if (name === "anno") {
      formik.setFieldValue(name, value)
      formik.values.anno = value;
      resetFormAnno(formik.values.anno); handleBlur('anno')
      if(formik.values.declaraciones === "ANUAL"){
        console.log("----------",value);
        declaracionesEndpoint(value);
      }
    } else {
      formik.setFieldValue(name, value)
      formik.values.periodo = value;

      formik.setFieldTouched(name, true, false); // Tercer argumento a `false` evita la validación hasta que se haga un nuevo cambio

      handleBlur('periodo');
      resetFormPeriodo(formik.values.periodo, formik.values.anno);
      declaracionesEndpoint(value);

    }

    // Puedes realizar otras acciones aquí según tus necesidades */
  };
/*   const handleChangeSelectDec = async (event, fieldName) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    setDeclaracionesM(value);
    //const info = rfcData ? rfcData : infoRFC;

    if (formik.values.RFC && fieldName === "anno" && formik.values.declaraciones === "ANUAL") {
        setShowModal(true);
    } else {
        setShowModal(false);
    }

    //console.log("periodo", info.periodicidad);
};
    console.log("periodo", info.periodicidad);
}; */


  const renderPeriodos = (tipo) => {
    if (tipo === "MENSUAL") {
      return (
        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="validationFormik102"
            className="position-relative"
          >
            <Form.Label> Año*</Form.Label>
            <Form.Select
              type="number"
              name="anno"
              value={formik.values.anno}
              onChange={handleChangeSelect}
              isValid={
                formik.touched.anno && !formik.errors.anno
              }
              isInvalid={
                !!formik.errors.anno && formik.touched.anno
              }
              disabled={ !recalcRFC || loading}
            /*  onBlur={() => { resetFormAnno(formik.values.anno); handleBlur('anno') }} */
            >
              <option key="default" value="">
                Seleccione...
              </option>
              {Array(2).fill(undefined).map((_, index) => (
                <option key={index} value={2024 - index}>
                  {2024 - index}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" tooltip></Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.anno}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="validationFormik102"
            className="position-relative"
          >
            <Form.Label> Periodo*</Form.Label>
            <Form.Select
              name="periodo"
              value={formik.values.periodo}
              onChange={handleChangeSelect}
              isValid={
                formik.touched.periodo &&
                !formik.errors.periodo
              }
              isInvalid={
                !!formik.errors.periodo && formik.touched.periodo
              }
              disabled={!recalcRFC || formik.values.anno === '' || loading || formik.values.declaraciones === 'ANUAL'}
            >
              <option>Seleccione...</option>
              {generarOpcionesMeses()}
            </Form.Select>

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.periodo}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      )}

    if (tipo === "ANUAL") {
      return (
        <Row className="mb-3">

          <Form.Group
            as={Col}
            controlId="validationFormik102"
            className="position-relative"
          >
            <Form.Label> Año*</Form.Label>
            <Form.Select
              type="number"
              name="anno"
              value={formik.values.anno}
              onChange={handleChangeSelect}
             /*  onChange={(event) => {
                formik.setFieldValue("anno", event.target.value);
                handleChangeSelectDec(event, "anno");
            }} */
              isValid={
                formik.touched.anno && !formik.errors.anno
              }
              isInvalid={
                !!formik.errors.anno && formik.touched.anno
              }
              disabled={ !recalcRFC || loading}
            /*  onBlur={() => { resetFormAnno(formik.values.anno); handleBlur('anno') }} */
            >
               <option key="default" value="">
                Seleccione...
              </option>
              {Array(1).fill(undefined).map((_, index) => (
                <option key={index} value={new Date().getFullYear()-1  - index}>
                  {new Date().getFullYear()-1  - index}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" tooltip></Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.anno}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      )
    }
    return (<div className='w-full flex justify-center text-sm text-gray-300'>No hay periodos correspondientes</div>)
  }

  return (
    <>
      <div className="HeaderTramite">
        <View className="FormTitle">Impuesto de Emisión de Gases a la Atmósfera</View>
        <View className="FormDescripcion">
        Consiste en el cumplimiento de una obligación fiscal a nivel estatal,
        que se aplica a empresas o actividades industriales
        debido a la emisión de gases que tienen un impacto en la calidad del aire y causan daños al medio ambiente.
        </View>
      </div>
      <View className="ViewPrincipal">
        {
          pantallaExito ?
            <PantallaExitoImpuestos pago={respuestaPago} recibo={respuestaRecibo} titulo={'Impuesto De Emisión De Gases A La Atmósfera'} />
            :
            ligaPagoMit ?
              <View className="FormContent">
                <div className='w-full flex justify-end'><img width={'300px'} height={'150px'} src={imagenTarjeta} alt="Mensaje tarjeta" /></div>
                <Iframe
                  src={ligaPagoMit}
                  height="850px"
                  width="550px"
                  display="initial"
                  styles={{ overflow: "hidden" }}
                  position="relative"
                />
              </View>
              :
              <View className="FormContent">
                <Form
                  onSubmit={formik.handleSubmit}
                  noValidate
                  className="ViewActa"
                >
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          <label>
                            Descripcion: Pulsa el botón para generar tu orden de
                            pago.
                          </label>
                        </Typography>
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                          style={{ justifyContent: "space-between" }}
                        >
                          <Button
                            variant="secondary"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Anterior
                          </Button>

                          <Button type="submit" variant="secondary">
                            Enviar
                          </Button>
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {activeStep === 0 ? (
                          <Box>
                            <Col>
                              <Row className="mb-3 mt-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormikRFC"
                                  className="position-relative"
                                >
                                  <Form.Label>RFC con Homoclave*</Form.Label>
                                  <div style={{ position: 'relative' }}>
                                    <Form.Control
                                      type="text"
                                      name="RFC"
                                      maxLength={13}
                                      disabled={loading}
                                      value={formik.values.RFC}
                                      onChange={handleRFCChange}
                                      /* onBlur={() => { handleBlur('RFC'); validarRFCendpoint(formik.values.RFC); }} */
                                      isValid={
                                        formik.touched.RFC && !formik.errors.RFC
                                      }
                                      isInvalid={
                                        !!formik.errors.RFC && formik.touched.RFC
                                      }
                                    />
                                    {loadingRFC ? (

                                      <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                        <Loader size='large' emptyColor="lightgray" filledColor="gray" />
                                      </div>

                                    ) : null}

                                  </div>
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.RFC}
                                  </Form.Control.Feedback>
                                  {infoRFC?.rfc?.length === 13 && infoRFC?.nombre && (<NameFieldValid nombre={infoRFC?.nombre} domicilio={`${infoRFC.calle} #${infoRFC.numeroExterior}, ${infoRFC.colonia}, C.P. ${infoRFC.codigoPostal}, ${infoRFC.localidad}, ${infoRFC.municipio} `} />)}
                                  {infoRFC?.rfc?.length === 12 && infoRFC?.razonSocial && (<NameFieldValid nombre={infoRFC?.razonSocial} domicilio={`${infoRFC.calle} #${infoRFC.numeroExterior}, ${infoRFC.colonia}, C.P. ${infoRFC.codigoPostal}, ${infoRFC.localidad}, ${infoRFC.municipio} `} />)}
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                              {loading ? (
                                <div >
                                  <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                                </div>

                                ) : null}

                              <Form.Group
                               as={Col}
                               controlId="validationFormik102"
                               className="position-relative"
                               >
                              <Form.Label> Tipo de Declaración*</Form.Label>
                             <Form.Select
                              name="declaraciones"
                              value={formik.values.declaraciones}
                              onChange={formik.handleChange}
                             /*  onChange={(event) => {
                                formik.setFieldValue("anno", "");
                                handleChangeSelectDec(event, "declaraciones");
                                }} */
                              isValid={formik.touched.declaraciones && !formik.errors.declaraciones}
                              isInvalid={!!formik.errors.declaraciones && formik.touched.declaraciones}
                              disabled={!recalcRFC || loading}
                             >
                              <option value="">Seleccione...</option>
                              <option value="MENSUAL">Mensual</option>
                              <option value="ANUAL">Anual</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid" tooltip>{formik.errors.declaraciones}</Form.Control.Feedback>
                              </Form.Group>
                               </Row>
                              {renderPeriodos(formik.values.declaraciones)}

                                { declaracionesA !== "" ?
                                <>
                                {console.log(declaracionesA)}
                                 <Row
                       
                        className='border border-gray-300 rounded-md flex justify-center text-center cursor-pointer hover:shadow-lg'>
                        <Col
                        
                            className=' h-14 rounded-md flex items-center justify-center p-2 m-1'
                        >Pagos Provisionales:</Col>
                        <Col
                            
                            className=' h-14 rounded-md flex items-center justify-center p-2 m-1'
                        >{numeral(declaracionesA).format('$0,0.00')}</Col>
                    </Row>
                    <DeclaracionesAnt className='' tipo='gases' annio={formik.values.anno} periodo={formik.values.periodo}/>
                                </>
                             /*  <DeclaracionesAntAnual className='' tipo='gases' annio={formik.values.anno} periodo={formik.values.periodo}/> */
                              :
                              <DeclaracionesAnt className='' tipo='gases' annio={formik.values.anno} periodo={formik.values.periodo}/>
                                 }

                                 {formik.values.anno === "2023" && (
                                  <>
                              <Row className="mb-3">
                              <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                              Base gravable
                              </p>
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>Bióxido de carbono</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="bioxidoDeCarbono"

                                    value={formik.values.bioxidoDeCarbono}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.bioxidoDeCarbono &&
                                      !formik.errors.bioxidoDeCarbono
                                    }
                                    isInvalid={
                                      !!formik.errors.bioxidoDeCarbono &&
                                      formik.touched.bioxidoDeCarbono
                                    }
                                    //disabled={!reDecs || decLimiteAlcanzado || loading}
                                    onBlur={() => {
                                      handleBlur('bioxidoDeCarbono');
                                      setTipoAccion(tipo.PRE)
                                      calcularServicioSei()
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.bioxidoDeCarbono}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>Metano</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="metano"

                                    value={formik.values.metano}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.metano &&
                                      !formik.errors.metano
                                    }
                                    isInvalid={
                                      !!formik.errors.metano &&
                                      formik.touched.metano
                                    }
                                    //disabled={!reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('metano');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.metano}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>Óxido nitroso</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="oxidoNitroso"

                                    value={formik.values.oxidoNitroso}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.oxidoNitroso &&
                                      !formik.errors.oxidoNitroso
                                    }
                                    isInvalid={
                                      !!formik.errors.oxidoNitroso &&
                                      formik.touched.oxidoNitroso
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('oxidoNitroso');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.oxidoNitroso}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label> Compensación</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="compensacion"
                                    //disabled={ !reDecs || loading}
                                    value={formik.values.compensacion}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.compensacion &&
                                      !formik.errors.compensacion
                                    }
                                    isInvalid={
                                      !!formik.errors.compensacion &&
                                      formik.touched.compensacion
                                    }

                                    onBlur={() => {
                                      handleBlur('compensacion');
                                      setTipoAccion(tipo.PRE)
                                      calcularServicioSei()
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.compensacion}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              {formik.values.declaraciones === 'ANUAL' &&
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label> Pagos provisionales</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="pagosProvisionales"
                                    //disabled={ !reDecs || loading}
                                    value={formik.values.pagosProvisionales}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.pagosProvisionales &&
                                      !formik.errors.pagosProvisionales
                                    }
                                    isInvalid={
                                      !!formik.errors.pagosProvisionales &&
                                      formik.touched.pagosProvisionales
                                    }

                                    onBlur={() => {
                                      handleBlur('pagosProvisionales');
                                      setTipoAccion(tipo.PRE)
                                      calcularServicioSei()
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.pagosProvisionales}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                            }
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>Multas</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="multas"
                                    value={formik.values.multas}

                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.multas && !formik.errors.multas
                                    }
                                    isInvalid={
                                      !!formik.errors.multas && formik.touched.multas
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('multas');
                                      setTipoAccion(tipo.PRE)
                                      calcularServicioSei()
                                    }}
                                  />
                                  {/*<CurrencyInput
                                  id="input-example"
                                  name="base"
                                  className='border border-solid border-gray-300 w-full rounded-md h-10 focus:outline-none focus:border-blue-500 focus:shadow-outline-purple'
                                  placeholder="Your text here"
                                  defaultValue={formik.values.base}
                                  decimalsLimit={2}
                                  onValueChange={(value,name)=>formik.setFieldValue(name,value,true)}
                                  prefix='$'
                                />*/}
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.multas}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                                </>
                                 )}

                              {/*GASES 2024*/}
                              {formik.values.anno >= "2024" && (
                                  <>
                              <Row className="mb-3">
                              <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                              Base gravable
                              </p>
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>Bióxido de carbono</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="bioxidoDeCarbono"

                                    value={formik.values.bioxidoDeCarbono}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.bioxidoDeCarbono &&
                                      !formik.errors.bioxidoDeCarbono
                                    }
                                    isInvalid={
                                      !!formik.errors.bioxidoDeCarbono &&
                                      formik.touched.bioxidoDeCarbono
                                    }
                                    //disabled={!reDecs || decLimiteAlcanzado || loading}
                                    onBlur={() => {
                                      handleBlur('bioxidoDeCarbono');
                                      setTipoAccion(tipo.PRE)
                                      calcularServicioSei()
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.bioxidoDeCarbono}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>Metano</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="metano"

                                    value={formik.values.metano}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.metano &&
                                      !formik.errors.metano
                                    }
                                    isInvalid={
                                      !!formik.errors.metano &&
                                      formik.touched.metano
                                    }
                                    //disabled={!reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('metano');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.metano}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>Óxido nitroso</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="oxidoNitroso"

                                    value={formik.values.oxidoNitroso}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.oxidoNitroso &&
                                      !formik.errors.oxidoNitroso
                                    }
                                    isInvalid={
                                      !!formik.errors.oxidoNitroso &&
                                      formik.touched.oxidoNitroso
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('oxidoNitroso');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.oxidoNitroso}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>Carbono negro</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GCn"

                                    value={formik.values.GCn}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GCn &&
                                      !formik.errors.GCn
                                    }
                                    isInvalid={
                                      !!formik.errors.GCn &&
                                      formik.touched.GCn
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GCn');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GCn}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                              <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                              Hidrofluorocarbonos
                              </p>
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>CHF3</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GHfc1"

                                    value={formik.values.GHfc1}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GHfc1 &&
                                      !formik.errors.GHfc1
                                    }
                                    isInvalid={
                                      !!formik.errors.GHfc1 &&
                                      formik.touched.GHfc1
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GHfc1');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GHfc1}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>CHF2CF3</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GHfc2"

                                    value={formik.values.GHfc2}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GHfc2 &&
                                      !formik.errors.GHfc2
                                    }
                                    isInvalid={
                                      !!formik.errors.GHfc2 &&
                                      formik.touched.GHfc2
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GHfc2');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GHfc2}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>CH2FCF3</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GHfc3"

                                    value={formik.values.GHfc3}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GHfc3 &&
                                      !formik.errors.GHfc3
                                    }
                                    isInvalid={
                                      !!formik.errors.GHfc3 &&
                                      formik.touched.GHfc3
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GHfc3');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GHfc3}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>CH3CHF2</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GHfc4"

                                    value={formik.values.GHfc4}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GHfc4 &&
                                      !formik.errors.GHfc4
                                    }
                                    isInvalid={
                                      !!formik.errors.GHfc4 &&
                                      formik.touched.GHfc4
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GHfc4');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GHfc4}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>CF3CHFCF3</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GHfc5"

                                    value={formik.values.GHfc5}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GHfc5 &&
                                      !formik.errors.GHfc5
                                    }
                                    isInvalid={
                                      !!formik.errors.GHfc5 &&
                                      formik.touched.GHfc5
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GHfc5');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GHfc5}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>CF3CH2CF3</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GHfc6"

                                    value={formik.values.GHfc6}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GHfc6 &&
                                      !formik.errors.GHfc6
                                    }
                                    isInvalid={
                                      !!formik.errors.GHfc6 &&
                                      formik.touched.GHfc6
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GHfc6');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GHfc6}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>CF3CHFCHFCF2_CF3</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GHfc7"

                                    value={formik.values.GHfc7}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GHfc7 &&
                                      !formik.errors.GHfc7
                                    }
                                    isInvalid={
                                      !!formik.errors.GHfc7 &&
                                      formik.touched.GHfc7
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GHfc7');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GHfc7}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>

                              <Row className="mb-3">
                              <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                              Perfluorocarbonos
                              </p>
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>CF4</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GPfc1"

                                    value={formik.values.GPfc1}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GPfc1 &&
                                      !formik.errors.GPfc1
                                    }
                                    isInvalid={
                                      !!formik.errors.GPfc1 &&
                                      formik.touched.GPfc1
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GPfc1');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GPfc1}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>C2F6</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GPfc2"

                                    value={formik.values.GPfc2}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GPfc2 &&
                                      !formik.errors.GPfc2
                                    }
                                    isInvalid={
                                      !!formik.errors.GPfc2 &&
                                      formik.touched.GPfc2
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GPfc2');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GPfc2}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>C4F10</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GPfc3"

                                    value={formik.values.GPfc3}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GPfc3 &&
                                      !formik.errors.GPfc3
                                    }
                                    isInvalid={
                                      !!formik.errors.GPfc3 &&
                                      formik.touched.GPfc3
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GPfc3');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GPfc3}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>C6F14</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GPfc4"

                                    value={formik.values.GPfc4}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GPfc4 &&
                                      !formik.errors.GPfc4
                                    }
                                    isInvalid={
                                      !!formik.errors.GPfc4 &&
                                      formik.touched.GPfc4
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GPfc4');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GPfc4}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>SF6</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="GPfc5"

                                    value={formik.values.GPfc5}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.GPfc5 &&
                                      !formik.errors.GPfc5
                                    }
                                    isInvalid={
                                      !!formik.errors.GPfc5 &&
                                      formik.touched.GPfc5
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('GPfc5');
                                      setTipoAccion(tipo.PRE)

                                      calcularServicioSei()
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.GPfc5}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>

                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label> Compensación</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="compensacion"
                                    //disabled={ !reDecs || loading}
                                    value={formik.values.compensacion}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.compensacion &&
                                      !formik.errors.compensacion
                                    }
                                    isInvalid={
                                      !!formik.errors.compensacion &&
                                      formik.touched.compensacion
                                    }

                                    onBlur={() => {
                                      handleBlur('compensacion');
                                      setTipoAccion(tipo.PRE)
                                      calcularServicioSei()
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.compensacion}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              {formik.values.declaraciones === 'ANUAL' &&
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label> Pagos provisionales</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="pagosProvisionales"
                                    //disabled={ !reDecs || loading}
                                    value={formik.values.pagosProvisionales}
                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.pagosProvisionales &&
                                      !formik.errors.pagosProvisionales
                                    }
                                    isInvalid={
                                      !!formik.errors.pagosProvisionales &&
                                      formik.touched.pagosProvisionales
                                    }

                                    onBlur={() => {
                                      handleBlur('pagosProvisionales');
                                      setTipoAccion(tipo.PRE)
                                      calcularServicioSei()
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.pagosProvisionales}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                            }
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  controlId="validationFormik102"
                                  className="position-relative"
                                >
                                  <Form.Label>Multas</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="multas"
                                    value={formik.values.multas}

                                    onChange={formik.handleChange}
                                    isValid={
                                      formik.touched.multas && !formik.errors.multas
                                    }
                                    isInvalid={
                                      !!formik.errors.multas && formik.touched.multas
                                    }
                                    //disabled={ !reDecs || loading}
                                    onBlur={() => {
                                      handleBlur('multas');
                                      setTipoAccion(tipo.PRE)
                                      calcularServicioSei()
                                    }}
                                  />
                                  {/*<CurrencyInput
                                  id="input-example"
                                  name="base"
                                  className='border border-solid border-gray-300 w-full rounded-md h-10 focus:outline-none focus:border-blue-500 focus:shadow-outline-purple'
                                  placeholder="Your text here"
                                  defaultValue={formik.values.base}
                                  decimalsLimit={2}
                                  onValueChange={(value,name)=>formik.setFieldValue(name,value,true)}
                                  prefix='$'
                                />*/}
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {formik.errors.multas}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                              </>
                              )}
                            </Col>
                            <Col></Col>
                          </Box>
                        ) : (
                          activeStep === 1 && (
                            <FormPagos
                              metodoPago={metodoPago}
                              setMetodoPago={setMetodoPago}
                              generarPresupuesto={() => {
                                //GenerarLigaPago("presupuesto")
                                setTipoAccion(tipo.PRESUPUESTO)
                                calcularServicioSei();
                              }}
                              continuarPago={setContinuarPago}
                            />
                          )
                        )}
                      </React.Fragment>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {
                      activeStep === steps.length - 1 &&
                      <ButtonB
                        variant='secondary'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        Anterior
                      </ButtonB>
                    }
                    <Box sx={{ flex: '1 1 auto' }} />
                    {
                      activeStep === steps.length - 1 && <ButtonB
                        onClick={() => {
                          setTipoAccion(tipo.PRESUPUESTO)
                          setLoadingPago(true)
                          calcularServicioSei(true);
                        }}
                        variant='secondary' className='mr-3'>
                        Descargar presupuesto
                      </ButtonB>
                    }
                   <ButtonB  onClick={async () => {
                      if (activeStep === steps.length - 1) {
                        /*setTipoAccion(tipo.PRESUPUESTO)
                        calcularServicioSei(true);
                        return;*/
                        if (metodoPago === "pago1") {
                          setTipoAccion(tipo.PAGO)
                          setLoadingPago(true);
                          //generarTransaccion(tipo.PAGO)
                        } else {
                          setTipoAccion(tipo.REFERENCIA)
                          setLoadingPago(true);
                          //generarTransaccion(tipo.REFERENCIA)
                        }

                        calcularServicioSei(true)
                        return;
                      }
                      if (calculoData?.calculos?.total === 0) {
                        setAdvertenciaPago(true)
                        return
                      }
                      await handleNext();
                    }} variant='secondary'
                      disabled={!recalc || loading || (activeStep === steps.length - 1 && continuarPago === false)}
                    >
                      {activeStep === steps.length - 1 ? metodoPago === "pago1" ? 'Continuar al pago' : "Descargar referencia de pago" : calculoData?.calculos?.total !== 0 ? 'Siguiente' : 'Realizar pago'}
                    </ButtonB>
                  </Box>
                </Form>
              </View>}
      </View >
      {!pantallaExito && <ResumenCard tipo="gases" desableCalc={!((formik.isValid === true && reDecs === true))} pagosProvisionales={formik.values.pagosProvisionales}  montoAnt={calcularSumaTotal()} annio={formik.values.anno}/>
      }
      <ModalActions
        title={'Se ha generado la referencia de pago con éxito'}
        content={''} icon={'check'}
        show={pagoExitoModal}
        onHide={() => { setPagoExitoModal(false); }}
        buttonAction={() => { setPagoExitoModal(false);  }}
      />
       <ModalActions
        title={'Advertencia'}
        content={errorMessage}
        icon={'error'}
        show={showModal}
        onHide={() => setShowModal(false)} 
        buttonAction={() => { 
        setCandadoFechaAnual(false); 
        setShowModal(false); 
        }}
        />
       <ModalActions
        title={'Hubo un problema'}
        content={'Por favor, vuelve a intentarlo'} icon={'error'}
        desc={errorMessage}
        show={pagoFallidoModal}
        onHide={() => { setPagoFallidoModal(false); setErrorMessage('') }}
        buttonAction={() => { setPagoFallidoModal(false); setErrorMessage('') }}
      />
      {/* <ModalActions
        title={'Advertencia'}
        tipo="descuento"
        content={<div style={{textAlign:"left"}}>
          <div>
           <label>Actualmente cuentas con un descuento para el periodo:<br/></label>
           <label style={{margin:"1rem 0 1rem 4rem"}}>{nombresMeses[Number(formik.values.periodo) - 1]} del año {formik.values.anno}, con el número de referencia: {descuentoValor}.</label>
          </div>
          <div>
            Queremos recordarte que, si decides continuar con nuestros servicios, perderás este beneficio. Sin embargo, si deseas conservar tu descuento, te invitamos amablemente a realizar el pago por medio de:
            <ol style={{margin:"1rem 0 1rem 2rem", listStyle:"unset"}}>
              <li>Transferencia (SPEI)</li>
              <li>Instituciones bancarias o Tiendas de conveniencia</li>
            </ol>
          </div>
             ¡Estamos aquí para ayudarte en lo que necesites!

            </div>}

            icon={'error'}
        show={descuento}
        onHide={() => { showDescuento(false); }}
        buttonAction={() => { showDescuento(false); }}
        buttonActionCancel={() => { showDescuento(false); window.location.reload(); }}
      /> */}
        <ModalActions
        title={'Advertencia'}
        content={'Alcanzaste el límite de declaraciones permitidas en el periodo seleccionado.'} icon={'error'}
        show={decLimiteAlcanzado}
        onHide={() => { setDecLimiteAlcanzado(false);  }}
        buttonAction={() => { setDecLimiteAlcanzado(false); }}
      />
      {/* <ModalActions
        title={'Advertencia'}
        content={'Solo se permite declarar de manera anual del 1 de enero al 17 de febrero del año en curso. Si no realizó la declaración dentro de la fecha, deberá de solicitar cambiar su régimen de pago en una oficina administrativa.'} icon={'error'}
        show={candadoFechaAnual}
        onHide={() => { setCandadoFechaAnual(false); window.location.reload() }}
        buttonAction={() => { setCandadoFechaAnual(false); window.location.reload(); }}
      /> */}
      <ModalActions
        title={'Declaración sin saldo a pagar'}
        content={'No hay importe a pagar en esta declaración, puedes dar clic en Regresar para realizar alguna modificación, o clic en Confirmar para enviar la declaración actual.'}
        icon={'error'}
        show={advertenciaPago}
        onHide={() => { setAdvertenciaPago(false); }}
        buttonAction={() => {
          setTipoAccion(tipo.RECIBO)
          calcularServicioSei(true);
          setAdvertenciaPago(false);
          setLoadingPago(true);
        }}
        buttonActionCancel={() => { setAdvertenciaPago(false) }}
        buttonText='Confirmar'
        cancelText='Regresar'
      />
      <ToastContainer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPago}
      >
        <h1>Esperando Respuesta</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}