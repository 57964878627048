import React from 'react'
import { View } from "@aws-amplify/ui-react";
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import Iframe from "react-iframe";
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Footer from '../Footer';
import { Scanner } from '@yudiel/react-qr-scanner';
const ENDPOINT = process.env.REACT_APP_DATA_TRAMITES;

export default function ValidadorActas() {
    /* let { Folio } = useParams();
    const navigate = useNavigate(); */
    const [paused, setPaused] = useState(true);
    const [urlPdf, setUrlPdf] = useState("");
    const [currentFolio, setCurrentFolio] = useState( "");

    const ValidarActa =(folioData) => {
        
        if (folioData !== "") {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "folioNumber": folioData,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${ENDPOINT}/servicios/data/folioNumber`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.error === undefined) {
                        setUrlPdf(result)
                    } else {
                        toast.error('No se encontró el registro', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setUrlPdf("");
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    const schema = yup.object().shape({
        FolioData: yup.string().required("Este Campo es Obligatorio"),
    });

    const fechaFormat = (fecha) => {
        const fechaString = fecha;
        const opcionesDeFormato = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        };
        const formatoFinal = new Intl.DateTimeFormat('es-MX', opcionesDeFormato).format(new Date(fechaString));
        return formatoFinal;
    };

    const handleSubmit = (valores) => {
        ValidarActa(valores.FolioData);
    };

    const CortarString = (FolioD) => {
        let folioDigital =""
        if(FolioD.length > 100){
         folioDigital = FolioD.slice(14, 30);

        }else{
            folioDigital = FolioD;
        }
        ValidarActa(folioDigital);
        setCurrentFolio(folioDigital)
        setPaused(true);
    };

    return (
        <>
            <div className="Lector" style={{ margin: "1rem auto 4rem auto", width: "50%" }}>
                <View className="FormTitle">Validador de Documentos </View>
                <View className="FormDescripcion">
                    Esta herramienta ayudará en la verificación de los documentos generados por el portal de pagos, ya sea de forma manual o mediante códigos QR.
                </View>
            </div>
            <View className='DivPrincipal'>
                <View className="ViewPrincipal">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            FolioData: currentFolio,
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors }) => (
                            <Form onSubmit={handleSubmit} noValidate className='ViewActa'>
                                <Row className='m-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Folio:</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                name='FolioData'
                                                type='text'
                                                value={values.FolioData}
                                                onChange={handleChange}
                                                isValid={touched.FolioData && !errors.FolioData}
                                                isInvalid={touched.FolioData && !!errors.FolioData}
                                            />
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.FolioData}</Form.Control.Feedback>
                                            <Button type='submit' variant='secondary'>Verificar</Button>
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                    <div className='mt-3 mb-3' style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Button onClick={() => setPaused(!paused)} variant='secondary'>{paused === true ? "Iniciar Scan" : "Parar Scan"}</Button>
                        {urlPdf !== "" &&
                            <Button onClick={() => window.open(urlPdf.actaUrl, "_blank")} variant='secondary'>Descargar Documento</Button>
                        }
                    </div>
                    {!paused &&
                        <div style={{ width: isMobile ? "99%" : "50%", style: "flex", margin: "auto" }}>
                            <Scanner onScan={(result) => CortarString(result[0].rawValue)} paused={paused} />
                        </div>
                    }
                    {urlPdf !== "" &&
                        <div className='mt-3'>
                            <h1>Fecha de Vencimiento: {fechaFormat(urlPdf.fechaExp)}</h1>
                            <Iframe
                                src={`${urlPdf.actaUrl}#toolbar=0`}
                                height={isMobile ? "500px" : "750px"}
                                width={isMobile ? "99%" : "750px"}
                                display="initial"
                                position="relative"
                                frameBorder="0"
                                styles={{ border: "none" }}
                            />
                        </div>
                    }
                </View>
            </View>
            <Footer />
            <ToastContainer closeOnClick />
        </>
    );
}