import React from 'react'
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap'
/* import { View } from '@aws-amplify/ui-react'; */
import { Divider } from '@mui/material';
import numeral from 'numeral';
import '../../App.css'


/* const data = {
    "serie": 2023,
    "identificador": 118552,
    "tramiteId": 2254021,
    "loteId": 3946285,
    "fechaVencimiento": "2023-11-30T20:10:58.664Z",
    "total": 1956536,
    "conceptos": [
        {
            "conceptoId": 1563,
            "clave": "30024",
            "descripcion": "SERVICIO DE CONTROL VEHICULAR Y FOMENTO EDUCATIVO FLOTILLAS",
            "importe": 643188
        },
        {
            "conceptoId": 1564,
            "clave": "30408",
            "descripcion": "PLACAS Y FOMENTO EDUCATIVO FLOTILLAS",
            "importe": 1244568.78
        },
        {
            "conceptoId": 1565,
            "clave": "30549",
            "descripcion": "PERMISO DE CARGA FLOTILLAS",
            "importe": 0
        },
        {
            "conceptoId": 1566,
            "clave": "30548",
            "descripcion": "BAJA DE OTRO ESTADO FLOTILLAS",
            "importe": 0
        },
        {
            "conceptoId": 75,
            "clave": "30005",
            "descripcion": "APORTACION A LA CRUZ ROJA",
            "importe": 68779.62
        },
        {
            "conceptoId": 18,
            "clave": "12033",
            "descripcion": "AJUSTE  POR  REDONDEO (ART. 25 C.F.E.)",
            "importe": 0.4
        }
    ],
    "referencias": [
        {
            "referencia": "202300011855240591263",
            "recaudacion": "BANAMEX-005/01"
        },
        {
            "referencia": null,
            "recaudacion": "OXXO"
        },
        {
            "referencia": "202300011855240591263",
            "recaudacion": "SANTANDER-2300"
        },
        {
            "referencia": "202300011855240591263",
            "recaudacion": "BANCOMER-001977644"
        },
        {
            "referencia": "202300011855240591263",
            "recaudacion": "BANORTE-55440"
        },
        {
            "referencia": "202300011855240591263",
            "recaudacion": "HSBC-7394"
        },
        {
            "referencia": "202300011855240591263",
            "recaudacion": "SCOTIABANK-3218"
        },
        {
            "referencia": null,
            "recaudacion": "SORIANA"
        },
        {
            "referencia": null,
            "recaudacion": "TELECOMM-10001"
        },
        {
            "referencia": null,
            "recaudacion": "ALSUPER-201"
        },
        {
            "referencia": "202300011855240591263",
            "recaudacion": "BANCOAZTECA-12345"
        }
    ]
};  */
export const ResumenFlotillas = (props) => {
    const { data } = props;
   
    
    return (
        <div className='seccion2Flotillas' style={{ borderRadius: "10px", boxShadow: "4px 4px 8px rgba(0,0,0,0.5)", padding: "2rem"}}>

            <Row>
                <Col className='justify-start text-start underline font-bold text-lg' md='8'>Concepto</Col>
                <Col className='justify-end text-end underline font-bold text-lg' md='4'>Cantidad</Col>
            </Row>
            <Divider />
            {data !== "" &&

                data.conceptos.map((con, index) => (
                    <div key={index}>
                        <Row  className='mb-3'>
                            <Col className='justify-start text-start text-sm' md='8'>{con.descripcion}</Col>
                            <Col className='justify-end text-end underline flex items-center' md='4'>{numeral(con.importe).format('$0,0.00')}</Col>
                        </Row>
                        <Divider />
                    </div>

                ))

            }
            <Row>
                <Col className='justify-start text-start font-bold underline text-lg' md='7'>Total: </Col>
                <Col className='justify-end text-end underline font-bold text-lg' md='5'>{numeral(data.total).format('$0,0.00')}</Col>
            </Row>
            {/*<div>
        Conceptos
        {calculo.conceptos.map(cp => {
            return (<div>
                {cp.clave}:{cp.descripcion.substring(0, 10) + '...'}-importe:<strong>${cp.importe}</strong>
            </div>)
        })}
    </div>*/}
        </div>
    )
}
