import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { View } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { toast, ToastContainer } from "react-toastify";
import { isMobile } from 'react-device-detect';
import { Backdrop, CircularProgress } from "@mui/material";
import { publishMessage } from "../../graphql/mutations";
import ModalActions from "./ModalActions";
import { v4 as uuidv4 } from 'uuid';
import { onMessageReceived } from "../../graphql/subscriptions";
import FormPagos from "../FormPagos";
import {
  ObtenerToken,
  Validar,
  CalcularVehicularAPI,
} from "../../api/Vehicular";
import {getCurrentUser,fetchUserAttributes} from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import "../../App.css";
import imagenTarjeta from "../../assets/mensaje_tarjeta.png";
import { Loader } from "@aws-amplify/ui-react";
import Iframe from "react-iframe";
import ResumenCard from "./ResumenCard";
import { useTramiteDataGeneral } from "../../TramiteContext";
import { generateClient } from '@aws-amplify/api';
import NameFieldValidVehicular from './NameFieldValidVehicular';
import PantallaExitoImpuestos from '../PantallaExitoImpuestos';
const steps = ['Información', 'Formas de pago'];
const EDNPOINTEVENT = process.env.REACT_APP_SEI_API_ENDPOINT;
const client = generateClient();
export const FormaReplaqueo = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [token, setToken] = useState("");
    const [info, setInfo] = useState("")
    const [idForm, setIdForm] = useState('Not Assign');
    const [infoCalculos, setInfoCalculos] = useState("")
    const [loadingButton, setLoadingButton] = useState(true);
    const [disabled, setDisabled] = React.useState(false);
    const {  setCalculo, setData,  } = useTramiteDataGeneral()
    const [metodoPago, setMetodoPago] = useState('pago1');
    const [pagoExitoModal, setPagoExitoModal] = useState(false)
    const [pagoFallidoModal, setPagoFallidoModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [loadingPago, setLoadingPago] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [respuestaPago, setRespuestaPago] = useState({});
    const [pantallaExito, setPantallaExito] = useState(false)
    const [ligaPagoMit, setLigaPagoMit] = useState(null)
    const [continuarPago, setContinuarPago] = useState(false)
    const [respuestaRecibo, setRespuestaRecibo] = useState({})
   
  
    useEffect(() => {
        ObtenerToken().then(result => {
            if (result.ok === true) {
                setToken(result.message.token)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(error => {
            console.log(error)
        })
    }, []);



  const handleValidar = (data1, data2) => {
   
    if (data2 === "" || data1 === "") {
      toast.error("Ingrese todos los campos", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
 
    } else {
        setDisabled(true);
        setLoadingButton(true);
      const data = {
        clave: "REPLA2", // REPLA2 o REFRE
        placa: data2,
        serieUltimosSeis: data1,
      };
      Validar(data, token)
        .then((result) => {
        
          if (result.ok === true) {
          
            setInfo(result.message);
            setDisabled(false);
            setLoadingButton(false);
          } else {
            setDisabled(false);
            setInfo("");
            toast.error(result.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
       
          setDisabled(false);
        });
    }
}
    const agruparPorPropiedad = (array, propiedad) => {
        return array.reduce((acumulador, elemento) => {
            const clave = elemento[propiedad];

            // Si la clave aún no existe en el objeto agrupado, agrégala como un array vacío
            acumulador[clave] = acumulador[clave] || [];

            // Agrega el elemento actual al array correspondiente
            acumulador[clave].push(elemento);

            return acumulador;
        }, {});
    };

  
        
  
    const generateID = async () => {
        const user = await getCurrentUser();
        setIdForm(`PagoReplaqueo_${user.username}_${Date.now()}_${uuidv4()}`)
    }
    const handleCalcular = (data1) => {

        setLoading(true);
        setDisabled(true);
        const data = {
            "tipoMov": "REPLA2", // REPLA2 o REFRE 
            "placa": data1,
        }
        CalcularVehicularAPI(data, token).then(result => {
            
            if (result.ok === true) {
                
                handleNext();
                setInfoCalculos(result.message);
                setLoading(false);
                generateID()
                setDisabled(false);
                setData(agruparPorPropiedad(result.message.conceptos, 'anio'))
                setCalculo(result.message);
            } else {
                setLoading(false);
                setDisabled(false);
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(err => {
            console.log(err)
            setLoading(false);
            setDisabled(false);
        })

    }
 
    const generarEventoMIT = async(conceptos) => {
        const user = await fetchUserAttributes();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: idForm,
      payload: {
        validationRequested: true,
        data: {
          email: user.email,
          padron: {
            nombrePadron: "REPLAQUEO",
          },
          user:
            user.name !== undefined
              ? user.name
              : "Usuario",
          estadoCuenta: {
            serie: infoCalculos?.estadoDeCuenta[0]?.serie,
            identificador: infoCalculos?.estadoDeCuenta[0]?.identificador,
            tramiteId: infoCalculos?.estadoDeCuenta[0]?.tramiteId,
            loteId: infoCalculos?.estadoDeCuenta[0]?.loteId,
            fechaVencimiento: infoCalculos?.estadoDeCuenta[0]?.fechaVencimiento,
            total: infoCalculos?.estadoDeCuenta[0]?.importe,
          },
          placaAnterior: "",
          placa: info?.placa,
          marca: info?.marca,
          numeroSerie: info?.numeroSerie,
          modelo: info?.modelo,
          conceptos: conceptos,
        },
        userId: user.sub,
        type: "vehicular",
        authToken: token,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${EDNPOINTEVENT}/sei/putEvent`, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Get the day and pad with leading zero if necessary
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Get the month (0-11) and add 1, then pad with leading zero
    const year = today.getFullYear(); // Get the full year (4 digits)

        return `${day}/${month}/${year}`;
    }
    const formatearFecha = (fecha) => {
        if (fecha) {
            const date = new Date(fecha);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return null;
    };
    const GenerarLigaPago = async (tipoPago = null) => {
        const user = await fetchUserAttributes();
        /*   setLoading(true) */
       
        // metodoPago : pago1 : card - pago2 : - pago3 :
        // prepara conceptos con lo de calculo
        const opcionPago = tipoPago ? tipoPago : metodoPago
        const conceptos = infoCalculos?.conceptos?.map(cp => {
            return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
        })
        if (opcionPago !== "presupuesto") {

            generarEventoMIT(conceptos);
        }
        //preparar otras referencias
        const otrasRef = infoCalculos?.estadoDeCuenta[0]?.referencias?.map(referencia => {
            const partes = referencia.recaudacion.split('-');
            const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
            const convenio = parteDespuesDelGuion
            return { banco: referencia.recaudacion, convenio: convenio ? convenio : '', referencia: referencia.referencia }
        })

        const variables = opcionPago === "pago1" ?
            {
                "id": idForm,
                "payload": {
                    "paymentMethod": "card",
                    "user": user.name !== undefined ? user.name : "Contribuyente",
                    "email": user.email,
                    "tramite": "Replaqueo", //"IFlotillas Foraneas",
                    "reference": `${infoCalculos?.estadoDeCuenta[0]?.referencias[0].referencia}`, /* "202300011471739766260" */
                    "monto": infoCalculos?.estadoDeCuenta[0]?.importe,
                    "type": "vehicular"
                }
            }
            :
            opcionPago === "pago2" ?
                {
                    "id": idForm,
                    "payload": {

                        "paymentMethod": "spei",
                        "data": {
                            "user": user.name !== undefined ? user.name : "Contribuyente",
                            "loteId": `${infoCalculos?.estadoDeCuenta[0]?.loteId}`,
                            "total_pagar": infoCalculos?.estadoDeCuenta[0]?.importe, //data.total
                            "tramite": "Replaqueo",//"Alta Flotillas Foraneas
                            "movimiento": "Replaqueo",
                            "fecha_generacion": getCurrentDate(),
                            "fecha_vigencia": `${formatearFecha(infoCalculos?.estadoDeCuenta[0]?.fechaVencimiento)}`,//"19/10/2022",
                            "placa": info?.placa,
                            "ultimos_seis_digitos": formik.values.digitosSerie,
                            "referencia": `${infoCalculos?.estadoDeCuenta[0]?.referencias[0].referencia}`,
                            "otras_referencias": otrasRef,

                        },
                        "email": user.email,
                        "procedure": "referencia_pago_control_vehicular"
                    }
                }
                :
                {
                    "id": idForm,
                    "payload": {
                        "paymentMethod": "spei",
                        "data": {
                            "user": user.name !== undefined ? user.name : "Contribuyente",
                            "tramite": "Replaqueo",
                            "movimiento": "Replaqueo",
                            "fecha_generacion": getCurrentDate(),
                            "placa": info?.placa,
                            "fecha_vigencia": `${formatearFecha(infoCalculos?.estadoDeCuenta[0]?.fechaVencimiento)}`,
                            "modelo": info?.modelo,
                            "marca": info?.marca,
                            "linea": info?.linea,
                            "sublinea": info?.subLinea,
                            "otros_conceptos": conceptos,
                            "total_pagar": infoCalculos?.estadoDeCuenta[0]?.importe,
                            "toWords": " "
                        },
                        "email": user.email,
                        "procedure": "presupuesto_control_vehicular"

                    },
                };
        const Data = await client.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });
       
    }

  
    const handleNext = async () => {
    await generateID(); 
    setActiveStep((preActiveStep) => preActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((preActiveStep) => preActiveStep - 1);
  };

  const validationSchema = yup.object().shape({
    placaActual: yup.string().required("Este campo es obligatorio"),
    digitosSerie: yup.string().required("Este campo es obligatorio"),
  });
  const formik = useFormik({
    validationSchema,
    onSubmit: (values) => {
    
     handleCalcular(values.placaActual); 
    },
    initialValues: {
      placaActual: "",
      digitosSerie: "",
    },
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (value.length <= 6 && name === "digitosSerie") {
        const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");
        formik.setFieldValue(name, alphanumericValue.toUpperCase());
        if (value.length === 6) {
            handleValidar(alphanumericValue, formik.values.placaActual);
        }
    } else if (name === "placaActual") {
        const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");
        formik.setFieldValue(name, alphanumericValue.toUpperCase());
        formik.setFieldValue("digitosSerie", "");
    }
};
  useEffect(() => {
    const subscription = client.graphql({ query: onMessageReceived, variables: { id: idForm } })
        .subscribe({
            next: (event) => {
                const receivedMessage = event.data.onMessageReceived;
                //parse json on payload response. - authToken - taskToken --- rfc
                /*      setMessage(receivedMessage); */
                const dataRec = JSON.parse(receivedMessage.payload)
               /*  console.log("DATA rec", dataRec); */
                /* if (dataRec.taskToken) {
                  setTaskToken(dataRec.taskToken)
                } */
                if (dataRec?.error) {
                    setErrorMessage(dataRec?.error)
                    setPagoFallidoModal(true)

                    //alert("Hubo un error intentalo de nuevo")
                    return
                }
                if (dataRec.status === "PAGO VALIDADO") {
                    //activar loader
                   
                    setRespuestaPago(dataRec)
                    setLoadingPago(true)

                }
                if (dataRec?.procedure === "paymentUrl") {
                    if (dataRec.response.cd_response === "error") {
                        //AlertsAction("error")
                        setPagoExitoModal(false)
                        setPagoFallidoModal(true)
                        setLoadingPago(false);
                        setLoading(false)
                        return
                    }
                    if (dataRec.response.cd_response === "success") {
                        //AlertsAction("success")
                        //setPagoExitoModal(true)
                        setPagoFallidoModal(false)
                        setLigaPagoMit(dataRec.response.nb_url)
                        setLoadingPago(false);
                        setLoading(false);
                    }
                }
                if (dataRec.Procedure === "referencia_pago_control_vehicular" || dataRec.Procedure === "presupuesto_control_vehicular" || dataRec.Procedure === "recibo_pago_control_vehicular") {
                    if (dataRec.Procedure === "referencia_pago_control_vehicular" || dataRec.Procedure === "presupuesto_control_vehicular") {
                        window.open(dataRec.TXTBody);
                        setLoadingPago(false)
                    }
                    if (dataRec.Procedure === "recibo_pago_control_vehicular") {
                        //activar vista de exito
                        setPantallaExito(true)
                        setRespuestaRecibo(dataRec)
                        setLoadingPago(false)
                        setLoading(false)
                        return
                    }
                    if (dataRec.Procedure === "referencia_pago_control_vehicular") {
                        setLoadingPago(false)
                        setLoading(false)
                        setPagoExitoModal(true)
                        setPagoFallidoModal(false)
                        return
                    }/* else {
              setCallFinal(true)
            }*/
                    return
                }
            },
            error: (error) => {
                console.error('Subscription error', error);
            },
        });


    return () => {
        subscription.unsubscribe();
    };
}, [idForm]);
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Replaqueo</View>
                <View className='FormDescripcion'>
                    El trámite de replaqueo tiene como objetivo asegurar que todos los vehículos automotores circulando en el estado estén adecuadamente identificados, facilitando la confirmación fácil de su posesión legal mediante la sustitución de las placas vehiculares y la tarjeta de circulación con nuevas formas.
                </View>
            </div>
            <View className="ViewPrincipal">
                {
                    pantallaExito ?
                        <PantallaExitoImpuestos pago={respuestaPago} recibo={respuestaRecibo} titulo={'Replaqueo'} />
                        :
                        ligaPagoMit ?
                            <View >
                                <div className='w-full flex justify-end'><img width={'300px'} height={'150px'} src={imagenTarjeta} alt="Mensaje tarjeta" /></div>
                                <Iframe
                                    src={ligaPagoMit}
                                    height="850px"
                                    width={isMobile ? "99%" : "550px"}
                                    display="initial"
                                    styles={{ overflow: "hidden" }}
                                    position="relative"
                                />
                            </View>
                            :
                            <View className="ViewContent">
                                <Form noValidate onSubmit={formik.handleSubmit} className='ViewActa'>
                                    <Box sx={{ width: '100%' }}>
                                        <Stepper activeStep={activeStep} className='mb-3'>
                                            {steps.map((label, index) => {
                                                const stepProps = {};
                                                const labelProps = {};


                                                return (
                                                    <Step key={label} {...stepProps}>
                                                        <StepLabel  {...labelProps}>{label}</StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                        {activeStep === steps.length ?
                                            (
                                                <React.Fragment>
                                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                                        <label>Descripcion: Pulsa el boton para generar tu orden de pago.</label>
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ justifyContent: "space-between" }}>
                                                        <Button
                                                            variant='secondary'
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}

                                                        >
                                                            Anterior
                                                        </Button>

                                                        <Button type='submit' variant='secondary'>
                                                            Enviar
                                                        </Button>
                                                    </Box>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {activeStep === 0 ?
                                                        <Box>
                                                            <Row className='mb-3'>
                                                                <Form.Group as={Col} className='position-relative'>
                                                                    <Form.Label>Placa Actual*</Form.Label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name='placaActual'
                                                                            placeholder='Placa Actual'
                                                                            value={formik.values.placaActual}
                                                                            onChange={handleInputChange}
                                                                           /*  onBlur={()=> handleValidar(formik.values.numeroSerie, formik.values.placa)} */
                                                                            disabled={disabled}
                                                                            isValid={formik.touched.placaActual && !formik.errors.placaActual}
                                                                            isInvalid={formik.touched.placaActual && !!formik.errors.placaActual}
                                                                        />
                                                            <Form.Text muted>Por favor, ingrese su información sin espacios en blanco ni guiones.</Form.Text>

                                                                        {disabled ? (

                                                                            <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                                                                <Loader size='large' emptyColor="lightgray" filledColor="gray" />
                                                                            </div>

                                                                        ) : null}


                                                                    </div>
                                                                    <Form.Control.Feedback type='invalid' tooltip>{formik.errors.placaActual}</Form.Control.Feedback>
                                                                </Form.Group>

                                                            </Row>
                                                            <Row className='mb-3'>
                                                                <Form.Group as={Col} className='position-relative'>
                                                                    <Form.Label>Últimos 6 dígitos del número de serie</Form.Label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name='digitosSerie'
                                                                            maxLength={6}
                                                                            disabled={disabled || formik.values.placaActual ===""}
                                                                            placeholder='Últimos 6 dígitos del número de serie'
                                                                            value={formik.values.digitosSerie}
                                                                            onChange={handleInputChange}
                                                                            isValid={formik.touched.digitosSerie && !formik.errors.digitosSerie}
                                                                            isInvalid={formik.touched.digitosSerie && !!formik.errors.digitosSerie}
                                                                        />
                                                                        {disabled ? (

                                                                            <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                                                                <Loader size='large' emptyColor="lightgray" filledColor="gray" />
                                                                            </div>

                                                                        ) : null}


                                                                    </div>
                                                                    {info?.marca && (<NameFieldValidVehicular marca={info?.marca} linea={info?.linea} sublinea={info?.subLinea} modelo={info?.modelo} serie={info?.numeroSerie} placa={info?.placa} />)}
                                                                    <Form.Control.Feedback type='invalid' tooltip>{formik.errors.digitosSerie}</Form.Control.Feedback>
                                                                </Form.Group>
                                                                {loading ? (

                                                                    <div >
                                                                        <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                                                                    </div>

                                                                ) : null}
                                                            </Row>

                                                        </Box>
                                                        :
                                                        activeStep === 1 &&
                                                        (<FormPagos metodoPago={metodoPago} setMetodoPago={setMetodoPago} continuarPago={setContinuarPago} />)
                                                    }
                                                </React.Fragment>
                                            )

                                        }

                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>


                                        <Button
                                            variant='secondary'

                                            onClick={() => {
                                                activeStep === 0 ?
                                                    navigate("/")
                                                    :
                                                    handleBack()
                                            }}


                                        >
                                            {activeStep === 0 ? "Regresar" : "Anterior"}
                                        </Button>

                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {
                                            activeStep === steps.length - 1 && <Button
                                                onClick={() => {

                                                    setLoadingPago(true)
                                                    GenerarLigaPago("presupuesto")
                                                }}
                                                variant='secondary' className='mr-3 ml-3'>
                                                Descargar Presupuesto
                                            </Button>
                                        }
                                        <Button onClick={() => {

                                            if (activeStep === steps.length - 1) {
                                                /*setTipoAccion(tipo.PRESUPUESTO)
                                                calcularServicioSei(true);
                                                return;*/
                                                if (metodoPago === "pago1") {

                                                    GenerarLigaPago("pago1")

                                                    setLoadingPago(true);
                                                    //generarTransaccion(tipo.PAGO)
                                                } else {
                                                    /*    setTipoAccion(tipo.REFERENCIA) */
                                                    GenerarLigaPago("pago2")

                                                setLoadingPago(true);
                                                //generarTransaccion(tipo.REFERENCIA)
                                            }

                                            return;
                                        }

                                        formik.handleSubmit()
                                    }} variant='secondary'
                                       disabled={loadingButton || loading || !formik.isValid || (activeStep === steps.length - 1 && continuarPago === false)}
                                    >
                                        {activeStep === steps.length - 1 ? metodoPago === "pago1" ? 'Continuar al pago' : "Descargar referencia de pago" : infoCalculos?.estadoDeCuenta?.importe !== 0 ? 'Siguiente' : 'Realizar pago'}
                                    </Button>
                                </Box>
                            </Form>
                        </View>}
            </View>
            {activeStep === steps.length - 1 &&

                <ResumenCard tipo="vehicular"/>
            }
            <ModalActions
                title={'Se ha generado la referencia de pago con éxito'}
                content={''} icon={'check'}
                show={pagoExitoModal}
                onHide={() => { setPagoExitoModal(false);}}
                buttonAction={() => { setPagoExitoModal(false); }}
            />
            <ModalActions
                title={'Hubo un problema'}
                content={'por favor, vuelve a intentarlo'} icon={'error'}
                desc={errorMessage}
                show={pagoFallidoModal}
                onHide={() => { setPagoFallidoModal(false); setErrorMessage('') }}
                buttonAction={() => { setPagoFallidoModal(false); setErrorMessage('') }}
            />


      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPago}
      >
        <h1>Esperando Respuesta</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
