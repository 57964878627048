import React from "react";
import {
  View,
  
} from "@aws-amplify/ui-react";
import * as formik from 'formik';
import * as yup from 'yup';
import { Col, Row, Form, Button } from "react-bootstrap";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const ConfigCuenta = () => {
  const { Formik } = formik;
  const schema = yup.object().shape({
    Nombre: yup.string().required("Este Campo es Obligatorio"),
    Email: yup.string().email("Correo no valido").required("Este Campo es Obligatorio"),
    Telefono: yup.string().required("Este Campo es Obligatorio"),
    PassWordActual: yup.string().required("Ingrese su contraseña"),
    PasswordNueva: yup.string().required("Ingrese su contraseña"),
    RepeatPassword: yup.string().required("Confirme su contraseña").min(8, "Muy corta").oneOf([yup.ref("PasswordNueva"), null], "Passwords must match"),
  });

  const [showPasswordActual, setShowPasswordActual] = React.useState(false);
  const [showPasswordNueva, setShowPasswordNueva] = React.useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = React.useState(false);

  const handleClickShowPassword = () => setShowPasswordActual((show) => !show);
  const handleClickShowPasswordNueva = () => setShowPasswordNueva((show) => !show);
  const handleClickShowPasswordRepeat = () => setShowPasswordRepeat((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className="FormTramite">
        <View className="FormTitle">Configuración de Cuenta</View>
        <View className='FormDescripcion'>Edita tu información y seguridad</View>
        <Formik
          validationSchema={schema}
          initialValues={{
            Nombre: "",
            Email: "",
            Telefono: "",
            PassWordActual: "",
            PasswordNueva: "",
            RepeatPassword: ""
          }
          }
          onSubmit={(valores, { resetForm }) => {
            console.log("Valores", valores);
          }
          }
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (

            <Form onSubmit={handleSubmit} noValidate className='ViewActa mt-3'>
              <Row className="mb-3">
                <Form.Group as={Col} className="position-relative" >
                <FormControl variant="outlined" fullWidth>
                    <InputLabel  >Nombre</InputLabel>
                    <OutlinedInput
                      label="Nombre"
                      name="Nombre"
                      value={values.Nombre}
                      onChange={handleChange}
                      error={touched.Nombre && Boolean( errors.Nombre)}
                      
                    />
                     <FormHelperText id="component-error-text" error>{ touched.Nombre && errors.Nombre}</FormHelperText>
                  </FormControl>
                </Form.Group>

                <Form.Group as={Col} className="position-relative" >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel >Contraseña Actual</InputLabel>
                    <OutlinedInput
                      type={showPasswordActual ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordActual ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      
                      label="Contraseña Actual"
                      name="PassWordActual"
                      value={values.PassWordActual}
                      onChange={handleChange}
                      error={touched.PassWordActual && Boolean( errors.PassWordActual)}
                    />
                     <FormHelperText id="component-error-text" error>{ touched.PassWordActual && errors.PassWordActual}</FormHelperText>
                  </FormControl>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="position-relative">
                <FormControl variant="outlined" fullWidth >
                    <InputLabel >Correo Electrónico</InputLabel>
                    <OutlinedInput
                      label="Correo Electrónico"
                      autoComplete="off"
                      name="Email"
                      value={values.Email}
                      onChange={handleChange}
                      error={touched.Email && Boolean( errors.Email)}
                    />
                     <FormHelperText id="component-error-text" error>{ touched.Email && errors.Email}</FormHelperText>
                  </FormControl>
                </Form.Group>
                <Form.Group as={Col} className="position-relative">
                <FormControl variant="outlined" fullWidth>
                    <InputLabel >Contraseña Nueva</InputLabel>
                    <OutlinedInput
                      type={showPasswordNueva ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordNueva}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordNueva ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      
                      label="Contraseña Nueva"
                      name="PasswordNueva"
                      value={values.PasswordNueva}
                      onChange={handleChange}
                      error={touched.PasswordNueva && Boolean( errors.PasswordNueva)}
                    />
                     <FormHelperText id="component-error-text" error>{ touched.PasswordNueva && errors.PasswordNueva}</FormHelperText>
                  </FormControl>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="position-relative">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel >Número de Teléfono</InputLabel>
                    <OutlinedInput

                      label="Número de Teléfono"
                      name="Telefono"
                      value={values.Telefono}
                      onChange={handleChange}
                      error={touched.Telefono && Boolean( errors.Telefono)}
                    />
                     <FormHelperText id="component-error-text" error>{ touched.Telefono && errors.Telefono}</FormHelperText>
                  </FormControl>
                </Form.Group>
                <Form.Group as={Col} className="position-relative">
                <FormControl variant="outlined" fullWidth>
                    <InputLabel >Repetir Contraseña</InputLabel>
                    <OutlinedInput
                      type={showPasswordRepeat ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordRepeat}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      
                      label="Repetir Contraseña"
                      name="RepeatPassword"
                      value={values.RepeatPassword}
                      onChange={handleChange}
                      error={touched.RepeatPassword && Boolean( errors.RepeatPassword)}
                    />
                     <FormHelperText id="component-error-text" error>{ touched.RepeatPassword && errors.RepeatPassword}</FormHelperText>
                  </FormControl>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Button type="submit" style={{ width: "50%", margin: "auto" }} variant="secondary">Guardar cambios</Button>
              </Row>
            </Form>

          )}


        </Formik>
      </div>
    </>
  )
}
