import React, { useState, useEffect } from "react";
import { InstantSearch } from "react-instantsearch";
import algoliasearch from "algoliasearch/lite";
import "../styles/algolia.css";

const REACT_APP_ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const REACT_APP_ALGOLIA_API_ID = process.env.REACT_APP_ALGOLIA_API_ID

const AlgoliaSearch = ({ list, toggleSearch }) => {
  const searchClient = algoliasearch(
    REACT_APP_ALGOLIA_APP_ID,
    REACT_APP_ALGOLIA_API_ID
  );



  const extractNombreOficialTramite = (excerpt) => {
    // Use a regular expression to capture the text between the two phrases
    const match = excerpt.match(
      /Nombre del trámite \(oficial\): (.+?) Nombre del trámite \(coloquial\):/
    );

    // Check if a match was found and extract the captured text
    const extractedOficialName = match ? match[1] : "No match found";

    return extractedOficialName;
  };

  const extractDescripcionTramite = (excerpt) => {
    // Use a regular expression to capture the text between the two phrases
    const match = excerpt.match(/Descripción del trámite:(.*?)Section 2/);

    // Check if a match was found and extract the captured text
    const extractedDescription = match ? match[1] : "No match found";

    return extractedDescription;
  };

  const SearchResultItem = ({ hit }) => {
    const extractedOficialName = extractNombreOficialTramite(hit.excerpt);
    const extractedDescription = extractDescripcionTramite(hit.excerpt);
    // console.log(extractedOficialName);
    // console.log(extractedDescription);

    if (!list) {
      return (
        <div className="searchResultItem m-3 p-1 h-60 justify-between flex flex-column max-w-md">
          <p className="algoliaTitulo min-h-[2.1rem] max-h-fit pt-2 line-clamp-4">
            {extractedOficialName}
          </p>
          <p className="algoliaDescripcion overflow-hidden line-clamp-4 ">
            {extractedDescription}
          </p>
          <a href={`${process.env.REACT_APP_HOME}tramite${hit.slug}`} className="result-link no-underline text-left">
            <p className="algoliaVer">Ver</p>
          </a>
        </div>
      );
    } else {
      return (
        <a href={`${process.env.REACT_APP_HOME}tramite${hit.slug}`} className="no-underline text-left">
          <div className="p-1 px-3 hover:bg-white ">
            <p className="text-lg line-clamp-4 mb-0"> {extractedOficialName}</p>
            <p className="text-sm overflow-hidden line-clamp-3 h-18 mb-0">
              {extractedDescription}
            </p>
          </div>
        </a>
      );
    }
  };

  const [query, setQuery] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(0);
  const [hits, setHits] = useState([]);
  const resultsPerPage = 4;

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
  };

  // const handlePageChange = (newPage) => {
  //   setPage(newPage);
  // };

  useEffect(() => {
    const fetchData = async () => {
      const index = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME);
      const { hits } = await index.search(query, { hitsPerPage: 1000 }); // Fetch all hits

      // Set hits in state
      setHits(hits);
    };

    const debounce = (func, delay) => {
      let timeoutId;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(context, args), delay);
      };
    };

    const delayedQuery = debounce((q) => {
      // This function will be called after a delay
      // Perform the search query here
      fetchData(q);
    }, 300); // Adjust the delay time as needed

    if (query) {
      // fetchData();
      delayedQuery(query);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  console.log(hits);

/*   const totalPages = Math.ceil(hits.length / resultsPerPage);
 */
  const renderHits = () => {
    const startIndex = page * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;

    // console.log(startIndex);
    // console.log(endIndex);
    return hits.slice(startIndex, endIndex).map((hit) => (
      <div key={hit.objectID}>
        <SearchResultItem hit={hit} query={query} />
        {/* {console.log(hit)} */}
      </div>
    ));
  };

  return (
    <div
      className={`justify-center ${
        list
          ? "algoliaList pt-2 bg-[#D4E0E0] lg:w-96 w-full z-40 lg:rounded-b-lg absolute lg:right-0"
          : "p-3 w-full"
      } ${query && list ? "" : "pb-3"}`}
    >
      <InstantSearch
        searchClient={searchClient}
        // indexName="process.env.GATSBY_ALGOLIA_INDEX}"
      >
        <div className="flex gap-2  justify-center">
          <input
            type="text"
            placeholder="Buscar..."
            value={query}
            onChange={handleInputChange}
            // className="algoliaInput w-100 mx-3"
            className={`algoliaInput  ${
              list ? "ml-3 w-5/6 " : "mx-auto w-full "
            }`}
          />
          <button
            onClick={toggleSearch}
            className={`p-2 text-xl align-middle text-slate-600  ${
              list ? "" : "hidden"
            }`}
          >
            x
          </button>
        </div>

        {query && (
          <div>
            <div
              className={`sm:grid justify-center ${
                list
                  ? "divide-y divide-dashed divide-slate-800 py-1 grid"
                  : "lg:flex lg:flex-row"
              }`}
            >
              {renderHits()}
            </div>
            {/* <div className="flex">
              {page > 0 && (
                <button
                  onClick={() => handlePageChange(page - 1)}
                  className="p-1 flex-1"
                >
                  {"<"}
                </button>
              )}
              {page < totalPages - 1 && (
                <button
                  onClick={() => handlePageChange(page + 1)}
                  className="p-1 flex-1"
                >
                  {">"}
                </button>
              )}
            </div> */}
          </div>
        )}
      </InstantSearch>
    </div>
  );
};

export default AlgoliaSearch;
