import React, { createContext, useContext, useState } from 'react';

const TramiteDataGeneral = createContext();

export function TramiteDataGeneralProvider({ children }) {
  const [data, setData] = useState(null);
  const [calculo, setCalculo] = useState(null);
  const [declaracion, setDeclaracion] = useState(null);
  const [declaracionAnual, setDeclaracionAnual] = useState(null);
  const saveJsonToLocalStorage = (key, jsonObject) => {
    localStorage.setItem(key, JSON.stringify(jsonObject));
  };
  const getJsonFromLocalStorage = (key) => {
    const savedItem = localStorage.getItem(key);
    return savedItem ? JSON.parse(savedItem) : null;
  };
  
  const updateData = (newData) => {
    setData(newData);
  };

  return (
    <TramiteDataGeneral.Provider value={{ data,setData, updateData,calculo,setCalculo,declaracion,setDeclaracion, setDeclaracionAnual,declaracionAnual, saveJsonToLocalStorage,getJsonFromLocalStorage }}>
      {children}
    </TramiteDataGeneral.Provider>
  );
}

// Custom hook to access the context
export function useTramiteDataGeneral() {
  return useContext(TramiteDataGeneral);
}