import React, { useEffect, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormDatosGenerales } from "./FormDatosGenerales"
import { FormDatosDomicilio } from './FormDatosDomicilio';
import { FormDatosContacto } from './FormDatosContacto';
import { FormDatosMedicos } from './FormDatosMedicos';
import { FormDatosIdentidad } from './FormDatosIdentidad';
import { FormDatosEntrega } from './FormDatosEntrega';
import { FormDatosEnvio } from './FormDatosEnvio';
import { FormDatosEmergencia } from './FormDatosEmergencia';
import { FormDocumentos } from './FormDocumentos';
import { useTramiteDataGeneral } from '../../TramiteContext';
import { Backdrop, CircularProgress } from '@mui/material';
import { FcOk } from "react-icons/fc";
import { toast, ToastContainer } from 'react-toastify';
export const TramiteLicencias = () => {
    const navigate = useNavigate();
    const { getJsonFromLocalStorage } = useTramiteDataGeneral();
    const dataLicencias = getJsonFromLocalStorage("DatosLicencias")
    const [dataL, setDataL] = useState("")
    const [loading, setLoading] = useState(true);
    const [changeData, setChangeData] = useState(true);
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "procedureType": dataLicencias.procedureType,
            "id": dataLicencias.id
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev/procedures/getProcedure", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setDataL(result.item)
                setLoading(false);
            })
            .catch((error) => console.error(error));
    }, [changeData])
    const handleTerminar = (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "procedureType": dataL.procedureType,
            "id": id
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev/ticket/createTicket", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if(result.error !== undefined){
                    toast.error(result.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }else{
                    toast.success("Datos Guardados Correctamente", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigate("/tramite/DPC-Licencias/Historial")
                }
            })
            .catch((error) => console.error(error));
    }
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Trámite de Licencias</View>
                <View className='FormSubTitle'>Datos del Contribuyente</View>
            </div>
            {loading ?
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <h1>Cargando Registros</h1>
                    <CircularProgress color="inherit" />
                </Backdrop>

                :


                <View className="ViewPrincipal">
                    <Row className='mb-3'>
                        <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                            <button type="button" onClick={() => navigate("/tramite/DPC-Licencias/Historial")}
                                className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                                <FaArrowLeft /> Volver al Inicio
                            </button>
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                        </Form.Group>
                        <Form.Group className='d-grid gap-2' as={Col} md="3">
                        </Form.Group>
                    </Row>
                    <View className="ViewContent">
                        <View className='ViewActa'>

                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos Generales
                                        </label>
                                        {dataL.datosGenerales !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <FormDatosGenerales datos={dataL} setChangeData={setChangeData} changeData={changeData}/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Residencia
                                        </label>
                                        {dataL.domicilioResidencia !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosDomicilio datos={dataL} setChangeData={setChangeData} changeData={changeData}/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Contacto
                                        </label>
                                        {dataL.datosContacto !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosContacto datos={dataL} setChangeData={setChangeData} changeData={changeData}/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos Medicos
                                        </label>
                                        {dataL.mediaFiliacion !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosMedicos datos={dataL} setChangeData={setChangeData} changeData={changeData}/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5-content"
                                    id="panel5-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Identidad
                                        </label>
                                        {dataL.identidad !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosIdentidad datos={dataL} setChangeData={setChangeData} changeData={changeData}/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6-content"
                                    id="panel6-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Entrega
                                        </label>
                                        {dataL.datosEntrega !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosEntrega datos={dataL} setChangeData={setChangeData} changeData={changeData}/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel7-content"
                                    id="panel7-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Envío
                                        </label>
                                        {dataL.direccionEnvio !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosEnvio datos={dataL} setChangeData={setChangeData} changeData={changeData}/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel8-content"
                                    id="panel8-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Emergencia
                                        </label>
                                        {dataL.contactoEmergencia !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosEmergencia datos={dataL} setChangeData={setChangeData} changeData={changeData}/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel9-content"
                                    id="panel9-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Documentacíon
                                        </label>
                                        {dataL.documentos !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDocumentos datos={dataL} setChangeData={setChangeData} changeData={changeData}/>
                                </AccordionDetails>
                            </Accordion>
                            {dataL.isComplete &&

                                <Row className='m-1'>
                                <Button variant='secondary' onClick={()=>{handleTerminar(dataL.id)}}>Terminar Proceso</Button>

                            </Row>
                            }
                        </View>
                    </View>
                </View>
            }
            <ToastContainer/>
        </>
    )
}
