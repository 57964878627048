import React, { useState, useEffect } from "react";

import { Form, Button, Row, Col } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;
const ENDPOINT_DOCUMENTOS = process.env.REACT_APP_DOCUMENTOS;

export const FormArchivosPerFisica = (props) => {
  const { handleBack, info } = props;
  console.log(info);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputKeys, setInputKeys] = useState({
    comprobanteDomicilio: null,
    identificacion: null,
 
  });

  const navigate = useNavigate();


  const handleClose = () => {
    navigate("/tramite/Historial-Propietarios");
    setShow(false);
  };


  const { Formik } = formik;

  const EnviarPersonalidad = (values) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(info),
      redirect: "follow",
    };
    fetch(`${ENDPOINT}/personalidadFisica`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.ticket === undefined) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Propietario",
            text: result.message,
          });
        } else {
          handleSubirArchivos(values, result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleSubirArchivos = async (values, id) => {
   
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/pdf");
    const arrayArchivos = [];
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const file = values[key];

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: file,
          redirect: "follow",
        };
        await fetch(
          `${ENDPOINT_DOCUMENTOS}/subir/?documentName=${key}&userId=${info.usuarioID}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {

            console.log(result);
            const nuevoValor = { name: key, url: result.s3_url };
            arrayArchivos.push(nuevoValor);
          })
          .catch((error) => console.log("error", error));
      }
    }
     CargarNombreDocumnetos(arrayArchivos, id.ticket.id.S);
  };

  const CargarNombreDocumnetos = (arrayArchivos, data) => {
    var myHeaders2 = new Headers();
    myHeaders2.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      paths: [
        {
          name: arrayArchivos[0].name,
          url: arrayArchivos[0].url,
        },
        {
          name: arrayArchivos[1].name,
          url: arrayArchivos[1].url,
        }
      ],
      dynamoID: data,
      ticketType: 15,
    });
  
    var requestO = {
      method: "POST",
      headers: myHeaders2,
      body: raw,
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/attachments`, requestO)
      .then((response) => response.json())
      .then((result) => {
       
        setLoading(false);
        let timerInterval;
        Swal.fire({
          title: "Creación de Propietario",
          html: "Propietario Creado con éxito, serás redirigido al historial de propietarios",
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/tramite/Historial-Propietarios")
          }
        });
      })
      .catch((error) => console.log("error", error));
  };

  const schema = yup.object().shape({
    comprobanteDomicilio: yup
      .mixed()
      .required("Este campo es obligatorio")
      .test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
      })
      .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
        return value.size <= maxSize;
      }),
    /*   credencialDiscapacidad: yup.mixed()
      .test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }
  
        return value.type === 'application/pdf';
      })
      .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }
  
        const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
        return value.size <= maxSize;
      }),  */
    identificacion: yup
      .mixed()
      .required("Este campo es obligatorio")
      .test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
      })
      .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
        return value.size <= maxSize;
      }),
  });

  const [touchedFields, setTouchedFields] = useState({});



  const handleBlur = (fieldName) => {
    setTouchedFields((prevTouched) => ({ ...prevTouched, [fieldName]: true }));
  };
  return (
    <>
      <Formik
      enableReinitialize ={true}
        validationSchema={schema}
        onSubmit={(values) => {
       
       
          setLoading(true);
          EnviarPersonalidad(values);
        }}
        initialValues={{
          comprobanteDomicilio: "",
          identificacion: "",
         /*  credencialDiscapacidad: "", */
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit} >

            <Row className="mb-3">
              <Form.Group as={Col} className="position-relative">
                <Form.Label>Comprobante de Domicilio (No mayor a 2 meses)*</Form.Label>
                <Form.Control
                  key={inputKeys.comprobanteDomicilio}
                  required
                  type="file"

                  onInput={() => handleBlur("comprobanteDomicilio")}
                  name="comprobanteDomicilio"
                  accept=".pdf"
                  onChange={(event) => {

                    setFieldValue(
                      "comprobanteDomicilio",
                      event.currentTarget.files[0]
                    );

                  }}
                  isValid={touched.comprobanteDomicilio && !errors.comprobanteDomicilio}
                  isInvalid={touchedFields.comprobanteDomicilio && !!errors.comprobanteDomicilio}
                />
                <Form.Text muted>
                  Archivo en formato PDF. Tamaño máximo 4 MB.
                </Form.Text>
                <Form.Control.Feedback type="invalid" tooltip>
                  {touchedFields.comprobanteDomicilio && errors.comprobanteDomicilio}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="position-relative">
                <Form.Label>Identificación Oficial (por ambos lados)*</Form.Label>
                <Form.Control
                  key={inputKeys.identificacion}
                  required
                  type="file"
                  accept=".pdf"

                  onInput={() => handleBlur("identificacion")}
                  name="identificacion"
                  onChange={(event) => {

                    setFieldValue(
                      "identificacion",
                      event.currentTarget.files[0]
                    );

                  }}
                  isValid={touched.identificacion && !errors.identificacion}
                  isInvalid={touchedFields.identificacion && !!errors.identificacion}
                />
                <Form.Text muted>
                  Archivo en formato PDF. Tamaño máximo 4 MB.
                </Form.Text>
                <Form.Control.Feedback type="invalid" tooltip>
                  {touchedFields.identificacion && errors.identificacion}
                </Form.Control.Feedback>
              </Form.Group>

            </Row>
          {/*   { info.discapacidad === true &&

              <Row className="mb-3">
              <Form.Group as={Col} className="position-relative">
                <Form.Label>Credencial Nacional para Personas con Discapacidad o Documentación Equivalente*</Form.Label>
                <Form.Control
                  key={inputKeys.credencialDiscapacidad}
                  type="file"
                  accept=".pdf"
                  
                  onInput={() => handleBlur("credencialDiscapacidad")}
                  name="credencialDiscapacidad"
                  onChange={(event) => {
                    
                    setFieldValue(
                      "credencialDiscapacidad",
                      event.currentTarget.files[0]
                      );
                      
                    }}
                    isValid={touched.credencialDiscapacidad && !errors.credencialDiscapacidad}
                    isInvalid={touchedFields.credencialDiscapacidad && !!errors.credencialDiscapacidad}
                    />
                <Form.Text muted>
                  Archivo en formato PDF. Tamaño máximo 4 MB.
                </Form.Text>
                <Form.Control.Feedback type="invalid" tooltip>
                  {touchedFields.credencialDiscapacidad && errors.credencialDiscapacidad}
                </Form.Control.Feedback>
              </Form.Group>

            </Row>
            } */}
            <Row className="mb-3">
              <Form.Group as={Col} className="d-grid gap-2">
                <Button type="submit" variant="secondary" onClick={()=>console.log(errors)}>
                  Terminar Registro
                </Button>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} className="d-grid gap-2">
                <Button variant="light" onClick={() => handleBack()}>
                  {" "}
                  Regresar
                </Button>
              </Form.Group>
            </Row>

          </Form>
        )}
      </Formik>
      <Modal show={show} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Propietarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>Propietario Creado con exito</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <h1>Generando Expediente Digital </h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
