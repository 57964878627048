/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onMessageReceived = /* GraphQL */ `
  subscription OnMessageReceived($id: ID!) {
    onMessageReceived(id: $id) {
      id
      payload
      updatedAt
    }
  }
`;
export const onCreateFormularios = /* GraphQL */ `
  subscription OnCreateFormularios(
    $filter: ModelSubscriptionFormulariosFilterInput
  ) {
    onCreateFormularios(filter: $filter) {
      id
      dependencia
      tramite
      api
      campos
      metodo
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      payload
      updatedAt
    }
  }
`;
export const onDeleteFormularios = /* GraphQL */ `
  subscription OnDeleteFormularios(
    $filter: ModelSubscriptionFormulariosFilterInput
  ) {
    onDeleteFormularios(filter: $filter) {
      id
      dependencia
      tramite
      api
      campos
      metodo
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      payload
      updatedAt
    }
  }
`;
export const onUpdatePerfiles = /* GraphQL */ `
  subscription OnUpdatePerfiles(
    $filter: ModelSubscriptionPerfilesFilterInput
    $owner: String
  ) {
    onUpdatePerfiles(filter: $filter, owner: $owner) {
      id
      owner
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePerfiles = /* GraphQL */ `
  subscription OnDeletePerfiles(
    $filter: ModelSubscriptionPerfilesFilterInput
    $owner: String
  ) {
    onDeletePerfiles(filter: $filter, owner: $owner) {
      id
      owner
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTransacciones = /* GraphQL */ `
  subscription OnCreateTransacciones(
    $filter: ModelSubscriptionTransaccionesFilterInput
    $owner: String
  ) {
    onCreateTransacciones(filter: $filter, owner: $owner) {
      id
      owner
      tramite
      amount
      status
      paymenturl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTransacciones = /* GraphQL */ `
  subscription OnUpdateTransacciones(
    $filter: ModelSubscriptionTransaccionesFilterInput
    $owner: String
  ) {
    onUpdateTransacciones(filter: $filter, owner: $owner) {
      id
      payload
      updatedAt
    }
  }
`;
export const onDeleteTransacciones = /* GraphQL */ `
  subscription OnDeleteTransacciones(
    $filter: ModelSubscriptionTransaccionesFilterInput
    $owner: String
  ) {
    onDeleteTransacciones(filter: $filter, owner: $owner) {
      id
      owner
      tramite
      amount
      status
      paymenturl
      createdAt
      updatedAt
    }
  }
`;
