import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { isMobile } from 'react-device-detect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const NameFieldValidVehicular = ({ marca, linea, sublinea, modelo, serie, placa }) => {
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(panel === "panel1" && !expanded);
    };
    return (
        isMobile ?

            <Accordion  expanded={expanded} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                 
                    <div>
                        <span className='text-[#a0aec0] text-sm font-semibold'>DETALLES DEL VEHICULO</span>
                        
                    </div>
                    
                </AccordionSummary>
                <AccordionDetails>
                <div>
                        <span className='text-[#a0aec0] text-sm font-semibold'>VALIDADO</span>
                        <CheckCircleOutlineIcon className='mx-3' sx={{ color: '#767676' }} />
                    </div>

                    <div className=' w-full'>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Marca: </strong>{marca}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Modelo: </strong> {modelo}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Linea: </strong> {linea}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>SubLinea: </strong>{sublinea}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Número de Serie: </strong>{serie}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Placa: </strong>{placa}</div>
                    </div>

                </AccordionDetails>
            </Accordion>
            :

          
                <div className='flex flex-col border-solid border border-[#ecf0f4] rounded-md ' >
                    <div className='flex justify-between w-full'>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Marca: </strong>{marca}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Modelo: </strong> {modelo}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Linea: </strong> {linea}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>SubLinea: </strong>{sublinea}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Número de Serie: </strong>{serie}</div>
                        <div className='font-semibold text-sm  mt-2 ml-2'><strong>Placa: </strong>{placa}</div>
                        <div className='mt-2'>
                            <span className='text-[#a0aec0] text-sm font-semibold'>VALIDADO</span>
                            <CheckCircleOutlineIcon className='mx-3' sx={{ color: '#767676' }} />
                        </div>
                    </div>
                </div>
           


    );
};

export default NameFieldValidVehicular;