import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import { API, Auth } from "aws-amplify";
import Row from 'react-bootstrap/Row';
import { InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import { publishMessage } from '../../graphql/mutations';
import * as yup from 'yup';
import { View, } from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom'; 
import { Button as ButtonB } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import ResumenCard from './ResumenCard';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';
import {Loader} from "@aws-amplify/ui-react";

const steps = ['Información', 'Formas de pago'];

export const FormCorreccionActas = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [metodoPago, setMetodoPago] = useState('pago1');
    const validationSchema = yup.object().shape({
        total: yup.string().required("Este Campo es Obligatorio")
    });

    const handleNext = () => {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const formik = useFormik({
        validationSchema,
        onSubmit: values => {
            handleNext()
        },
        initialValues: {
            total: '',   
        }
    });


    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>CORRECCIÓN DE ACTAS </View>
                <View className='FormSubTitle'>Actas</View>
                <View className='FormDescripcion'>
                    A personas fisicas, personas morales, unidades economicas y entidades gubernamentales que generen
                    emisiones de gases de efecto invernadero en sus procesos productivos y operaciones dentro del
                    territorio del Estado de Durango, México.
                </View>
            </div>

            <View className='ViewPrincipal'>
                <View className='FormContent'>
                    <Form
                        onSubmit={formik.handleSubmit}
                        noValidate
                        className="ViewActa"
                    >
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};


                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel  {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                <label>
                                    Descripcion: Pulsa el boton para generar tu orden de
                                    pago.
                                </label>
                                </Typography>
                                <Box
                                sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                                style={{ justifyContent: "space-between" }}
                                >
                                <Button
                                    variant="secondary"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                >
                                    Anterior
                                </Button>

                                <Button type="submit" variant="secondary">
                                    Enviar
                                </Button>
                                </Box>
                            </React.Fragment>
                            ) : (
                            <React.Fragment>
                            {activeStep === 0 ? (
                                <Box>
                                    <Row className='mb-3'>
                                        <Form.Group
                                            as={Col}
                                            controlId="validationFormik102"
                                            className="position-relative"
                                        >
                                            <Form.Label > Total a pagar*</Form.Label>
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                name="total"
                                                value={formik.values.total}
                                                onChange={formik.handleChange}
                                                isValid={formik.touched.total && !formik.errors.total}
                                                isInvalid={!!formik.errors.total && formik.touched.total}
                                            />

                                            <Form.Control.Feedback type="invalid" tooltip>{formik.errors.total}</Form.Control.Feedback>
                                        </Form.Group>

                                    </Row>
                                </Box>
                                 ) : (
                                    activeStep === 1 && (
                                      <FormPagos
                                        metodoPago={metodoPago}
                                        setMetodoPago={setMetodoPago}
                                      />
                                    )
                                  )}
                            </React.Fragment>
                             )}
                        </Box>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="d-grid gap-2">
                                <ButtonB onClick={handleNext} variant='secondary' isDisabled={isLoading}>
                                    {isLoading ?
                                        <>
                                            Loading
                                            <Loader
                                            />
                                        </>
                                        :
                                        <>
                                            SIGUIENTE

                                        </>

                                    }
                                </ButtonB>
                            </Form.Group>
                        </Row>


                        <Row className="mb-3">
                            <Form.Group as={Col} className="d-grid gap-2">
                                <ButtonB onClick={handleBack} variant="light">
                                    Regresar
                                </ButtonB>
                            </Form.Group>

                        </Row>
                    </Form>
                </View>
            </View>
        </>
    )
}