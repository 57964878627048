import { useState } from 'react'
import { Button, Form, Row, Col, Accordion } from 'react-bootstrap';
import { View } from '@aws-amplify/ui-react';
import "../../App.css";

export const DetallesVehiculos = () => {
  return (
    <>
    <div className='HeaderTramite'>
        <View className='FormTitle'>Alta de Flotillas Foráneas</View>
        <View className='FormSubTitle'>Detalles de Flotillas</View>
        <View className='FormDescripcionFlotillas'>
            <div className='InfoFlotillas'>
                <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>Nombre, denominación o razón social:</strong> </span>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>Domicilio Fiscal: </strong></span>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>RFC:</strong></span>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>Correo Electrónico:</strong></span>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>Teléfono:</strong></span>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                    <span style={{ display: "flex", justifyContent: "center" }}> Empresa Comercializadora Prueba S.A De C.V.</span>
                    <span style={{ display: "flex", justifyContent: "center" }}>Torreón, Coahuila</span>
                    <span style={{ display: "flex", justifyContent: "center" }}>XXX-XXXXXX-XXX</span>
                    <span style={{ display: "flex", justifyContent: "center" }}>Prueba@empresa.com</span>
                    <span style={{ display: "flex", justifyContent: "center" }}>000-000-00-00</span>
                </div>
            </div>
        </View>
    </div>

    <View className="ViewPrincipal">
        <View className="ViewContent">
       
            <Accordion  alwaysOpen>
                <Accordion.Item eventKey="0" className='mb-3'>
                    <Accordion.Header style={{textDecoration:"underline"}}>Flotilla Número 1234</Accordion.Header>
                    <Accordion.Body>
                    <Row className='mb-3'>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Num. Série</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    placeholder='0000 0000 000 000'
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Modelo</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Modelo'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Estado de Procedencia</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    placeholder='Estado de Procedencia'
                                    readOnly
                                />
                            </Form.Group>

                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} >
                                <Form.Label>Clase de Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Clase de Vehículo'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Tipo de Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Tipo de Vehículo'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Combustible</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Combustible'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Num. de Puertas</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Capacidad (Tons)</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} >
                                <Form.Label>Cilindros</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Origen del Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Uso del Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Tipo de Servicio</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Subtipo de Servicio</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>

                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} >
                                <Form.Label>Num. de Pedimento</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Num. de Motor</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Color</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>NCI o NRPV</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Constancia</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>

                        </Row>
                        <Row className='mb-3'>

                            <Form.Group as={Col}>
                                
                                <Button variant='secondary' style={{width:"10%"}}>Listo</Button>
                            </Form.Group>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className='mb-3'>
                    <Accordion.Header style={{textDecoration:"underline"}}>Flotilla Número 322</Accordion.Header>
                    <Accordion.Body>
                    <Row className='mb-3'>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Num. Série</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    placeholder='0000 0000 000 000'
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Modelo</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Modelo'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Estado de Procedencia</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    placeholder='Estado de Procedencia'
                                    readOnly
                                />
                            </Form.Group>

                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} >
                                <Form.Label>Clase de Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Clase de Vehículo'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Tipo de Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Tipo de Vehículo'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Combustible</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Combustible'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Num. de Puertas</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Capacidad (Tons)</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} >
                                <Form.Label>Cilindros</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Origen del Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Uso del Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Tipo de Servicio</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Subtipo de Servicio</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>

                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} >
                                <Form.Label>Num. de Pedimento</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Num. de Motor</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Color</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>NCI o NRPV</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Constancia</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>

                        </Row>
                        <Row className='mb-3'>

                            <Form.Group as={Col}>
                                
                                <Button variant='secondary' style={{width:"10%"}}>Listo</Button>
                            </Form.Group>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='mb-3'> 
                    <Accordion.Header style={{textDecoration:"underline"}}>Flotilla Número 431</Accordion.Header>
                    <Accordion.Body>
                    <Row className='mb-3'>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Num. Série</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    placeholder='0000 0000 000 000'
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Modelo</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Modelo'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Estado de Procedencia</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    placeholder='Estado de Procedencia'
                                    readOnly
                                />
                            </Form.Group>

                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} >
                                <Form.Label>Clase de Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Clase de Vehículo'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Tipo de Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Tipo de Vehículo'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Combustible</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Combustible'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Num. de Puertas</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Capacidad (Tons)</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} >
                                <Form.Label>Cilindros</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Origen del Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Uso del Vehículo</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Tipo de Servicio</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Subtipo de Servicio</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>

                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} >
                                <Form.Label>Num. de Pedimento</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Num. de Motor</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Color</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>NCI o NRPV</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label>Constancia</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>

                        </Row>
                        <Row className='mb-3'>

                            <Form.Group as={Col}>
                                
                                <Button variant='secondary' style={{width:"10%"}}>Listo</Button>
                            </Form.Group>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </View>
    </View>
</>
    )
}
