const ENDPOINT_SEI = process.env.REACT_APP_SEI_API_ENDPOINT;
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;


export function VerificarClaveAPI( clave, token) {
    const url = `${ENDPOINT_SEI}/sei/servicios/predial/validate`
    const params = {
        method: "POST",
        body: JSON.stringify({
            "claveCatastral": clave //Clave del Movimiento(Interno)
        }),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };
    return fetch(url, params).then(response => {
        return response.json();
    }).then(result => {
      
        if(result.error === undefined){

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }else{
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err => {
        return {
            ok: false,
            message: err.message
        };
    })
}

export function CalcularPredialAPI(data,token){
    const url = `${ENDPOINT_SEI}/sei/servicios/predial/calculate`
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };
    return fetch(url, params).then(response => {
        return response.json();
    }).then(result => {
      
        if(result.error === undefined){

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }else{
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err => {
        return {
            ok: false,
            message: err.message
        };
    })
}