import React, { useEffect } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate,useLocation } from 'react-router-dom'
import { CrearIteamAPI } from "../../api/Licencias"
import { toast, ToastContainer } from 'react-toastify';
import { getCurrentUser } from 'aws-amplify/auth';
import { useTramiteDataGeneral } from '../../TramiteContext';
import OpcionesSelect from './OpcionesSelect';
export const FormContribuyente = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const datos = location.state;
    const { saveJsonToLocalStorage } = useTramiteDataGeneral();
    const schema = yup.object().shape({
        nombre: yup.string().required("Este valor es Obligatorio"),
        primerApellido: yup.string().required("Este valor es Obligatorio"),
        segundoApellido: yup.string(),
        curp: yup.string().required("Este valor es Obligatorio"),
        residencia: yup.string().required("Este valor es Obligatorio"),
        paisResidencia: yup.string().required("Este valor es Obligatorio"),
        estadoResidencia: yup.string(),
    });
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "curp") {
            values = values.replace(/[^A-Z0-9]/g, '');

            setFieldValue(name, values);
        } else {

            values = values.replace(/[^A-Z\s]/g, '');

            setFieldValue(name, values);
        }
    };
    const CrearIteam = async (values) => {
        const user = await getCurrentUser();
        const data = {

            "procedure": datos,
            "type": "nueva",
            "usuarioID": user.username,
            "nombre": values.nombre,
            "primerApellido": values.primerApellido,
            "segundoApellido": values.segundoApellido,
            "curp": values.curp,
            "pais": values.paisResidencia,
            "estado": parseInt(values.estadoResidencia),
            "domicilioOrigen":values.residencia
        }
        CrearIteamAPI(data).then(result => {

            if (result.ok) {
                console.log(result)
                saveJsonToLocalStorage("DatosLicencias", result.message.item)
                navigate("/tramite/DPC-Licencias/DatosGenerales")
            } else {

                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(error => {
            console.log(error)
        })
    }
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Trámite de Licencias</View>
                <View className='FormSubTitle'>Validación del Contribuyente</View>
            </div>

            <View className="ViewPrincipal">
                <Row className='mb-3'>
                    <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                        <button type="button" onClick={() => navigate("/tramite/DPC-Licencias/Historial")}
                            className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                            <FaArrowLeft /> Volver al Inicio
                        </button>
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                    </Form.Group>
                    <Form.Group className='d-grid gap-2' as={Col} md="3">
                    </Form.Group>
                </Row>
                <View className="ViewContent">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            nombre: "",
                            primerApellido: "",
                            segundoApellido: "",
                            curp: "",
                            residencia: "",
                            paisResidencia: "",
                            estadoResidencia: "",
                        }}
                        onSubmit={values => {
                            const myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");

                            const raw = JSON.stringify({
                                "servicio": {
                                    "claveServicio": "CURP"
                                }
                            });

                            const requestOptions = {
                                method: "POST",
                                headers: myHeaders,
                                body: raw,
                                redirect: "follow"
                            };

                            fetch("https://19m6l8mzik.execute-api.us-east-1.amazonaws.com/dev/servicios/sei/servicios/validate", requestOptions)
                                .then((response) => response.json())
                                .then((result) => {
                                    if (result.error === undefined) {
                                        toast.success("Validacíon Correcta", {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                        CrearIteam(values);
                                    } else {

                                        toast.error(result.message, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                    }
                                })
                                .catch((error) => console.error(error));

                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldTouched, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} noValidate className='ViewActa'>
                                <Row className='mb-3'>
                                    <Form.Label style={{ textDecoration: "underline" }}>Datos Requeridos</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Nombre (s)*</Form.Label>
                                        <Form.Control
                                            name='nombre'
                                            type='text'
                                            placeholder='Nombre (s)'
                                            value={values.nombre}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.nombre && !errors.nombre}
                                            isInvalid={touched.nombre && !!errors.nombre}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.nombre}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Primer Apellido*</Form.Label>
                                        <Form.Control
                                            name='primerApellido'
                                            type='text'
                                            placeholder='Primer Apellido'
                                            value={values.primerApellido}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.primerApellido && !errors.primerApellido}
                                            isInvalid={touched.primerApellido && !!errors.primerApellido}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.primerApellido}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Segundo Apellido</Form.Label>
                                        <Form.Control
                                            name='segundoApellido'
                                            type='text'
                                            placeholder='Segundo Apellido'
                                            value={values.segundoApellido}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.segundoApellido && !errors.segundoApellido}
                                            isInvalid={touched.segundoApellido && !!errors.segundoApellido}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.segundoApellido}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>CURP</Form.Label>
                                        <Form.Control
                                            name='curp'
                                            type='text'
                                            placeholder='CURP'
                                            value={values.curp}
                                            maxLength={18}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.curp && !errors.curp}
                                            isInvalid={touched.curp && !!errors.curp}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.curp}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Residencia*</Form.Label>
                                        <Form.Select
                                            name='residencia'
                                            type='text'
                                            value={values.residencia}
                                            onChange={(e) => {
                                                handleChange(e);
                                                const selectedResidencia = e.target.value;
                                                if (selectedResidencia !== values.residencia) {
                                                    setFieldValue('paisResidencia', '');
                                                    setFieldValue('estadoResidencia', '');
                                                }
                                            }}
                                            isValid={touched.residencia && !errors.residencia}
                                            isInvalid={touched.residencia && !!errors.residencia}
                                        >
                                            <option value="">SELECCIONA</option>
                                            <option value="1">NACIONAL</option>
                                            <option value="2">EXTRANJERO</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>
                                            {errors.residencia}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                {values.residencia === "1" ?
                                    <>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Pais de Residencia*</Form.Label>
                                                <Form.Select
                                                    name='paisResidencia'
                                                    type='text'
                                                    value={values.paisResidencia}
                                                    onChange={handleChange}
                                                    isValid={touched.paisResidencia && !errors.paisResidencia}
                                                    isInvalid={touched.paisResidencia && !!errors.paisResidencia}
                                                >
                                                    <option value="">SELECCIONA</option>
                                                    <option value="mexico">MÉXICO</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.paisResidencia}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Estado de Residencia*</Form.Label>
                                                <Form.Select
                                                    name='estadoResidencia'
                                                    type='text'

                                                    value={values.estadoResidencia}
                                                    onChange={handleChange}
                                                    isValid={touched.estadoResidencia && !errors.estadoResidencia}
                                                    isInvalid={touched.estadoResidencia && !!errors.estadoResidencia}
                                                >
                                                    <option value="">SELECCIONA</option>
                                                    <OpcionesSelect id={19} />

                                                </Form.Select>
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.estadoResidencia}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                    </>
                                    : values.residencia === "2" &&
                                    <>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Pais de Residencia*</Form.Label>
                                                <Form.Control
                                                    name='paisResidencia'
                                                    type='text'
                                                    value={values.paisResidencia}
                                                    onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                                    isValid={touched.paisResidencia && !errors.paisResidencia}
                                                    isInvalid={touched.paisResidencia && !!errors.paisResidencia}
                                                />

                                                <Form.Control.Feedback type='invalid' tooltip>{errors.paisResidencia}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                    </>
                                }
                                <Row className='mb-3'>
                                    <Button variant='secondary' type='submit'>SIGUIENTE</Button>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='link'>REGRESAR</Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                    <ToastContainer />
                </View>
            </View>
        </>
    )
}
