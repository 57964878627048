import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  Accordion,
  Modal,
} from "react-bootstrap";
import { View } from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Revisado from "../../assets/Revision.png";
import Bloqueado from "../../assets/Bloqueado.png";
import Inactivo from "../../assets/Inactivo.png";
import Rechazado from "../../assets/Rechazado.png";
import Aprobado from "../../assets/Aprobado.png";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FaArrowLeft } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import "../../App.css";
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;

export const HistorialPersonalidades = () => {
  const [info, setInfo] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload]= useState(false);
  const [helpView2, setHelpView2] = useState(false);
  const [helpView3, setHelpView3] = useState(false);
  const [openAccordion, setOpenAccordion] = useState([]);

  useEffect(() => {
    // Establecer el estado inicial de los acordeones cuando info.Items cambia
    if (info && info) {
      setOpenAccordion(Array(info.length).fill(true));
    }
  }, [info]);

  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      ticketType: "15",
      usuarioID: user.username,
    };

    axios
      .post(`${ENDPOINT}/listaPersonalidad`, payload)
      .then((response) => {
        response.data.Items.sort(function (a, b) {
          return new Date(b.createdAt?.S) - new Date(a.createdAt?.S)
      });
        console.log(response.data.Items)
        setInfo(response.data.Items);
        setLoading(false);
        setReload(false)
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [reload]);


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [filters, setFilters] = useState({
    ticketID: '',
    rfc: '',
    razon_social: '',
    status: ""
    // Agrega más campos según tus necesidades
  });

  if (!info || !info) {
    // Manejo de casos donde info o info.Items es undefined
    return null; // o algún otro manejo de error que prefieras
  }

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
    setCurrentPage(1);
  };
  const filteredData = info.filter(item => {
    return (
      item?.ticketID?.N.toLowerCase().includes(filters?.ticketID?.toLowerCase()) &&
      item?.rfc?.S.toLowerCase().includes(filters?.rfc?.toLowerCase()) &&
      item?.razon_social?.S.toLowerCase().includes(filters?.razon_social?.toLowerCase())&&
      item?.status?.S.toLowerCase().includes(filters?.status?.toLowerCase())

      // Agrega más condiciones según tus necesidades
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleAccordionToggle = (index) => {
    const updatedAccordion = [...openAccordion];
    updatedAccordion[index] = !updatedAccordion[index];
    setOpenAccordion(updatedAccordion);
  };
  const getStatusIcon = (status) => {
    // Implementa la lógica para obtener el icono según el estado
    // Puedes usar una función o un mapeo similar al que usaste en Bootstrap
    return status === "Revisión"
      ? Revisado
      : status === "Aprobado"
        ? Aprobado
        : status === "Rechazado"
          ? Rechazado
          : status === "Bloqueado"
            ? Bloqueado
            : status === "Inactivo"
              ? Inactivo
              : Revisado;
  };

  return (
    <>
      <div className="HeaderTramite">
        <View className="FormTitle">Emplacamiento Exprés</View>
        <View className="FormSubTitle">Propietarios</View>
        <View className="FormDescripcion">
          {" "}
          En esta página podrá dar seguimiento a los diferentes procesos de
          Acreditación de Personalidad para el trámite de Alta de Flotillas
          Foráneas.{" "}
          <HelpOutlineIcon
            style={{ padding: "5px", width: "30px", height: "30px" }}
            className="cursor- cursor-pointer"
            onClick={() => setHelpView2(true)}
          />
        </View>
      </div>

      <View className="ViewPrincipal">
        <View className="ViewContent">

          <Row className="mb-3">
            <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
              <button type="button" onClick={() => navigate("/tramite/Alta-Vehicular")}
                className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                <FaArrowLeft /> Volver al Inicio
              </button>


            </Form.Group>

            <Form.Group as={Col} md="6"></Form.Group>

            <Form.Group className="d-grid gap-2" as={Col} md="3">
              <Button
                variant="secondary"
                 onClick={()=> setHelpView3(true)} 
               /*  onClick={() => navigate("/tramite/Alta-Propietario")} */
              >
                Captura de Propietario
              </Button>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="3" className="mb-2">
              <Form.Control
                type="text"
                name="Folio"
                value={filters.ticketID}
                onChange={e => handleFilterChange('ticketID', e.target.value)}
                placeholder="Folio"
              />
            </Form.Group>
            <Form.Group as={Col} md="3" className="mb-2">
              <Form.Control
                type="text"
                value={filters.razon_social}
                onChange={e => handleFilterChange('razon_social', e.target.value)}
                name="razon_social"
                placeholder="Razón Social"
              />
            </Form.Group>
            <Form.Group as={Col} md="3" className="mb-2">
              <Form.Control
                type="text"
                value={filters.rfc}
                onChange={e => handleFilterChange('rfc', e.target.value)}
                placeholder="RFC"
                name="rfc"
              />
            </Form.Group>
            <Form.Group as={Col} md="2" className="mb-2"> 
              <Form.Select
                type="text"
                onChange={e => handleFilterChange('status', e.target.value)}
                value={filters.status}
                name="status"
              >
                <option value="">Seleccionar Estado</option>
                <option value="Revisión">Revisión</option>
                <option value="Aprobado">Aprobado</option>
                <option value="Rechazado">Rechazado</option>
                <option value="Bloqueado">Bloqueado</option>
                <option value="Inactivo">Inactivo</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}   style={{display:"flex", alignItems:"center", justifyContent:"right"}}>
              <Button variant="link" onClick={()=>setReload(true)} ><TfiReload /></Button>
            </Form.Group>
          </Row>

          <div className={`accordion-list ${!reload ? 'open' : ''}`}>
            {currentItems.length !== 0 &&
              currentItems.map(
                (data, index) =>
                  data.ticketID !== undefined && (
                    <div
                      key={index}
                      className="mb-3 border border-gray-300 rounded overflow-hidden"
                    >
                      <div
                        className="cursor-pointer bg-[#e0e6e0] py-2 px-4 flex justify-between items-center"
                        onClick={() => handleAccordionToggle(index)}
                      >
                        {data.ticketID.N === undefined ? (
                          <label className="text-lg font-bold underline">
                            Propietario: Sin Folio asignado
                          </label>
                        ) : (
                          <>
                            <label className="text-lg font-bold underline">
                              Propietario: #{data.ticketID.N}
                            </label>
                            <span className="flex items-center font-bold underline">
                              Estado:{" "}
                              <img
                                src={getStatusIcon(data.status.S)}
                                alt="Status"
                              />{" "}
                              {data.status.S}
                            </span>
                          </>
                        )}
                      </div>
                      {openAccordion[index] && (
                        <div className="p-4 flex justify-between" id="DivAcordeon">
                          <div className="mb-3 divRazonSocial" style={{ width: "15rem" }} >
                            <label className="block font-bold underline">
                            {data.tipoPersonalidad.S !== "Moral" ? "Nombre": "Razón Social" }
                              
                            </label>
                            <span>{data.razon_social.S}</span>
                          </div>
                          <div className="mb-3">
                            <label className="block font-bold underline">
                              RFC
                            </label>
                            <span>{data.rfc.S}</span>
                          </div>
                          
                          <div className="flex justify-end">
                            <Button
                              className="h-10"
                              variant="secondary"
                              onClick={() =>{

                                if(data.tipoPersonalidad.S === "Moral"){
                                  navigate("/tramite/Info-Propietario", {
                                    state: data,
                                  })
                                }else{
                                  navigate("/tramite/Info-Propietario-Fisica", {
                                    state: data,
                                  })
                                }
                              }
                              }
                            >
                              Ver Detalles
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  )
              )}
          </div>

          {filteredData.length > itemsPerPage && (
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <Pagination
                count={Math.ceil(filteredData.length / itemsPerPage)}
                page={currentPage}
                onChange={handleChange}
              />
            </Stack>
          )}
        </View>
      </View>
      <Modal
        show={helpView2}
        animation={false}
        onHide={() => setHelpView2(false)}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            El proceso de Acreditación de Personalidad puede transitar por los
            siguientes estados:{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Captura</strong> – El expediente digital de la Acreditación
            de la Personalidad ha sido Creado, pero aún no ha sido Asignado a un
            Analista para su revisión <br></br>
            <strong>Revisión</strong> - El expediente digital ha sido asignado a
            un Supervisor y ha comenzado el proceso de Acreditación <br></br>
            <strong>Aprobado</strong> – El expediente se considera completo y
            correcto, por lo que dicha Personalidad puede ser utilizada para el
            registro de Flotillas de Vehículos Foráneos <br></br>
            <strong>Rechazado</strong> –El expediente se considera incompleto,
            por lo que el Contribuyente debe de seguir la retroalimentación e
            indicaciones del Analista o Supervisor <br></br>
            <strong>Bloqueado</strong> – El analista ha llegado a la conclusión
            de que la información sometida para el proceso de acreditación
            cuenta con una serie de anomalías que justifican una acción de
            Bloqueo, siendo de manera efectiva, una cancelación de dicha
            Personalidad para el presente y futuros trámites. <br></br>
            <strong>Inactivo</strong> – El Contribuyente ha marcado el trámite
            de Acreditación de Personalidad como cancelado, finalizando las
            actividades de Acreditación <br></br>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setHelpView2(false)}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={helpView3}
        animation={false}
        onHide={() => setHelpView3(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Emplacamiento Exprés
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Tipo de personalidad que desea registrar:
        </Modal.Body>
        <Modal.Footer style={{display:"flex", justifyContent:"space-between"}}>
          <Button variant="secondary" onClick={() => navigate("/tramite/Alta-Propietario")}>
            Moral
          </Button>
          <Button variant="secondary" onClick={() => navigate("/tramite/FormPersonaFisica")}>
          Física
          </Button>
        </Modal.Footer>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <h1>Cargando Información</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
