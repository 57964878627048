import React from 'react'
import personalidadesLogo from '../../assets/Personalidades.png'
import flotillaslogo from '../../assets/Flotillas.png'
import { View } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'
import {AiOutlineEye} from 'react-icons/ai'
import Flujo from '../../assets/Flujo.png'
import '../../App.css'
export const HomeAltaVehicular = () => {
    const navigate = useNavigate();


  return (
    <>
              <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Emplacamiento Exprés</View>
                <View className="FormDescripcion">En esta página podrá realizar los trámites de Acreditación de Personalidad, Acreditación Vehicular, y de seguimiento de Flotillas Foráneas.</View>
            </div>

            <View className="ViewPrincipal">
                <View className="ViewContent">
                  <ul  className='DivAltaVehicular'>
                    <li  onClick={()=>navigate("/tramite/Historial-Propietarios")}>
                        <div style={{margin:"auto"}}>
                        <img src={personalidadesLogo} alt="LOGO" width={80}/>
                        </div>
                        <label >Propietarios</label>
                        <span >Registro de Nuevos Propietarios y seguimiento de Acreditación</span>
                    </li>
                   <li  onClick={()=> navigate("/tramite/Historial-Flotillas")}>
                        <div style={{margin:"auto"}}>
                        <img src={flotillaslogo} alt="LOGO" width={80}/>
                        </div>
                        <label>Mis Flotillas</label>
                        <span >Registro y Seguimiento de Flotillas Foráneas y Acreditación Vehicular</span>
                    </li>
                  </ul>
                   <div style={{width:"70%", margin:"auto"}}>
                    <img src={Flujo} alt="" width="100%"/>
                 
                  </div> 
                </View>
            </View>
          
        </>
    </>
  )
}
