import React from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
export const FormDatosDomicilio = (props) => {
    const { datos, setChangeData, changeData } = props;
    const schema = yup.object().shape({
        tipoLicencia: yup.string().required("Este valor es Obligatorio"),
        tipoTramite: yup.string().required("Este valor es Obligatorio"),
        pais: yup.string().required("Este valor es Obligatorio"),
        estado: yup.string().required("Este valor es Obligatorio"),
        municipio: yup.string(),
        localidad: yup.string().required("Este Campo es Obligatorio"),
        origenDomicilio: yup.string().required("Este Campo es Obligatorio"),
        colonia: yup.string().required("Este Campo es Obligatorio"),
        tipoCalle: yup.string().required("Este Campo es Obligatorio"),
        calle: yup.string().required("Este valor es Obligatorio"),
        numExterior: yup.string().required("Este Campo es Obligatorio"),
        numInterior: yup.string(),
        letra: yup.string(),
        codigoPostal: yup.string().required("Este valor es Obligatorio")
    });
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "numExterior" || name === "numInterior" || name === "codigoPostal") {
            values = values.replace(/[^0-9]/g, '');

            setFieldValue(name, values);
        } else {

            values = values.replace(/[^A-Z0-9\s]/g, '');

            setFieldValue(name, values);
        }
    };
    return (
        <>
            <View className="">
                <View className="">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            tipoTramite: datos.type,
                            tipoLicencia: datos.procedure,
                            pais: datos.domicilioResidencia !== null ? datos.domicilioResidencia.pais : "",
                            estado: datos.domicilioResidencia !== null ? datos.domicilioResidencia.estado : "",
                            municipio: datos.domicilioResidencia !== null ? datos.domicilioResidencia.municipio : "",
                            localidad: datos.domicilioResidencia !== null ? datos.domicilioResidencia.localidad : "",
                            colonia: datos.domicilioResidencia !== null ? datos.domicilioResidencia.colonia : "",
                            origenDomicilio: datos.domicilioResidencia !== null ? datos.domicilioResidencia.origenDomicilio : "",
                            tipoCalle: datos.domicilioResidencia !== null ? datos.domicilioResidencia.tipoCalle : "",
                            calle: datos.domicilioResidencia !== null ? datos.domicilioResidencia.calle : "",
                            numExterior: datos.domicilioResidencia !== null ? datos.domicilioResidencia.numeroExterior : "",
                            numInterior: datos.domicilioResidencia !== null ? datos.domicilioResidencia.numeroInterior : "",
                            letra: datos.domicilioResidencia !== null ? datos.domicilioResidencia.letra : "",
                            codigoPostal: datos.domicilioResidencia !== null ? datos.domicilioResidencia.codigoPostal : "",
                        }}
                        onSubmit={async values => {

                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": "nueva",
                                "usuarioID": user.username,
                                "domicilioResidencia": {
                                    "pais": values.pais,
                                    "estado": values.estado,
                                    "municipio": values.municipio,
                                    "localidad": values.localidad,
                                    "colonia": values.colonia,
                                    "tipoCalle": values.tipoCalle,
                                    "calle": values.calle,
                                    "numeroExterior": values.numExterior,
                                    "letra": values.letra,
                                    "numeroInterior": values.numInterior,
                                    "codigoPostal": values.codigoPostal,
                                    "origenDomicilio": values.origenDomicilio
                                },

                            }
                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setChangeData(!changeData)
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                            }).catch(error => {
                                console.log(error)
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Licencia</Form.Label>
                                        <Form.Select
                                            name='tipoLicencia'
                                            type='text'
                                            disabled
                                            value={values.tipoLicencia}
                                            onChange={handleChange}
                                            isValid={touched.tipoLicencia && !errors.tipoLicencia}
                                            isInvalid={touched.tipoLicencia && !!errors.tipoLicencia}
                                        >
                                            <OpcionesSelect id={"LICENCIAS"} valor={"categoria"} />
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoLicencia}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Tramite</Form.Label>
                                        <Form.Control
                                            name='tipoTramite'
                                            type='text'
                                            disabled
                                            value={values.tipoTramite.toUpperCase()}
                                            onChange={handleChange}
                                            isValid={touched.tipoTramite && !errors.tipoTramite}
                                            isInvalid={touched.tipoTramite && !!errors.tipoTramite}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoTramite}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Label style={{ textDecoration: "underline" }}>Domicilio de Residencia</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Origen Domicilio*</Form.Label>
                                        <Form.Select
                                            name='origenDomicilio'
                                            type='text'
                                            value={values.origenDomicilio}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            onChange={(e) => {
                                                handleChange(e);
                                                const selectedResidencia = e.target.value;
                                                if (selectedResidencia !== values.origenDomicilio) {
                                                    setFieldValue('pais', '');
                                                    setFieldValue('estado', '');
                                                }
                                            }}
                                            isValid={touched.origenDomicilio && !errors.origenDomicilio}
                                            isInvalid={touched.origenDomicilio && !!errors.origenDomicilio}
                                        >
                                            <option value="">SELECCIONA</option>
                                            <option value="1">NACIONAL</option>
                                            <option value="2">EXTRANJERO</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.origenDomicilio}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                {values.origenDomicilio === "1" ?
                                    <>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Pais*</Form.Label>
                                                <Form.Select
                                                    name='pais'
                                                    type='text'
                                                    value={values.pais}
                                                    onChange={handleChange}
                                                    disabled={datos.domicilioResidencia !== null ? true : false}
                                                    isValid={touched.pais && !errors.pais}
                                                    isInvalid={touched.pais && !!errors.pais}
                                                >
                                                    <option value="">SELECCIONA</option>
                                                    <option value="mexico">MÉXICO</option>

                                                </Form.Select>
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.pais}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Estado*</Form.Label>
                                                <Form.Select
                                                    name='estado'
                                                    type='text'
                                                    value={values.estado}
                                                    onChange={handleChange}
                                                    disabled={datos.domicilioResidencia !== null ? true : false}
                                                    isValid={touched.estado && !errors.estado}
                                                    isInvalid={touched.estado && !!errors.estado}
                                                >

                                                    {datos.domicilioResidencia === null ?

                                                        <option value="">SELECCIONA</option>
                                                        : null}
                                                    <OpcionesSelect id={19} />

                                                </Form.Select>
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.estado}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                    </>
                                    : values.origenDomicilio === "2" &&
                                    <>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Pais*</Form.Label>
                                                <Form.Control
                                                    name='pais'
                                                    type='text'
                                                    placeholder='PAIS'
                                                    value={values.pais}
                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                    disabled={datos.domicilioResidencia !== null ? true : false}
                                                    isValid={touched.pais && !errors.pais}
                                                    isInvalid={touched.pais && !!errors.pais}
                                                />
                                                

                                
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.pais}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Estado*</Form.Label>
                                                <Form.Control
                                                    name='estado'
                                                    type='text'
                                                    placeholder='ESTADO'
                                                    value={values.estado}
                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                    disabled={datos.domicilioResidencia !== null ? true : false}
                                                    isValid={touched.estado && !errors.estado}
                                                    isInvalid={touched.estado && !!errors.estado}
                                                />

                                                    

                                            
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.estado}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                    </>
                                }

                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Código Postal*</Form.Label>

                                        <Form.Control
                                            name='codigoPostal'
                                            type='text'
                                            placeholder='Código Postal'
                                            value={values.codigoPostal}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            isValid={touched.codigoPostal && !errors.codigoPostal}
                                            isInvalid={touched.codigoPostal && !!errors.codigoPostal}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.codigoPostal}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Municipio*</Form.Label>
                                        <Form.Control
                                            name='municipio'
                                            type='text'
                                            placeholder='Municipio'
                                            value={values.municipio}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            isValid={touched.municipio && !errors.municipio}
                                            isInvalid={touched.municipio && !!errors.municipio}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.municipio}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Localidad*</Form.Label>
                                        <Form.Control
                                            name='localidad'
                                            type='text'
                                            placeholder='Localidad'
                                            value={values.localidad}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            isValid={touched.localidad && !errors.localidad}
                                            isInvalid={touched.localidad && !!errors.localidad}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.localidad}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Colonia*</Form.Label>
                                        <Form.Control
                                            name='colonia'
                                            type='text'
                                            placeholder='Colonia'
                                            value={values.colonia}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            isValid={touched.colonia && !errors.colonia}
                                            isInvalid={touched.colonia && !!errors.colonia}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.colonia}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>


                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Calle*</Form.Label>
                                        <Form.Select
                                            name='tipoCalle'
                                            type='text'
                                            placeholder='Tipo de Calle'
                                            value={values.tipoCalle}
                                            onChange={handleChange}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            isValid={touched.tipoCalle && !errors.tipoCalle}
                                            isInvalid={touched.tipoCalle && !!errors.tipoCalle}
                                        >
                                            {datos.domicilioResidencia === null ?

                                                <option value="">SELECCIONA</option>
                                                : null}
                                            <OpcionesSelect id={6} />
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoCalle}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Calle*</Form.Label>

                                        <Form.Control
                                            name='calle'
                                            type='text'
                                            placeholder='Calle'
                                            value={values.calle}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            isValid={touched.calle && !errors.calle}
                                            isInvalid={touched.calle && !!errors.calle}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.calle}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Número Exterior*</Form.Label>

                                        <Form.Control
                                            name='numExterior'
                                            type='text'
                                            placeholder='Numero Exterior'
                                            value={values.numExterior}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            isValid={touched.numExterior && !errors.numExterior}
                                            isInvalid={touched.numExterior && !!errors.numExterior}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.numExterior}</Form.Control.Feedback>


                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Número Interior</Form.Label>

                                        <Form.Control
                                            name='numInterior'
                                            type='text'
                                            placeholder='Numero Interior'
                                            value={values.numInterior}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            isValid={touched.numInterior && !errors.numInterior}
                                            isInvalid={touched.numInterior && !!errors.numInterior}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.numInterior}</Form.Control.Feedback>


                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Letra</Form.Label>

                                        <Form.Control
                                            name='letra'
                                            type='text'
                                            placeholder='Letra'
                                            value={values.letra}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.domicilioResidencia !== null ? true : false}
                                            isValid={touched.letra && !errors.letra}
                                            isInvalid={touched.letra && !!errors.letra}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.letra}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>

                                <Row className='mb-3'>
                                    <Button variant='secondary' disabled={datos.domicilioResidencia !== null ? true : false} type='submit'>GUARDAR</Button>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='link'>REGRESAR</Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
        </>
    )
}
