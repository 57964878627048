import React from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import logo from "../src/assets/logoicon.png";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router";

const App = ({ signOut }) => {

  return(
 
      <BrowserRouter>
          <AppRouter />
          {/*  <RouterProvider router={router} /> */}
        </BrowserRouter>
    
  )
};

export default App;

export const Head = () => (
  <>
    <link rel="icon" href={logo} />
  </>
);