import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { View } from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from "@mui/material/CircularProgress";
import { FaArrowLeft } from "react-icons/fa";
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR  ;

export const FormCapturaFlotilla = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object().shape({
    personaMoral: yup.string().required("Este campo es obligatorio"),
   /*  facturacion: yup.boolean() */

  });
  const formik = useFormik({
    validationSchema,
    onSubmit: (values) => {
     
    /*   const tipoFacturacion = values.facturacion === true? "Individual" : "Global"; */
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        personalidadID: values.personaMoral,
        tipoFacturacion : "Individual",
        usuarioID: user.username,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${ENDPOINT}/flotilla`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.ticket !== undefined){
            setLoading(false);
            navigate("/tramite/Captura-vehiculos", { state: result })
          }else{
            setLoading(false);
            toast.error(result.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
          /*Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: result.message,
                        showConfirmButton: false,
                        timer: 1000
                    });
                    */




        })
        .catch(error => {
          console.log('error', error)
          setLoading(false);

        }); 
    },
    initialValues: {
      personaMoral: "",
     
    }
  });
  useEffect(() => {
    setLoading(true);
    const payload = {

      "ticketType": "15",
      "usuarioID": user.username
    }

    axios.post(`${ENDPOINT}/listaComplete`, payload).then(response => {

      setInfo(response.data);
      setLoading(false);
      
    }).catch(error => {
      console.log(error);
    })
  }, [])

  const handleBlur = field => {
    formik.setFieldTouched(field, true);
  };
  return (
    <>
      <div className='HeaderTramite'>
        <View className='FormTitle'>Emplacamiento Exprés</View>
        <View className='FormSubTitle'>Captura de Flotilla</View>
        <View className='FormDescripcion'>
          Para realizar el proceso de Flotilla Foránea es necesario contar con una Personalidad Acreditada. Una vez finalizada la captura de los vehículos de la Flotilla, deberá mantenerse al tanto de los comentarios del funcionario asignado para poder realizar su acreditación de manera exitosa.
          <br /> La primera flotilla por Personalidad debe de contar con al menos 500 vehículos. Flotillas subsecuentes no tienen número mínimo.
        </View>
      </div>
      <View className="ViewPrincipal">
        <View className="ViewContent">
          <Row className='mb-0'>
            <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
              <button type="button" onClick={() => navigate("/tramite/Historial-Flotillas")}
                className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                <FaArrowLeft /> Volver al Inicio
              </button>
            </Form.Group>

            <Form.Group as={Col} md="7"></Form.Group>
          </Row>
          <Form noValidate onSubmit={formik.handleSubmit} className="ViewActa">
            <Row className="mb-3">
              <Form.Group as={Col} className="position-relative">
                <Form.Label>Personalidad*</Form.Label>
                <Form.Select
                  type="text"
                  name="personaMoral"
                  value={formik.values.personaMoral}
                  onChange={formik.handleChange}
                  isValid={
                    formik.touched.personaMoral && !formik.errors.personaMoral
                  }
                  isInvalid={
                    formik.touched.personaMoral && !!formik.errors.personaMoral
                  }
                >
                  <option value="">Selecciona</option>
                  {info.length !== 0 &&
                
                    info.map((data, index) => (
                      <option key={index} value={data.id}>
                      {data.tipoPersonalidad === "Moral"  ?  `RFC: ${data.rfc} - Razón social: ${data.razon_social}` : `RFC: ${data.rfc} - Nombre: ${data.razon_social}` } 
                      </option>
                    ))
                 
                    }
                </Form.Select>
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.errors.personaMoral}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          {/*   <Row className="mb-3">
              <Form.Group as={Col} className="position-relative">
                <Form.Label>Número de Vehículos*</Form.Label>
                <Form.Control
                  type="number"
                  name="cantidadVehiculos"
                  value={formik.values.cantidadVehiculos}
                  onChange={formik.handleChange}
                  isValid={
                    formik.touched.cantidadVehiculos &&
                    !formik.errors.cantidadVehiculos
                  }
                  isInvalid={
                    formik.touched.cantidadVehiculos &&
                    !!formik.errors.cantidadVehiculos
                  }
                  onBlur={() => handleBlur('cantidadVehiculos')}
                >
                </Form.Control>
                <Form.Control.Feedback type="invalid" tooltip>
                  {formik.errors.cantidadVehiculos}
                </Form.Control.Feedback>
              </Form.Group>
            </Row> */}
          {/*   <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Tipo de Facturación</Form.Label>
                <Form.Check 
                  type="switch"
                  id="facturacion"
                  label={formik.values.facturacion === false ? "Por flotilla":"Por vehículo"}
                  checked={formik.values.facturacion}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Row> */}
            <Row className="mb-3">
              <Form.Group as={Col} className="d-grid gap-2">
                <Button type="submit" variant="secondary" >
                  Siguiente
                </Button>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="d-grid gap-2">
                <Button
                  variant="link"
                  onClick={() => navigate("/tramite/Alta-Vehicular")}
                >
                  Regresar
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </View>
      </View>
      <ToastContainer closeOnClick />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <h1>Cargando...</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
