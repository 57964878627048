import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;
const ENDPOINT_DOCUMENTOS = process.env.REACT_APP_DOCUMENTOS;
const ENDPOINT_DOCUMENTOS_VEHICULAR = process.env.REACT_APP_VEHICULAR_FILES;

export const FormArchivos = (props) => {
  const { handleBack, info } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [inputKeys, setInputKeys] = useState({
    constanciaSF: null,
    escrituraConstitutiva: null,
    poderRepresentante: null,
    identificacionRepresentante: null,
    cartaPoderTramitador: null,
    identificacionTramitador: null,
  });

  const navigate = useNavigate();
 

  const handleClose = () => {
    navigate("/tramite/Historial-Propietarios");
    setShow(false);
  };

  const { Formik } = formik;

  const EnviarPersonalidad = (values) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(info),
      redirect: "follow",
    };
    fetch(`${ENDPOINT}/personalidad`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.ticket === undefined) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Propietario",
            text: result.message,
          });
        } else {
          handleSubirArchivos(values, result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleSubirArchivos = async (values, id) => {
    console.log("------",id);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/pdf");
    const arrayArchivos = [];
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const file = values[key];

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: file,
          redirect: "follow",
        };
        await fetch(
          /* `${ENDPOINT_DOCUMENTOS}/subir/?documentName=${key}&userId=${info.usuarioID}`, */
          `${ENDPOINT_DOCUMENTOS_VEHICULAR}/${key}${id.ticket.createPersonalidad.id}.pdf`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {

            console.log(result);
            const nuevoValor = { name: key, url: result.s3_url };
            arrayArchivos.push(nuevoValor);
          })
          .catch((error) => console.log("error", error));
      }
    }
    CargarNombreDocumnetos(arrayArchivos, id);
  };

  const CargarNombreDocumnetos = (arrayArchivos, data) => {
    var myHeaders2 = new Headers();
    myHeaders2.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      paths: [
        {
          name: arrayArchivos[0].name,
          url: arrayArchivos[0].url,
        },
        {
          name: arrayArchivos[1].name,
          url: arrayArchivos[1].url,
        },
        {
          name: arrayArchivos[2].name,
          url: arrayArchivos[2].url,
        },
        {
          name: arrayArchivos[3].name,
          url: arrayArchivos[3].url,
        },
        {
          name: arrayArchivos[4].name,
          url: arrayArchivos[4].url,
        },
        {
          name: arrayArchivos[5].name,
          url: arrayArchivos[5].url,
        },
      ],
      dynamoID: data.ticket.createPersonalidad.id,
      ticketType: 15,
    });
   
    var requestO = {
      method: "POST",
      headers: myHeaders2,
      body: raw,
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/attachments`, requestO)
      .then((response) => response.json())
      .then((result) => {
     
        setLoading(false);
        let timerInterval;
        Swal.fire({
            title: "Creación de Propietario",
            html: "Propietario Creado con éxito, serás redirigido al historial de propietarios",
            timer:5000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
                clearInterval(timerInterval);
              }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                navigate("/tramite/Historial-Propietarios")
         }
        });
      })
      .catch((error) => console.log("error", error));
  };

  const schema = yup.object().shape({
    constanciaSF: yup
      .mixed()
      .required("Este campo es obligatorio")
      .test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
      })
      .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
        return value.size <= maxSize;
      }),
    escrituraConstitutiva: yup
      .mixed()
      .required("Este campo es obligatorio")
      .test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
      })
      .test('fileSize', 'El archivo no debe ser mayor a 10 MB', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        const maxSize = 10 * 1024 * 1024; // 4 MB en bytes
        return value.size <= maxSize;
      }),
    poderRepresentante: yup
      .mixed()
      .required("Este campo es obligatorio")
      .test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
      })
      .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
        return value.size <= maxSize;
      }),
    identificacionRepresentante: yup
      .mixed()
      .required("Este campo es obligatorio")
      .test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
      })
      .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
        return value.size <= maxSize;
      }),
    cartaPoderTramitador: yup
      .mixed()
      .required("Este campo es obligatorio")
      .test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
      })
      .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
        return value.size <= maxSize;
      }),
    identificacionTramitador: yup
      .mixed()
      .required("Este campo es obligatorio")
      .test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
      })
      .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
        if (!value) {
          return true; // Permitir si no hay archivo
        }

        const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
        return value.size <= maxSize;
      }),
  });

  const [touchedFields, setTouchedFields] = useState({});

 

  const handleBlur = (fieldName) => {
    setTouchedFields((prevTouched) => ({ ...prevTouched, [fieldName]: true }));
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
          setLoading(true);
          EnviarPersonalidad(values);
        }}
        initialValues={{
          constanciaSF: null,
          escrituraConstitutiva: null,
          poderRepresentante: null,
          identificacionRepresentante: null,
          cartaPoderTramitador: null,
          identificacionTramitador: null,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit} >
            <Row className="mb-3">
              <Form.Group as={Col} className="position-relative ">
                <Form.Label>Constancia de Situación Fiscal*</Form.Label>
                <Form.Control
                  key={inputKeys.constanciaSF}
                  required
                  type="file"
                  name="constanciaSF"
                  accept=".pdf"
                 
                  onInput={() => handleBlur("constanciaSF")}
                  onChange={(event) => {

                    setFieldValue(
                      "constanciaSF",
                      event.currentTarget.files[0]
                    );


                  }}
                  isValid={touched.constanciaSF && !errors.constanciaSF}
                  isInvalid={touchedFields.constanciaSF && !!errors.constanciaSF}
                />
                <Form.Control.Feedback type="invalid" tooltip>

                  {touchedFields.constanciaSF && errors.constanciaSF}
                </Form.Control.Feedback>
                <Form.Text muted>
                  Archivo en formato PDF. Tamaño máximo 4 MB.
                </Form.Text>
              </Form.Group>
              <Form.Group as={Col} className="position-relative">
                <Form.Label>Escritura Constitutiva*</Form.Label>
                <Form.Control
                  key={inputKeys.escrituraConstitutiva}
                  required
                  type="file"
                  accept=".pdf"
                
                  onInput={() => handleBlur("escrituraConstitutiva")}
                  name="escrituraConstitutiva"
                  onChange={(event) => {

                    setFieldValue(
                      "escrituraConstitutiva",
                      event.currentTarget.files[0]
                    );

                  }}
                  isValid={touched.escrituraConstitutiva && !errors.escrituraConstitutiva}
                  isInvalid={touchedFields.escrituraConstitutiva && !!errors.escrituraConstitutiva}
                />
                <Form.Text muted>
                  Archivo en formato PDF. Tamaño máximo 10 MB.
                </Form.Text>
                <Form.Control.Feedback type="invalid" tooltip>
                  {touchedFields.escrituraConstitutiva && errors.escrituraConstitutiva}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="position-relative ">
                <Form.Label>Poder del Representante Legal*</Form.Label>
                <Form.Control
                  key={inputKeys.poderRepresentante}
                  required
                  type="file"
                  name="poderRepresentante"
                  
                  onInput={() => handleBlur("poderRepresentante")}
                  accept=".pdf"
                  onChange={(event) => {

                    setFieldValue(
                      "poderRepresentante",
                      event.currentTarget.files[0]
                    );

                  }}
                  isValid={touched.poderRepresentante && !errors.poderRepresentante}
                  isInvalid={touchedFields.poderRepresentante && !!errors.poderRepresentante}
                />
                <Form.Text muted>
                  Archivo en formato PDF. Tamaño máximo 4 MB.
                </Form.Text>
                <Form.Control.Feedback type="invalid" tooltip>
                {touchedFields.poderRepresentante && errors.poderRepresentante}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} className="position-relative">
                <Form.Label>Identificación del Representante Legal*</Form.Label>
                <Form.Control
                  key={inputKeys.identificacionRepresentante}
                  required
                  type="file"
              
                  onInput={() => handleBlur("identificacionRepresentante")}
                  name="identificacionRepresentante"
                  accept=".pdf"
                  onChange={(event) => {

                    setFieldValue(
                      "identificacionRepresentante",
                      event.currentTarget.files[0]
                    );

                  }}
                  isValid={touched.identificacionRepresentante && !errors.identificacionRepresentante}
                  isInvalid={touchedFields.identificacionRepresentante && !!errors.identificacionRepresentante}
                />
                <Form.Text muted>
                  Archivo en formato PDF. Tamaño máximo 4 MB.
                </Form.Text>
                <Form.Control.Feedback type="invalid" tooltip>
                {touchedFields.identificacionRepresentante && errors.identificacionRepresentante}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="position-relative">
                <Form.Label>Carta Poder Simple del Tramitador*</Form.Label>
                <Form.Control
                  key={inputKeys.cartaPoderTramitador}
                  required
                  type="file"
                  
                  onInput={() => handleBlur("cartaPoderTramitador")}
                  name="cartaPoderTramitador"
                  accept=".pdf"
                  onChange={(event) => {

                    setFieldValue(
                      "cartaPoderTramitador",
                      event.currentTarget.files[0]
                    );

                  }}
                  isValid={touched.cartaPoderTramitador && !errors.cartaPoderTramitador}
                  isInvalid={touchedFields.cartaPoderTramitador && !!errors.cartaPoderTramitador}
                />
                <Form.Text muted>
                  Archivo en formato PDF. Tamaño máximo 4 MB.
                </Form.Text>
                <Form.Control.Feedback type="invalid" tooltip>
                  {touchedFields.cartaPoderTramitador && errors.cartaPoderTramitador}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} className="position-relative">
                <Form.Label>Identificación Oficial del Tramitador*</Form.Label>
                <Form.Control
                  key={inputKeys.identificacionTramitador}
                  required
                  type="file"
                  accept=".pdf"
                 
                  onInput={() => handleBlur("identificacionTramitador")}
                  name="identificacionTramitador"
                  onChange={(event) => {

                    setFieldValue(
                      "identificacionTramitador",
                      event.currentTarget.files[0]
                    );

                  }}
                  isValid={touched.identificacionTramitador && !errors.identificacionTramitador}
                  isInvalid={touchedFields.identificacionTramitador && !!errors.identificacionTramitador}
                />
                <Form.Text muted>
                  Archivo en formato PDF. Tamaño máximo 4 MB.
                </Form.Text>
                <Form.Control.Feedback type="invalid" tooltip>
                  {touchedFields.identificacionTramitador && errors.identificacionTramitador}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="d-grid gap-2">
                <Button type="submit" variant="secondary">
                  SIGUIENTE
                </Button>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} className="d-grid gap-2">
                <Button variant="light" onClick={() => handleBack()}>
                  {" "}
                  Regresar
                </Button>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
      <Modal show={show} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Propietarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>Propietario Creado con exito</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <h1>Generando Expediente Digital </h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
