import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import { View } from '@aws-amplify/ui-react'
import Footer from './Footer'

export default function UserLayout() {
    return (

        <View className="App">
            <Header />
{/*             <Heading level={1}>Bienvenido a Tu Portal</Heading>
 */}            <Outlet />
 
 
            <Footer />
        </View>
    )
}
