import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';


import ls from "@livesession/sdk";

// const REACT_APP_LIVESESSION_ID = process.env.REACT_APP_LIVESESSION_ID
// eslint-disable-next-line no-unused-vars
const REACT_APP_LIVESESSION_ID = "a1aa3fce.4ec80389"

//  ls.init(REACT_APP_LIVESESSION_ID);
// ls.newPageView(); 
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  console.info = function () {};
  console.debug = function () {};
}

Amplify.configure(config);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
          <App />
        
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
