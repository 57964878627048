import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import BuildIcon from '@mui/icons-material/Build';
export const PaginaMantenimiento = () => {
  const navigate = useNavigate();
    return (
    <div className='HeaderTramite' style={{height:"40rem"}}>
        <BuildIcon style={{width:"20rem",height:"20rem"}}/>
    <h1>Página en Mantenimiento</h1>
    <Button variant='secondary' onClick={()=> navigate("/")}>Volver a inicio</Button>
    </div>
  )
}
