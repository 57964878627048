import React, { useState, useEffect } from "react";
import { View } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import InputMask from 'react-input-mask';
import { FormArchivos } from "./FormArchivos";
import { FaArrowLeft } from "react-icons/fa";
const steps = ["Información", "Archivos"];
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;

export const FormAcreditacioPersonalidad2 = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [direcciones, setDirecciones] = useState(null);
  const [activer, setActiver] = useState(false);
  const [info, setInfo] = useState("");
  const navigate = useNavigate();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { user } = useAuthenticator((context) => [context.user]);

  const validationSchema = yup.object().shape({

    clavePromotor: yup.string(), 
    razon_social: yup.string().required("Este campo es obligatorio"),
    rfc: yup
      .string()
      .required("Este Campo es Obligatorio")
      .matches(
        /^([A-Z\x26]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/,
        "RFC inválido"
      ),
    telefono: yup
      .string()
      .required("Este campo es obligatorio")
      .matches(/^[0-9]{10}$/, "Formato no valido"),
    estado: yup.string().required("Este campo es obligatorio"),
    municipio: yup.string().required("Este campo es obligatorio"),
    codigo_postal: yup.string().required("Este campo es obligatorio"),
    localidad: yup.string(),
    colonia: yup.string().required("Este campo es obligatorio"),
    calle: yup.string().required("Este campo es obligatorio"),
    numero_exterior: yup.string().required("Este campo es obligatorio"),
    numero_interior: yup.string(),
  });

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values,{setStatus}) => {
      setInfo(values);
      handleNext();
    },
    initialValues: {
      razon_social: "",
      clavePromotor:"", 
      rfc: "",
      telefono: "",
      estado: "",
      municipio: "",
      codigo_postal: "",
      localidad: "",
      colonia: "",
      calle: "",
      numero_exterior: "",
      numero_interior: "",
      usuarioID: user.username,
    },
  });

  const handleObtenrDomicilio = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    var raw = {
      codigoPostal: data,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/zipcodeInfo`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDirecciones(result);
        formik.setFieldValue("estado", result.estado[0].toUpperCase());
        formik.setFieldValue("municipio", result.municipio[0]);
        formik.setFieldValue("calle", "");
        formik.setFieldValue("numero_exterior", "");
        formik.setFieldValue("numero_interior", "");
        formik.setFieldValue("colonia", "");
        formik.setFieldTouched("colonia", false, true);
        formik.setFieldTouched("calle", false, true);
        formik.setFieldTouched("numero_exterior", false, true);
        if (result.localidad.length === 0) {
          formik.setFieldValue("localidad", "SIN LOCALIDAD");
        } else {
          formik.setFieldValue("localidad", result.localidad[0].toUpperCase());
        }
        if (result.municipio.length === 0) {
          formik.setFieldValue("municipio", "SIN MUNICIPIO");
        } else {
          formik.setFieldValue("municipio", result.municipio[0]);
        }
        if (result.localidad.length === 0 && result.municipio.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "EL código postal no cuenta con municipio ni localidad, contacté a soporte",
            confirmButtonText: "Aceptar",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate("/tramite/Historial-Propietarios")
            }
          });
        }

      })
      .catch((error) => console.log("error", error));
  };
  const ValidarRFC = (value) => {
    const id = toast.loading("Verificando...")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "ticketType": 15,
      "rfc": value,
      "usuarioID": user.username
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${ENDPOINT}/checkRFC`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.rfcExist === true) {
          setActiver(true)
          toast.update(id, { render: result.message, type: "warning", isLoading: false, autoClose: 5000, closeOnClick: true, });

        } else {
          setActiver(false);
          toast.update(id, { render: result.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, });

        }
      })
      .catch(error => console.log('error', error));
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "telefono") {
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length <= 10 && "telefono") {
        // Actualizar el valor del campo usando el método setFieldValue de Formik
        formik.setFieldValue(name, numericValue);
      }
    } else {
      if (value.length <= 12 && name === "rfc") {
        formik.setFieldValue(name, value.toUpperCase());
        if (value.length === 12) {

          ValidarRFC(value);
        }

      }else{
        formik.setFieldValue(name, value.toUpperCase())
      }
    }
  };
  const handleInputChangeCodigo = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    formik.setFieldValue(name, numericValue);
    if (value.length === 5) {
      handleObtenrDomicilio(value);
    }
  };
  const handleInputChangeNumero = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    formik.setFieldValue(name, numericValue);

  };
  return (
    <>
      <div className="HeaderTramite">
        <View className="FormTitle">Emplacamiento Exprés</View>
        <View className="FormSubTitle">Acreditación de Personalidad</View>
        <View className="FormDescripcion"></View>
      </div>
      <Row className="mb-0">
        <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
          <button type="button" onClick={() => navigate("/tramite/Historial-Propietarios")}
            className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
            <FaArrowLeft /> Volver al Inicio
          </button>
        </Form.Group>
      </Row>
      <View className="ViewPrincipal">
        <View className="FormContent">
          <Box sx={{ width: "100%" }} className="ViewActa">
            <Stepper activeStep={activeStep} className="mb-3">
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <></>
            ) : (
              <React.Fragment>
                {activeStep === 0 ? (
                  <Box>
                    <Form
                      onSubmit={formik.handleSubmit}
                      noValidate
                      
                    >
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label>
                            Datos personales de Persona Moral
                          </Form.Label>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Razón Social*</Form.Label>
                          <Form.Control
                            type="text"
                            name="razon_social"
                            disabled={activer}
                            value={formik.values.razon_social}
                            onChange={handleInputChange}
                            isValid={
                              formik.touched.razon_social &&
                              !formik.errors.razon_social
                            }
                            isInvalid={
                              formik.touched.razon_social &&
                              !!formik.errors.razon_social
                            }
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.razon_social}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>RFC con Homoclave*</Form.Label>
                          <Form.Control
                            type="text"
                            name="rfc"
                            maxLength={12}
                            value={formik.values.rfc}
                            onChange={handleInputChange}
                            isValid={formik.touched.rfc && !formik.errors.rfc}
                            isInvalid={
                              formik.touched.rfc && !!formik.errors.rfc
                            }
                          />

                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.rfc}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Teléfono*</Form.Label>
                          <InputMask
                            mask="(999) 999-9999"
                            maskChar="_"
                            value={formik.values.telefono}
                            onChange={handleInputChange}
                            
                             disabled={activer}
                          >
                            {(inputProps) => (
                              <Form.Control
                                type="text"
                                name="telefono"
                                {...inputProps}
                                isValid={formik.touched.telefono && !formik.errors.telefono}
                                isInvalid={formik.touched.telefono && !!formik.errors.telefono}
                              />
                            )}
                          </InputMask>

                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.telefono}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Clave del Promotor(Opcional)</Form.Label>
                          <Form.Control
                            type="text"
                            name="clavePromotor"
                            disabled={activer}
                            value={formik.values.clavePromotor}
                            onChange={handleInputChange}
                            isValid={
                              formik.touched.clavePromotor &&
                              !formik.errors.clavePromotor
                            }
                            isInvalid={
                              formik.touched.clavePromotor &&
                              !!formik.errors.clavePromotor
                            }
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.clavePromotor}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label>
                            Datos de domicilio de Persona Moral
                          </Form.Label>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Código Postal*</Form.Label>
                          <Form.Control
                            type="text"
                            name="codigo_postal"
                            maxLength="5"
                            disabled={activer}
                            value={formik.values.codigo_postal}
                            /*  onBlur={() =>
                               handleObtenrDomicilio(formik.values.codigo_postal)
                             }  */
                            onChange={handleInputChangeCodigo}
                            isValid={
                              formik.touched.codigo_postal &&
                              !formik.errors.codigo_postal
                            }
                            isInvalid={
                              formik.touched.codigo_postal &&
                              !!formik.errors.codigo_postal
                            }
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.codigo_postal}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Estado*</Form.Label>
                          <Form.Control
                            type="text"
                            name="estado"
                            readOnly
                            disabled
                            value={formik.values.estado.toUpperCase()}
                            onChange={handleInputChange}
                            isValid={
                              formik.touched.estado && !formik.errors.estado
                            }
                            isInvalid={
                              formik.touched.estado && !!formik.errors.estado
                            }
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.estado}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Municipio*</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="municipio"
                            value={formik.values.municipio.toUpperCase()}
                            onChange={handleInputChange}
                            isValid={
                              formik.touched.municipio &&
                              !formik.errors.municipio
                            }
                            isInvalid={
                              formik.touched.municipio &&
                              !!formik.errors.municipio
                            }
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.municipio}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Localidad*</Form.Label>
                          <Form.Control
                            type="text"
                            name="localidad"
                            disabled
                            value={formik.values.localidad.toUpperCase()}
                            onChange={handleInputChange}
                            isValid={
                              formik.touched.localidad &&
                              !formik.errors.localidad
                            }
                            isInvalid={
                              formik.touched.localidad &&
                              !!formik.errors.localidad
                            }
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.localidad}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Colonia*</Form.Label>
                          <Form.Select
                            type="text"
                            name="colonia"
                            value={formik.values.colonia}
                            onChange={formik.handleChange}
                            disabled={activer}
                            isValid={
                              formik.touched.colonia && !formik.errors.colonia
                            }
                            isInvalid={
                              formik.touched.colonia && !!formik.errors.colonia
                            }
                          >
                            <option value="">Seleccione</option>
                            {direcciones !== null &&
                              direcciones.colonias.map((data, index) => (
                                <option key={index} value={data.toUpperCase()}>
                                  {data.toUpperCase()}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.colonia}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Calle*</Form.Label>
                          <Form.Control
                            type="text"
                            name="calle"
                            disabled={activer}
                            value={formik.values.calle}
                            onChange={handleInputChange}
                            isValid={
                              formik.touched.calle && !formik.errors.calle
                            }
                            isInvalid={
                              formik.touched.calle && !!formik.errors.calle
                            }
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.calle}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Número Exterior*</Form.Label>
                          <Form.Control
                            disabled={activer}
                            type="text"
                            name="numero_exterior"
                            value={formik.values.numero_exterior}
                            onChange={handleInputChangeNumero}
                            isValid={
                              formik.touched.numero_exterior &&
                              !formik.errors.numero_exterior
                            }
                            isInvalid={
                              formik.touched.numero_exterior &&
                              !!formik.errors.numero_exterior
                            }
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.numero_exterior}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Número Interior</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={activer}
                            name="numero_interior"
                            value={formik.values.numero_interior}
                            onChange={handleInputChangeNumero}
                            isValid={
                              formik.touched.numero_interior &&
                              !formik.errors.numero_interior
                            }
                            isInvalid={
                              formik.touched.numero_interior &&
                              !!formik.errors.numero_interior
                            }
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.numero_interior}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col} className="d-grid gap-2">
                          <Button type="submit" variant="secondary">
                            SIGUIENTE
                          </Button>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="d-grid gap-2">
                          <Button
                            variant="link"
                            onClick={() =>
                              navigate("/tramite/Historial-Propietarios")
                            }
                          >
                            Regresar
                          </Button>
                        </Form.Group>
                      </Row>
                    </Form>
                  </Box>
                ) : (
                  <FormArchivos handleBack={handleBack} info={info} />
                )}
              </React.Fragment>
            )}
          </Box>
        </View>
      </View>


      <ToastContainer closeOnClick />
    </>
  );
};
