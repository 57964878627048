import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import Row from 'react-bootstrap/Row';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button as ButtonB } from 'react-bootstrap';
import { useTramiteDataGeneral } from '../../TramiteContext';

export const FormArrendamiento2 = ({ info, handleBack, steps, handleNext, activeStep,CalcularArrendamiento,setInfoCal }) => {
    const { data } = useTramiteDataGeneral()

    

    function getCurrentDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with leading zero
        const year = today.getFullYear(); // Get the full year (4 digits)

        return `${year}-${month}-${day}`;
    }
    const [days, setDays] = useState(0);


    const obtenerDias = (fechaEscritura) => {
        const date = getCurrentDate();
        var fecha1 = new Date(date);
        var fecha2 = new Date(fechaEscritura);
        var diferenciaEnMilisegundos = fecha1 - fecha2;

        // Convertir la diferencia de milisegundos a días
        var diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);

        setDays(diferenciaEnDias)
    }
    useEffect(() => {
        obtenerDias(info.fechaFirma)
    }, [])
 

    const validationSchema = yup.object().shape({
        ingresosEnajenacion: yup.number().required("Este Campo es Obligatorio"),
        deducciones: yup.number().required("Este Campo es Obligatorio"),
        aniosAdquisicionVenta: yup.number().required("Este Campo es Obligatorio").max(20,"Lo años no pueden ser mayor a 20"),
        actualizacion: yup.number(),
        recargos: yup.number(),
        multaCorreccionAnterior: yup.number(),
        montoPagadoAnterior: yup.number(),
        actualizacionFederacion: yup.number(),
        recargosFederacion: yup.number(),

    });
    const formik = useFormik({
        validationSchema,
        enableReinitialize:true,
        onSubmit: values => {
            handleNext()
            CalcularArrendamiento(true);
        },
        initialValues: {
            ingresosEnajenacion: data !== null ? data.ingresosEnajenacion : "",
            deducciones: data !== null ?data.deducciones:"",
            aniosAdquisicionVenta: data !== null ?data.aniosAdquisicionVenta:"",
            actualizacion: days<=15 ? "":data !== null ?data.actualizacion:"",
            recargos: days<=15 ? "":data !== null ?data.recargos:"",
            multaCorreccionAnterior: info.declaracion ===2 ?'':data !== null ?data.multaCorreccionAnterior:"",
            montoPagadoAnterior: info.declaracion ===2 ?'':data !== null ?data.montoPagadoAnterior:"",
            actualizacionFederacion: days<=15 ? "":data !== null ?data.actualizacionFederacion:"",
            recargosFederacion: days<=15 ? "":data !== null ?data.recargosFederacion:""
        }

    });
  
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, "");
        formik.setFieldValue(name, numericValue);
    };
   
    const handleInputChangeCodigo = (e) => {
        const { name, value } = e.target;
        // Elimina todo excepto números y un punto decimal
        let numericValue = value.replace(/[^\d.]/g, '');
        // Asegura que solo haya un punto decimal
        const decimalIndex = numericValue.indexOf('.');
        if (decimalIndex !== -1) {
            numericValue = numericValue.slice(0, decimalIndex + 3); // Limita los decimales a dos
        }
        // Actualiza el valor en el estado del formulario
        formik.setFieldValue(name, numericValue);
    };
    useEffect(()=>{
        setInfoCal(formik.values)
        },[formik.values])
    return (
        <>
            <Box>
                <Col>
                    <Row className="mb-3 mt-3">
                        <Form.Group as={Col} className="position-relative">
                            <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                                Datos de Declaración
                            </p>
                            <Form.Label>Ingresos por Enajenación*</Form.Label>
                            <Form.Control
                                type="text"
                                name="ingresosEnajenacion"
                                onBlur={()=>{

                                    if(formik.values.deducciones !== "" && formik.values.aniosAdquisicionVenta !== "" && formik.values.ingresosEnajenacion!==""){
                                        
                                        CalcularArrendamiento()
                                    }
                                }

                                
                                }
                                placeholder='Ingresos por Enajenación'
                                value={formik.values.ingresosEnajenacion}
                                onChange={handleInputChangeCodigo}
                                isValid={formik.touched.ingresosEnajenacion && !formik.errors.ingresosEnajenacion}
                                isInvalid={!!formik.errors.ingresosEnajenacion && formik.touched.ingresosEnajenacion}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>{formik.errors.ingresosEnajenacion}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group className='position-relative'>
                            <Form.Label>Deducciones*</Form.Label>
                            <Form.Control
                                type="text"
                                onBlur={()=>{

                                    if(formik.values.deducciones !== "" && formik.values.aniosAdquisicionVenta !== "" && formik.values.ingresosEnajenacion!==""){
                                        
                                        CalcularArrendamiento()
                                    }
                                }

                                
                                }
                                name="deducciones"
                                placeholder='Deducciones'
                                value={formik.values.deducciones}
                                onChange={handleInputChangeCodigo}
                                isValid={formik.touched.deducciones && !formik.errors.deducciones}
                                isInvalid={!!formik.errors.deducciones && formik.touched.deducciones}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>{formik.errors.deducciones}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group className='position-relative'>
                            <Form.Label>Años entre la adquisición y venta*</Form.Label>
                            <Form.Control
                                type="text"
                                name="aniosAdquisicionVenta"
                                placeholder='Años entre la adquisición y venta'
                                value={formik.values.aniosAdquisicionVenta}
                                onBlur={()=>{

                                    if(formik.values.deducciones !== "" && formik.values.aniosAdquisicionVenta !== "" && formik.values.ingresosEnajenacion!==""){
                                        
                                        CalcularArrendamiento()
                                    }
                                }

                                
                                }
                                onChange={handleInputChange}
                                isValid={formik.touched.aniosAdquisicionVenta && !formik.errors.aniosAdquisicionVenta}
                                isInvalid={ formik.touched.aniosAdquisicionVenta&& !!formik.errors.aniosAdquisicionVenta}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>{formik.errors.aniosAdquisicionVenta}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    {days >= 15 &&
                        <>

                            <Row className='mb-3'>
                                <Form.Group as={Col} className="position-relative">
                                    <Form.Label>Actualización</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="actualizacion"
                                        placeholder='Actualización'
                                        value={formik.values.actualizacion}
                                        onBlur={()=>CalcularArrendamiento()}
                                        onChange={handleInputChangeCodigo}
                                        isValid={formik.touched.actualizacion && !formik.errors.actualizacion}
                                        isInvalid={!!formik.errors.actualizacion && formik.touched.actualizacion}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors.actualizacion}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} className="position-relative">
                                    <Form.Label>Recargos</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="recargos"
                                        placeholder='Recargos'
                                        value={formik.values.recargos}
                                        onChange={handleInputChangeCodigo}
                                        onBlur={()=>CalcularArrendamiento()}
                                        isValid={formik.touched.recargos && !formik.errors.recargos}
                                        isInvalid={!!formik.errors.recargos && formik.touched.recargos}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors.recargos}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group className='position-relative'>
                                    <Form.Label>Actualización ISR Federación</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="actualizacionFederacion"
                                        placeholder='Actualización ISR Federación'
                                        value={formik.values.actualizacionFederacion}
                                        onChange={handleInputChangeCodigo}
                                        onBlur={()=>CalcularArrendamiento()}
                                        isValid={formik.touched.actualizacionFederacion && !formik.errors.actualizacionFederacion}
                                        isInvalid={!!formik.errors.actualizacionFederacion && formik.touched.actualizacionFederacion}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors.actualizacionFederacion}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group className='position-relative'>
                                    <Form.Label>Recargos del ISR Federación</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="recargosFederacion"
                                        placeholder='Recargos del ISR Federación'
                                        value={formik.values.recargosFederacion}
                                        onChange={handleInputChangeCodigo}
                                        onBlur={()=>CalcularArrendamiento()}
                                        isValid={formik.touched.recargosFederacion && !formik.errors.recargosFederacion}
                                        isInvalid={!!formik.errors.recargosFederacion && formik.touched.recargosFederacion}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors.recargosFederacion}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </>
                    }
                    {info.declaracion === "2" &&

                        <>
                            <>
                                        {info.correccionFiscal === true &&
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Multa por Corrección</Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name='multaCorreccionAnterior'
                                                        placeholder='Multa por Corrección'
                                                        value={formik.values.multaCorreccionAnterior}
                                                        onChange={handleInputChangeCodigo}
                                                        onBlur={()=>CalcularArrendamiento()}
                                                        isValid={formik.touched.multaCorreccionAnterior && !formik.errors.multaCorreccionAnterior}
                                                        isInvalid={formik.touched.multaCorreccionAnterior && !!formik.errors.multaCorreccionAnterior}
                                                    />
                                                    <Form.Control.Feedback type='invalid' tooltip>{formik.errors.multaCorreccionAnterior}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                        }
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Monto pagado con Anterioridad</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='montoPagadoAnterior'
                                            placeholder='Monto pagado con Anterioridad'
                                            value={formik.values.montoPagadoAnterior}
                                            onBlur={()=>CalcularArrendamiento()}
                                            onChange={handleInputChangeCodigo}
                                            isValid={formik.touched.montoPagadoAnterior && !formik.errors.montoPagadoAnterior}
                                            isInvalid={formik.touched.montoPagadoAnterior && !!formik.errors.montoPagadoAnterior}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{formik.errors.montoPagadoAnterior}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </>

                        </>
                    }
                </Col>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <ButtonB
                    variant="secondary"
                    disabled={activeStep === 0}
                    onClick={()=>{handleBack()
                    
                    }}
                >
                    Anterior
                </ButtonB>
                <Box sx={{ flex: "1 1 auto" }} />

                {activeStep === steps.length - 1 && (
                    <div className="mr-2 bg-white" style={{ height: "58px", borderRadius: "10px", display: "flex", justifyContent: "center", }}>
                        <ButtonB
                            variant="secondary"
                           /*  onClick={() => { GenerarLigaPago("presupuesto"); }} */>Descargar Presupuesto
                        </ButtonB>
                        {/*<FormControlLabel value="pago3" control={<Radio />}  style={{ width: "20px", heidth: "20px" }} />*/}
                    </div>
                )}
                <ButtonB onClick={() => {
                    /*   if (activeStep === steps.length - 1) {
                        GenerarLigaPago();
                        return;
                      } */
                    formik.handleSubmit()
                }} variant="secondary">
                    {activeStep === steps.length - 1 ? 'Continuar al pago' : 'Siguiente'}
                </ButtonB>
            </Box>
        </>
    )
}
