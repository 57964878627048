import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const ModalActions = ({ title, content, tipo, buttonText = 'Aceptar', cancelText = 'Cancelar', buttonAction, icon, show, color = '#51545b', onHide, desc, buttonActionCancel = null }) => {
    const showIcon = () => {
        switch (icon) {
            case 'info':
                return <InfoOutlinedIcon className='scale-[10]' sx={{ color: { color } }} />
            case 'check':
                return <CheckCircleOutlineOutlinedIcon className='scale-[10]' sx={{ color: { color } }} />
            case 'error':
                return <ErrorOutlineOutlinedIcon className='scale-[10]' sx={{ color: { color } }} />
            default:
                break;
        }
    }
    return (
        <>
            <Modal show={show} size='lg' onHide={onHide}>
                <Modal.Header>
                    <Modal.Title className='w-full text-center'>
                        <div className='w-full text-center font-bold text-4xl'>{title}</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-full h-[200px] flex justify-center items-center'>{showIcon()}</div>
                    <div className='w-full justify-center text-center my-8'>{content}</div>
                    <div className='w-full justify-center text-center my-8'>{desc}</div>

                </Modal.Body>
                <Modal.Footer >
                    {tipo === "descuento" ?
                        <div style={{ display: "flex", justifyContent: "space-between" , width:"100%"}}>
                            <Button onClick={buttonAction} variant='secondary'>Aceptar</Button>
                           {/*  <Button variant='secondary'>Descargar Referencia de Pago</Button> */}
                            <Button onClick={buttonActionCancel} variant='secondary'>  Cancelar</Button>
                        </div>
                        :
                        <>
                            <div className='w-full flex justify-center'>
                                <button
                                    onClick={buttonAction}
                                    className='min-w-[350px] h-[50px] bg-[#607471] text-white font-bold rounded-full'
                                >
                                    {buttonText}
                                </button>
                            </div>
                            {buttonActionCancel &&
                                <div className='w-full flex justify-center'>
                                    <button
                                        onClick={buttonActionCancel}
                                        className='min-w-[350px] h-[50px] bg-[#607471] text-white font-bold rounded-full'
                                    >
                                        {cancelText}
                                    </button>
                                </div>
                            }
                        </>
                    }

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalActions;