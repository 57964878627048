
import React,{useEffect} from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const FreshServiceTicket = () => {
    const { user } = useAuthenticator((context) => [context.user]);
    
     var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", process.env.REACT_APP_TOKEN_VERIFICATION);
      

    useEffect(() => {
        var raw = JSON.stringify({
            "description": "Detalles del ticket",
            "subject": "Se require soporte",
            "email": user.attributes.email,
            "priority": 1,
            "status": 2,
            "cc_emails": [
                "portaldepagos@g-tech.io"
            ]
        });
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };    
        fetch(`${process.env.REACT_APP_URL_FRESHSERVICE}/tickets`, requestOptions)
            .then(response => response.text())
            .then(result =>{
                const DataResult = JSON.parse(result);
                const url =`${process.env.REACT_APP_URL_FRESHSERVICE_TICKETS}/support/tickets/`+DataResult.ticket.id;
                window.location.href = url;
            }
             ) 
            .catch(error => console.log('error', error));
    
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 
    
   
    return (
        <div>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <div>

        <CircularProgress color="inherit" />
        <h1> Redirigiendo</h1>
        </div>
      </Backdrop>
        </div>
    );
};

export default FreshServiceTicket;