import React from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
export const FormDatosEntrega = (props) => {
    const navigate = useNavigate();
    const { datos,setChangeData, changeData}= props;
    const schema = yup.object().shape({
        tipoLicencia: yup.string(),
        tipoTramite: yup.string(),
        nombreDestinatario: yup.string().required("Este valor es Obligatorio"),
        nombre: yup.string().required("Este valor es Obligatorio"),
        primerApellido: yup.string(),
        segundoApellido: yup.string().required("Este Campo es Obligatorio"),
        telefono: yup.string().required("Este Campo es Obligatorio"),
    });
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "telefono") {
            values = values.replace(/[^0-9]/g, '');

            setFieldValue(name, values);
        } else {

            values = values.replace(/[^A-Z\s]/g, '');

            setFieldValue(name, values);
        }
    };
    return (
        <>
            <View className="">
                <View className="">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            tipoTramite: datos.type,
                            tipoLicencia: datos.procedure,
                            nombreDestinatario: datos.datosEntrega !== null ? datos.datosEntrega.nombreDestinatario : "",
                            nombre: datos.datosEntrega !== null ? datos.datosEntrega.nombre : "",
                            primerApellido: datos.datosEntrega !== null ? datos.datosEntrega.primerApellido : "",
                            segundoApellido: datos.datosEntrega !== null ? datos.datosEntrega.segundoApellido : "",
                            telefono: datos.datosEntrega !== null ? datos.datosEntrega.telefono : "",
                           
                        }}
                        onSubmit={ async values => { 
                            console.log(values);
                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": "nueva",
                                "usuarioID": user.username,
                                "datosEntrega": {
                                    "nombreDestinatario": values.nombreDestinatario,
                                    "nombre": values.nombre,
                                    "primerApellido": values.primerApellido,
                                    "segundoApellido": values.segundoApellido,
                                    "telefono": values.telefono
                                },
                            }
                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setChangeData(!changeData)
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                            }).catch(error => {
                                console.log(error)
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>
                                   <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Licencia</Form.Label>
                                        <Form.Select
                                            name='tipoLicencia'
                                            type='text'
                                            value={values.tipoLicencia}
                                            onChange={handleChange}
                                            disabled
                                            isValid={touched.tipoLicencia && !errors.tipoLicencia}
                                            isInvalid={touched.tipoLicencia && !!errors.tipoLicencia}
                                        >
                                             <OpcionesSelect id={"LICENCIAS"} valor={"categoria"}/>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoLicencia}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Tramite</Form.Label>
                                        <Form.Control
                                            name='tipoTramite'
                                            type='text'
                                            value={values.tipoTramite.toUpperCase()}
                                            onChange={handleChange}
                                            disabled
                                            isValid={touched.tipoTramite && !errors.tipoTramite}
                                            isInvalid={touched.tipoTramite && !!errors.tipoTramite}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoTramite}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                <Form.Label style={{textDecoration:"underline"}}>Datos de Entrega</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Nombre del Destinatario*</Form.Label>
                                        <Form.Control
                                            name='nombreDestinatario'
                                            type='text'
                                            value={values.nombreDestinatario}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.datosEntrega !== null ? true : false}
                                            isValid={touched.nombreDestinatario && !errors.nombreDestinatario}
                                            isInvalid={touched.nombreDestinatario && !!errors.nombreDestinatario}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.nombreDestinatario}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                               
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Nombre (s)*</Form.Label>

                                        <Form.Control
                                            name='nombre'
                                            type='text'
                                            placeholder='Nombre (s)'
                                            value={values.nombre}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.datosEntrega !== null ? true : false}
                                            isValid={touched.nombre && !errors.nombre}
                                            isInvalid={touched.nombre && !!errors.nombre}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.nombre}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Primer Apellido*</Form.Label>
                                        <Form.Control
                                            name='primerApellido'
                                            type='text'
                                            placeholder='Primer Apellido'
                                            value={values.primerApellido}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.datosEntrega !== null ? true : false}
                                            isValid={touched.primerApellido && !errors.primerApellido}
                                            isInvalid={touched.primerApellido && !!errors.primerApellido}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.primerApellido}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Segundo Apellido</Form.Label>
                                        <Form.Control
                                            name='segundoApellido'
                                            type='text'
                                            placeholder='Segundo Apellido'
                                            value={values.segundoApellido}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.datosEntrega !== null ? true : false}
                                            isValid={touched.segundoApellido && !errors.segundoApellido}
                                            isInvalid={touched.segundoApellido && !!errors.segundoApellido}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.segundoApellido}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>           
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Telefono de Contacto*</Form.Label>

                                        <Form.Control
                                            name='telefono'
                                            type='text'
                                            placeholder='Telefono'
                                            value={values.telefono}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.datosEntrega !== null ? true : false}
                                            isValid={touched.telefono && !errors.telefono}
                                            isInvalid={touched.telefono && !!errors.telefono}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.telefono}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                             
                              
                                <Row className='mb-3'>
                                    <Button variant='secondary' disabled={datos.datosEntrega !== null ? true : false} type='submit'>GUARDAR</Button>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='link'>REGRESAR</Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer/>
        </>
    )
}
