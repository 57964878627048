import React from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
export const FormDatosEnvio = (props) => {
    const navigate = useNavigate();
    const { datos,setChangeData, changeData}= props;
    const schema = yup.object().shape({
        tipoLicencia: yup.string(),
        tipoTramite: yup.string(),
        pais: yup.string().required("Este valor es Obligatorio"),
        estado: yup.string(),
        municipio: yup.string(),
        localidad: yup.string().required("Este Campo es Obligatorio"),
        entreCalle1: yup.string().required("Este Campo es Obligatorio"),
        entreCalle2: yup.string().required("Este Campo es Obligatorio"),
        colonia: yup.string().required("Este Campo es Obligatorio"),
        datoReferencia: yup.string().required("Este Campo es Obligatorio"),
        indicacionesEntrega: yup.string().required("Este Campo es Obligatorio"),
        calle: yup.string().required("Este valor es Obligatorio"),
        numExterior: yup.string().required("Este Campo es Obligatorio"),
        numInterior: yup.string(),
        letra: yup.string(),
        codigoPostal: yup.string().required("Este valor es Obligatorio"),
        residencia: yup.string().required("Este valor es Obligatorio"),
    });

    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "numExterior" || name === "numInterior" || name === "codigoPostal") {
            values = values.replace(/[^0-9]/g, '');

            setFieldValue(name, values);
        } else {

            values = values.replace(/[^A-Z0-9\s]/g, '');

            setFieldValue(name, values);
        }
    };
    return (
        <>
            <View className="">
                <View className="">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            tipoTramite: datos.type,
                            tipoLicencia: datos.procedure,
                            residencia: "",
                            pais: datos.direccionEnvio !== null ? datos.direccionEnvio.pais : "",
                            estado: datos.direccionEnvio !== null ? datos.direccionEnvio.estado : "",
                            municipio: datos.direccionEnvio !== null ? datos.direccionEnvio.municipio : "",
                            localidad: datos.direccionEnvio !== null ? datos.direccionEnvio.localidad : "",
                            colonia: datos.direccionEnvio !== null ? datos.direccionEnvio.colonia : "",
                            entreCalle1: datos.direccionEnvio !== null ? datos.direccionEnvio.entreCalle1 : "",
                            entreCalle2: datos.direccionEnvio !== null ? datos.direccionEnvio.entreCalle2 : "",
                            calle: datos.direccionEnvio !== null ? datos.direccionEnvio.calle : "",
                            numExterior: datos.direccionEnvio !== null ? datos.direccionEnvio.numeroExterior : "",
                            numInterior: datos.direccionEnvio !== null ? datos.direccionEnvio.numeroIterior : "",
                            letra: datos.direccionEnvio !== null ? datos.direccionEnvio.letra : "",
                            codigoPostal: datos.direccionEnvio !== null ? datos.direccionEnvio.codigoPostal : "",
                            datoReferencia: datos.direccionEnvio !== null ? datos.direccionEnvio.datoReferencia : "",
                            indicacionesEntrega: datos.direccionEnvio !== null ? datos.direccionEnvio.indicacionesEntrega : ""
                        }}
                        onSubmit={async values => {
                            console.log(values);
                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": "nueva",
                                "usuarioID": user.username,
                                "direccionEnvio": {
                                    "pais": values.pais,
                                    "estado": values.estado,
                                    "codigoPostal": values.codigoPostal,
                                    "municipio": values.municipio,
                                    "localidad": values.localidad,
                                    "colonia": values.colonia,
                                    "calle": values.calle,
                                    "numeroExterior": values.numExterior,
                                    "numeroIterior": values.numInterior,
                                    "entreCalle1": values.entreCalle1,
                                    "entreCalle2": values.entreCalle2,
                                    "datosReferencia": values.datoReferencia,
                                    "indicacionesEntrega": values.indicacionesEntrega
                                },
                            }
                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setChangeData(!changeData)
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                            }).catch(error => {
                                console.log(error)
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors , setFieldValue, setFieldTouched}) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>
                                 <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Licencia</Form.Label>
                                        <Form.Select
                                            name='tipoLicencia'
                                            type='text'
                                            value={values.tipoLicencia}
                                            onChange={handleChange}
                                            disabled
                                            isValid={touched.tipoLicencia && !errors.tipoLicencia}
                                            isInvalid={touched.tipoLicencia && !!errors.tipoLicencia}
                                        >
                                             <OpcionesSelect id={"LICENCIAS"} valor={"categoria"}/>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoLicencia}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Tramite</Form.Label>
                                        <Form.Control
                                            name='tipoTramite'
                                            type='text'
                                            value={values.tipoTramite.toUpperCase()}
                                            onChange={handleChange}
                                            disabled
                                            isValid={touched.tipoTramite && !errors.tipoTramite}
                                            isInvalid={touched.tipoTramite && !!errors.tipoTramite}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoTramite}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Label style={{ textDecoration: "underline" }}>Domicilio de Residencia</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Residencia*</Form.Label>
                                        <Form.Select
                                            name='residencia'
                                            value={values.residencia}
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            onChange={(e) => {
                                                handleChange(e);
                                                const selectedResidencia = e.target.value;
                                                if (selectedResidencia !== values.residencia) {
                                                    setFieldValue('pais', '');
                                                    setFieldValue('estado', '');
                                                }
                                            }}
                                            isValid={touched.residencia && !errors.residencia}
                                            isInvalid={touched.residencia && !!errors.residencia}
                                        >
                                            <option value="">SELECCIONA</option>
                                            <option value="1">NACIONAL</option>
                                            <option value="2">EXTRANJERO</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>
                                            {errors.residencia}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                {values.residencia === "1" ?
                                    <>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Pais*</Form.Label>
                                                <Form.Select
                                                    name='pais'
                                                    type='text'
                                                    value={values.pais}
                                                    onChange={handleChange}
                                                    disabled={datos.direccionEnvio !== null ? true : false}
                                                    isValid={touched.pais && !errors.pais}
                                                    isInvalid={touched.pais && !!errors.pais}
                                                >
                                                    <option value="">SELECCIONA</option>
                                                    <option value="mexico">MÉXICO</option>

                                                </Form.Select>
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.pais}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Estado*</Form.Label>
                                                <Form.Select
                                                    name='estado'
                                                    type='text'
                                                    value={values.estado}
                                                    onChange={handleChange}
                                                    disabled={datos.direccionEnvio !== null ? true : false}
                                                    isValid={touched.estado && !errors.estado}
                                                    isInvalid={touched.estado && !!errors.estado}
                                                >

                                                    {datos.direccionEnvio === null ?

                                                        <option value="">SELECCIONA</option>
                                                        : null}
                                                    <OpcionesSelect id={19} />

                                                </Form.Select>
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.estado}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                    </>
                                    : values.residencia === "2" &&
                                    <>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Pais*</Form.Label>
                                                <Form.Control
                                                    name='pais'
                                                    type='text'
                                                    placeholder='PAIS'
                                                    value={values.pais}
                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                    disabled={datos.direccionEnvio !== null ? true : false}
                                                    isValid={touched.pais && !errors.pais}
                                                    isInvalid={touched.pais && !!errors.pais}
                                                />
                                                

                                
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.pais}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Form.Group as={Col} className='position-relative'>
                                                <Form.Label>Estado*</Form.Label>
                                                <Form.Control
                                                    name='estado'
                                                    type='text'
                                                    placeholder='ESTADO'
                                                    value={values.estado}
                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                    disabled={datos.direccionEnvio !== null ? true : false}
                                                    isValid={touched.estado && !errors.estado}
                                                    isInvalid={touched.estado && !!errors.estado}
                                                />

                                                    

                                            
                                                <Form.Control.Feedback type='invalid' tooltip>{errors.estado}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                    </>
                                }
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Código Postal*</Form.Label>

                                        <Form.Control
                                            name='codigoPostal'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Código Postal'
                                            value={values.codigoPostal}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.codigoPostal && !errors.codigoPostal}
                                            isInvalid={touched.codigoPostal && !!errors.codigoPostal}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.codigoPostal}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Municipio*</Form.Label>
                                        <Form.Control
                                            name='municipio'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Municipio'
                                            value={values.municipio}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.municipio && !errors.municipio}
                                            isInvalid={touched.municipio && !!errors.municipio}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.municipio}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Localidad*</Form.Label>
                                        <Form.Control
                                            name='localidad'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Localidad'
                                            value={values.localidad}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.localidad && !errors.localidad}
                                            isInvalid={touched.localidad && !!errors.localidad}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.localidad}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Colonia*</Form.Label>
                                        <Form.Control
                                            name='colonia'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Colonia'
                                            value={values.colonia}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.colonia && !errors.colonia}
                                            isInvalid={touched.colonia && !!errors.colonia}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.colonia}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Calle*</Form.Label>

                                        <Form.Control
                                            name='calle'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Calle'
                                            value={values.calle}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.calle && !errors.calle}
                                            isInvalid={touched.calle && !!errors.calle}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.calle}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Número Exterior*</Form.Label>

                                        <Form.Control
                                            name='numExterior'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Numero Exterior'
                                            value={values.numExterior}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.numExterior && !errors.numExterior}
                                            isInvalid={touched.numExterior && !!errors.numExterior}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.numExterior}</Form.Control.Feedback>


                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Número Interior</Form.Label>

                                        <Form.Control
                                            name='numInterior'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Numero Interior'
                                            value={values.numInterior}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.numInterior && !errors.numInterior}
                                            isInvalid={touched.numInterior && !!errors.numInterior}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.numInterior}</Form.Control.Feedback>


                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Letra</Form.Label>

                                        <Form.Control
                                            name='letra'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Letra'
                                            value={values.letra}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.letra && !errors.letra}
                                            isInvalid={touched.letra && !!errors.letra}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.letra}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Entre Calle 1</Form.Label>
                                        <Form.Control
                                            name='entreCalle1'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Entre Calle 1'
                                            value={values.entreCalle1}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.entreCalle1 && !errors.entreCalle1}
                                            isInvalid={touched.entreCalle1 && !!errors.entreCalle1}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.entreCalle1}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Entre Calle 2</Form.Label>
                                        <Form.Control
                                            name='entreCalle2'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Entre Calle 2'
                                            value={values.entreCalle2}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.entreCalle2 && !errors.entreCalle2}
                                            isInvalid={touched.entreCalle2 && !!errors.entreCalle2}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.entreCalle2}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Datos de Referencia</Form.Label>
                                        <Form.Control
                                            name='datoReferencia'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Datos de Referencia'
                                            value={values.datoReferencia}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.datoReferencia && !errors.datoReferencia}
                                            isInvalid={touched.datoReferencia && !!errors.datoReferencia}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.datoReferencia}</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Indicaciones de Entrega</Form.Label>
                                        <Form.Control
                                            name='indicacionesEntrega'
                                            type='text'
                                            disabled={datos.direccionEnvio !== null ? true : false}
                                            placeholder='Indicaciones de Entrega'
                                            value={values.indicacionesEntrega}
                                            onChange={(event) => handleInputChange(event, setFieldValue, setFieldTouched)}
                                            isValid={touched.indicacionesEntrega && !errors.indicacionesEntrega}
                                            isInvalid={touched.indicacionesEntrega && !!errors.indicacionesEntrega}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.indicacionesEntrega}</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>

                                <Row className='mb-3'>
                                    <Button variant='secondary' disabled={datos.direccionEnvio !== null ? true : false} type='submit'>GUARDAR</Button>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='link'>REGRESAR</Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
        </>
    )
}
