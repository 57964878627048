import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { View } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
// import { Clipboard, Ripple, initTE } from "tw-elements";
import Select from "react-select";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import FormPagos from "../FormPagos";
const steps = ["Información"];
const ENDPOINT_MARCAS = process.env.REACT_APP_MARCAS;

export const FormGenerador = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [metodoPago, setMetodoPago] = useState("pago1");
  const [marcaOptions, setMarcaOptions] = useState([]);
  const [lineaOptions, setLineaOptions] = useState([]);
  const [sublineaOptions, setSublineaOptions] = useState([]);
  const [marca, setMarca] = useState("");
  const [otherValue, setOtherValue] = useState("");
  const [linea, setLinea] = useState("");
  const [sublinea, setSublinea] = useState("");
  const [sublineaRes, setSublineaRes] = useState([]);
  const [options, setOptions] = useState([]);
  const marcaOptionsItems = marcaOptions.Items || [];
  const lineaOptionsItems = lineaOptions.Items || [];
  const sublineaOptionsItems = sublineaOptions.Items || [];

  const handleNext = () => {
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validationSchema = yup.object().shape({
    nombre: yup.string().required("Este Campo es Obligatorio"),
    primerApellido: yup.string().required("Este Campo es Obligatorio"),
    segundoApellido: yup.string(),
  });

  const formik = useFormik({
    validationSchema,
    onSubmit: async (values) => {
      const datos = {
        marca,
        linea,
        sublinea,
        ...values,
      };

      try {
        const response = await fetch(`${ENDPOINT_MARCAS}/marcas`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          //   const formattedOptions = data.map(item => ({
          //     value: item.sk.N,
          //     label: item.NOMBRE.S,
          // }));
          // setMarcaOptions(formattedOptions);
          // setMarcaOptions(data);
          handleNext();
        } else {
          console.error("Error en la solicitud:", response.statusText);
        }
      } catch (error) {
        console.error("Error al realizar la solicitud:", error.message);
      }
    },
  });

  const handleBlur = (field) => { 
    formik.setFieldTouched(field, true);
  };

  useEffect(() => {
    const fetchMarcaOptions = async () => {
      try {
        const response = await fetch(`${ENDPOINT_MARCAS}/marcas`);
        if (response.ok) {
          const data = await response.json();
          // console.log("datos", JSON.parse(data.body));
          setMarcaOptions(JSON.parse(data.body));

          // console.log("israel", marca);
        } else {
          console.error(
            "Error al obtener opciones de marca:",
            response.statusText
          );
        }
      } catch (error) {
        console.error(
          "Error al realizar la solicitud de opciones de marca:",
          error.message
        );
      }
    };

    fetchMarcaOptions();
  }, []);

  const fetchLineaOptions = async () => {
    try {
      const response = await fetch(
        `${ENDPOINT_MARCAS}/lineas?idmarca=${marca}`
      );
      if (response.ok) {
        const data = await response.json();
        setLineaOptions(JSON.parse(data.body));
      } else {
        console.error(
          "Error al obtener opciones de marca:",
          response.statusText
        );
      }
    } catch (error) {
      console.error(
        "Error al realizar la solicitud de opciones de marca:",
        error.message
      );
    }
  };

  const fetchSublineaOptions = async () => {
    try {
      const response = await fetch(
        `${ENDPOINT_MARCAS}/sublineas?idlinea=${linea}`
      );
      if (response.ok) {
        const data = await response.json();
        setSublineaOptions(JSON.parse(data.body));
        console.log("datos", JSON.parse(data.body));
      } else {
        console.error(
          "Error al obtener opciones de sublínea:",
          response.statusText
        );
      }
    } catch (error) {
      console.error(
        "Error al realizar la solicitud de opciones de sublínea:",
        error.message
      );
    }
  };

  useEffect(() => {
    if (marca > 0) {
      fetchLineaOptions();
    }
  }, [marca]);

  useEffect(() => {
    if (linea !== "") {
      fetchSublineaOptions();
    }
  }, [linea]);

  const fetchCodigoInfo = async (codigo) => {
    try {
      const response = await fetch(`${ENDPOINT_MARCAS}/clave?clave=${codigo}`); 

      if (response.ok) {
        const data = await response.json();
        // console.log("datos obtenidos", data);
        return data; // Retornar los datos obtenidos desde el endpoint
      } else {
        throw new Error("Error al obtener la información");
      }
    } catch (error) {
      throw new Error("Error al realizar la solicitud: " + error.message);
    }
  };

  const handleCodigoChange = async (codigo) => {
    // console.log('codigo',codigo);
    try {
      const data = await fetchCodigoInfo(codigo);

      if (data) {
        const sublineData = data.body;
        const sublineDataR = JSON.parse(sublineData);
        // console.log("datos NUEVOS", sublineDataR);

        // setMarca(markData.NOMBRE);
        // setLinea(lineData.NOMBRE);
        setSublineaRes(sublineDataR);
      } else {
        console.error("Error al obtener la información:");
      }
    } catch (error) {
      console.error("Error al realizar la solicitud:", error.message);
    }
  };

  //initTE({ Clipboard, Ripple });

  // Mapeo de MARCA
  const handleChange = (selectedOption) => {
    setMarca(selectedOption ? selectedOption.value : "");
  };

  const selectOptions = marcaOptionsItems.map((item) => ({
    value: item.sk.N,
    label: item.NOMBRE.S,
  }));

  // Mapeo de LINEA
  const handleChangeLinea = (selectedOption) => {
    setLinea(selectedOption ? selectedOption.value : "");
  };

  const selectOptionsLinea = lineaOptionsItems.map((item) => ({
    value: item.sk.N,
    label: item.NOMBRE.S,
  }));

  // Mapeo de SUBLINEA
  const handleChangeSublinea = (selectedOption) => {
    setSublinea(selectedOption ? selectedOption.value : "");
  };

  const selectOptionsSublinea = sublineaOptionsItems.map((item) => ({
    value: item.sk.N,
    label: item.NOMBRE.S,
  }));

  return (
    <>
      <div className="HeaderTramite">
        <View className="FormTitle">Generador</View>
        <View className="FormSubTitle">Generador</View>
        <View className="FormDescripcion">Generador</View>
      </div>
      <View className="ViewPrincipal">
        <View className="FormContent flex">
          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className="ViewActa mx-8"
          >
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep} className="mb-3">
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    <label>
                      Descripcion: Pulsa el boton para generar tu orden de pago.
                    </label>
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Button
                      variant="secondary"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Anterior
                    </Button>

                    <Button type="submit" variant="secondary">
                      Enviar
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep === 0 ? (
                    <Box>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          controlId="validationFormik102"
                          className="position-relative"
                        >
                          <Form.Label> Marca</Form.Label>
                          {marcaOptions?.Items &&
                          marcaOptions?.Items.length > 0 ? (
                            <Select
                              name="marca"
                              value={selectOptions.find(
                                (option) => option.value === marca
                              )}
                              onChange={handleChange}
                              options={selectOptions}
                              // onChange={(e) => setMarca({value: e.value,label: e.label})}
                              // options={marcaOptions.Items.map((item, index) => ({
                              //   value: item.sk.N,
                              //   label: item.NOMBRE.S,
                              // }))}
                              // onChange={(selected) => {
                              //   setMarca(selected.value);
                              // }}
                              isSearchable={true}
                              isValid={
                                formik.touched.marca && !formik.errors.marca
                              }
                              isInvalid={
                                !!formik.errors.marca && formik.touched.marca
                              }
                              onBlur={() => handleBlur("marca")}
                            >
                              {/* <option>Seleccione...</option>
                              {marcaOptions.Items.map((option, index) => (
                                <option
                                  key={`${option.NOMBRE.S}-${index}`}
                                  value={option.sk.N}
                                  
                                >
                                  {option.NOMBRE.S}
                                </option> */}

                              {/* ))} */}
                            </Select>
                          ) : (
                            <p>No se encontraron opciones de marca.</p>
                          )}

                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.marca}
                          </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group
                          as={Col}
                          controlId="validationFormik102"
                          className="position-relative"
                        >
                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.anno}
                          </Form.Control.Feedback>
                        </Form.Group> */}
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          controlId="validationFormik102"
                          className="position-relative"
                        >
                          <Form.Label> Linea</Form.Label>
                          {lineaOptions?.Items &&
                          lineaOptions?.Items.length > 0 ? (
                            <Select
                              name="Linea"
                              // value={linea}
                              value={selectOptionsLinea.find(
                                (option) => option.value === linea
                              )}
                              // onChange={(e) => setLinea(e.target.value)}
                              onChange={handleChangeLinea}
                              options={selectOptionsLinea}
                              isSearchable={true}
                              isValid={
                                formik.touched.linea && !formik.errors.linea
                              }
                              isInvalid={
                                !!formik.errors.linea && formik.touched.linea
                              }
                              onBlur={() => handleBlur("linea")}
                            >
                              {/* <option>Seleccione...</option>
                              {lineaOptions.Items.map((option, index) => (
                                <option
                                  key={`${option.NOMBRE.S}-${index}`}
                                  value={option.sk.N}
                                >
                                  {option.NOMBRE.S}
                                </option>
                              ))} */}
                            </Select>
                          ) : (
                            <p>No se encontraron opciones de línea.</p>
                          )}

                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.mes}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          controlId="validationFormik102"
                          className="position-relative"
                        >
                          <Form.Label> Sublinea</Form.Label>
                          {sublineaOptions?.Items &&
                          sublineaOptions?.Items.length > 0 ? (
                            <Select
                              name="Sublinea"
                              // value={sublinea}
                              value={selectOptionsSublinea.find(
                                (option) => option.value === sublinea
                              )}
                              // onChange={(e) => setSublinea(e.target.value)}
                              onChange={handleChangeSublinea}
                              options={selectOptionsSublinea}
                              isSearchable={true}
                              isValid={formik.touched.mes && !formik.errors.mes}
                              isInvalid={
                                !!formik.errors.mes && formik.touched.mes
                              }
                              onBlur={() => handleBlur("mes")}
                            >
                              {/* <option>Seleccione...</option>
                              {sublineaOptions.Items.map((option, index) => (
                                <option
                                  key={`${option.NOMBRE.S}-${index}`}
                                  value={option.sk.N}
                                >
                                  {option.NOMBRE.S}
                                </option> */}
                              {/* ))} */}
                            </Select>
                          ) : (
                            <p>No se encontraron opciones de sublínea.</p>
                          )}

                          <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.mes}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Resultado</Form.Label>
                          <Form.Control
                            type="text"
                            name="ClaveGenerada"
                            id="copy-target-2"
                            value={
                              (sublineaOptions.Items &&
                                sublineaOptions.Items.find(
                                  (option) => option.sk.N === sublinea
                                )?.CLAVE.S) ||
                              ""
                            }
                            onChange={(e) => setSublinea(e.target.value)}
                          />
                          <button
                            id="copy-button"
                            type="button"
                            data-te-clipboard-init
                            data-te-clipboard-target="#copy-target-2"
                            data-te-ripple-init
                            data-te-ripple-color="light"
                            className="inline-block rounded mt-2 bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                          >
                            Copiar Resultado
                          </button>
                        </Form.Group>
                      </Row>
                    </Box>
                  ) : (
                    activeStep === 1 && (
                      <FormPagos
                        metodoPago={metodoPago}
                        setMetodoPago={setMetodoPago}
                      />
                    )
                  )}
                </React.Fragment>
              )}
            </Box>
          </Form>

          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className="ViewActa mx-8"
          >
            <Box sx={{ width: "100%" }}>
              <Row className="mb-3">
                <Form.Group as={Col} className="position-relative">
                  <Form.Label>Ingrese la Clave</Form.Label>
                  <Form.Control
                    type="text"
                    name="CodigoVehiculo"
                    id="copy-target-2"
                    onChange={(e) => handleCodigoChange(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  controlId="validationFormik102"
                  className="position-relative"
                >
                  <Form.Label> Marca</Form.Label>

                  <Form.Control
                    name="marca"
                    id="copy-target-3"
                    type="text"
                    value={
                      sublineaRes.sublineData && sublineaRes.markData.NOMBRE
                        ? sublineaRes.markData.NOMBRE
                        : ""
                    }
                    readOnly
                  />
                  <button
                    id="copy-button"
                    type="button"
                    data-te-clipboard-init
                    data-te-clipboard-target="#copy-target-3"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="inline-block rounded mt-2 bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    Copiar Resultado
                  </button>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  controlId="validationFormik102"
                  className="position-relative"
                >
                  <Form.Label> Linea</Form.Label>
                  <Form.Control
                    name="Linea"
                    id="copy-target-4"
                    type="text"
                    value={
                      sublineaRes.sublineData && sublineaRes.lineData.NOMBRE
                        ? sublineaRes.lineData.NOMBRE
                        : ""
                    }
                    readOnly
                  />
                  <button
                    id="copy-button"
                    type="button"
                    data-te-clipboard-init
                    data-te-clipboard-target="#copy-target-4"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="inline-block rounded mt-2 bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    Copiar Resultado
                  </button>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="position-relative">
                  <Form.Label> Sublinea</Form.Label>
                  <Form.Control
                    name="Sublinea"
                    id="copy-target-5"
                    type="text"
                    value={
                      sublineaRes.sublineData && sublineaRes.sublineData.NOMBRE
                        ? sublineaRes.sublineData.NOMBRE
                        : ""
                    }
                    readOnly
                  />
                  <button
                    id="copy-button"
                    type="button"
                    data-te-clipboard-init
                    data-te-clipboard-target="#copy-target-5"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="inline-block rounded mt-2 bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    Copiar Resultado
                  </button>
                </Form.Group>
              </Row>
            </Box>
            <script
              type="text/javascript"
              src="../node_modules/tw-elements/dist/js/tw-elements.umd.min.js"
            ></script>
          </Form>
        </View>
      </View>
    </>
  );
};
