import React from 'react';
import { Outlet } from 'react-router-dom'
import Header from './Header'
import { View } from '@aws-amplify/ui-react'
import Footer from './Footer'
import '../App.css'
/* import { useTramiteDataGeneral } from '../TramiteContext'
 */
export default function TramiteLayout({calculo}) {
    return (

        <View className="App">
            <Header />
            {/*             <Heading level={1}>Bienvenido a Tu Portal</Heading>
 */}
            <div className='DivPrincipal'>
                <Outlet/>
            </div>
            <Footer />
        </View>
    )
}
