import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react'; // Importa el hook de autenticación de AWS Amplify
import { Authenticator, Heading, translations } from '@aws-amplify/ui-react';
import { UserProvider } from './UserContext';
import {  I18n } from '@aws-amplify/core';
import logoDgo from './assets/DurGobEst.png'
import { TramiteDataGeneralProvider } from './TramiteContext';
const dict = {
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire",
  },
  es: {
    'Sign In': 'Iniciar Sesion',
    'Sign Up': 'Regístrate',
  },
};
I18n.putVocabularies(dict);
I18n.setLanguage('es');


const formFields = {
  signIn: {
    username: {
      label:'Número de Celular',
      dialCode: '+52',
      placeholder: '618*******',
      maxlength:"10",
    },
    
  },
  
  resetPassword: {
    username: {
      dialCode: '+52',
      placeholder: '618*******',
      maxlength:"10",
    }
  },
  signUp: {
    name:{
      order:1,
      label:"Nombre",
      placeholder:"Escriba su Nombre"
    },
    phone_number: {
      label:'Número de Celular',
      dialCode: '+52',
      placeholder: '618*******',
      maxlength:"10",
    },
  },
   
}

const components = {
  SignIn: {
    Header() {
/*       const { tokens } = useTheme();
 */
      return (
        <Heading
          
          level={3}
          style={{margin:"auto", textAlign:"center",padding:"10px" }}
        >
          <img src={logoDgo} alt='logoDgo' width={350} height={69}/>
          
          <br/>Portal de Pagos del Estado de Durango
        </Heading>
      );
    },
  },

}
const PrivateRoute = () => {

  return (

      <Authenticator className='AuthForm' formFields={formFields} components={components} 
      maxlength="10" 
      >
      <UserProvider>
        <TramiteDataGeneralProvider>
      <Outlet />
         
        </TramiteDataGeneralProvider>
      </UserProvider>
    </Authenticator>
    )
  ;
};

export default PrivateRoute;
