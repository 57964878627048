import React from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
export const FormDatosGenerales = (props) => {
   const { datos,setChangeData, changeData}= props;
   console.log(props)
    const schema = yup.object().shape({
        nombre: yup.string().required("Este valor es Obligatorio"),
        primerApellido: yup.string().required("Este valor es Obligatorio"),
        segundoApellido: yup.string(),
        sexo: yup.number().required("Este Campo es Obligatorio"),
        estadoCivil: yup.string().required("Este Campo es Obligatorio"),
        nacionalidad: yup.string().required("Este Campo es Obligatorio"),
        estadoNacimiento: yup.string().required("Este valor es Obligatorio"),
        curp: yup.string().required("Este Campo es Obligatorio"),
        rfc: yup.string().required("Este valor es Obligatorio"),
        fechaNacimiento: yup.string().required("Este valor es Obligatorio")
    });
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "curp" || name === "rfc") {
            values = values.replace(/[^A-Z0-9]/g, '');

            setFieldValue(name, values);
        } else {

            values = values.replace(/[^A-Z\s]/g, '');

            setFieldValue(name, values);
        }
    };
    const handleDateChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let formattedValue = value;

        formattedValue = formattedValue.replace(/[^\d]/g, "");

        if (formattedValue.length > 2) {
            formattedValue = formattedValue.substring(0, 2) + '/' + formattedValue.substring(2);
        }
        if (formattedValue.length > 5) {
            formattedValue = formattedValue.substring(0, 5) + '/' + formattedValue.substring(5, 9);
        }

        setFieldValue(name, formattedValue);
    };
    return (
        <>
            <View className="">
                <View className="">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            nombre: datos.nombre,
                            primerApellido: datos.primerApellido,
                            segundoApellido: datos.segundoApellido,
                            sexo: datos.datosGenerales !== null ? datos.datosGenerales.sexo : "",
                            estadoCivil: datos.datosGenerales !== null ? datos.datosGenerales.estadoCivil : "",
                            nacionalidad: datos.datosGenerales !== null ? datos.datosGenerales.nacionalidad : "",
                            estadoNacimiento: datos.datosGenerales !== null ? datos.datosGenerales.estadoNacimiento : "",
                            curp: datos.curp,
                            rfc: datos.datosGenerales !== null ? datos.datosGenerales.rfc : "",
                            fechaNacimiento: datos.datosGenerales !== null ? datos.datosGenerales.fechaNacimiento : "",
                        }}
                        onSubmit={async values => {
                            console.log(values);
                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": "nueva",
                                "usuarioID": user.username,
                                "datosGenerales": {
                                    "nombre": values.nombre,
                                    "primerApellido": values.primerApellido,
                                    "segundoApellido": values.segundoApellido,
                                    "curp": values.curp,
                                    "sexo": parseInt(values.sexo),
                                    "estadoCivil": parseInt(values.estadoCivil),
                                    "nacionalidad": parseInt(values.nacionalidad),
                                    "rfc": values.rfc,
                                    "estadoNacimiento": parseInt(values.estadoNacimiento),
                                    "fechaNacimiento": values.fechaNacimiento
                                },

                            }
                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setChangeData(!changeData)
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                            }).catch(error => {
                                console.log(error)
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Nombre (s)*</Form.Label>
                                        <Form.Control
                                            name='nombre'
                                            type='text'
                                            placeholder='Nombre (s)'
                                            value={values.nombre}
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            isValid={touched.nombre && !errors.nombre}
                                            isInvalid={touched.nombre && !!errors.nombre}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.nombre}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Primer Apellido*</Form.Label>
                                        <Form.Control
                                            name='primerApellido'
                                            type='text'
                                            placeholder='Primer Apellido'
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            value={values.primerApellido}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            isValid={touched.primerApellido && !errors.primerApellido}
                                            isInvalid={touched.primerApellido && !!errors.primerApellido}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.primerApellido}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Segundo Apellido</Form.Label>
                                        <Form.Control
                                            name='segundoApellido'
                                            type='text'
                                            placeholder='Segundo Apellido'
                                            value={values.segundoApellido}
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            isValid={touched.segundoApellido && !errors.segundoApellido}
                                            isInvalid={touched.segundoApellido && !!errors.segundoApellido}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.segundoApellido}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Sexo*</Form.Label>
                                        <Form.Select
                                            name='sexo'
                                            type='number'
                                            value={values.sexo}
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.sexo && !errors.sexo}
                                            isInvalid={touched.sexo && !!errors.sexo}
                                        >

                                            {datos.datosGenerales === null ?

                                                <option value="">SELECCIONA</option>
                                                : null}
                                            <OpcionesSelect id={7} />

                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.sexo}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Estado Civil*</Form.Label>
                                        <Form.Select
                                            name='estadoCivil'
                                            type='text'
                                            value={values.estadoCivil}
                                            onChange={handleChange}
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            isValid={touched.estadoCivil && !errors.estadoCivil}
                                            isInvalid={touched.estadoCivil && !!errors.estadoCivil}
                                        >
                                            {datos.datosGenerales === null ?

                                                <option value="">SELECCIONA</option>
                                                : null}
                                            <OpcionesSelect id={5} />


                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.estadoCivil}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Nacionalidad*</Form.Label>
                                        <Form.Select
                                            name='nacionalidad'
                                            type='text'
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            value={values.nacionalidad}
                                            onChange={handleChange}
                                            isValid={touched.nacionalidad && !errors.nacionalidad}
                                            isInvalid={touched.nacionalidad && !!errors.nacionalidad}
                                        >
                                            {datos.datosGenerales === null ?

                                                <option value="">SELECCIONA</option>
                                                : null}
                                            <OpcionesSelect id={20} />


                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.nacionalidad}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Estado de Nacimiento*</Form.Label>
                                        <Form.Select
                                            name='estadoNacimiento'
                                            type='text'
                                            value={values.estadoNacimiento}
                                            onChange={handleChange}
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            isValid={touched.estadoNacimiento && !errors.estadoNacimiento}
                                            isInvalid={touched.estadoNacimiento && !!errors.estadoNacimiento}
                                        >

                                            {datos.datosGenerales === null ?

                                                <option value="">SELECCIONA</option>
                                                : null}

                                            <OpcionesSelect id={19} />


                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.estadoNacimiento}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>CURP</Form.Label>
                                        <Form.Control
                                            name='curp'
                                            type='text'
                                            placeholder='CURP'
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            value={values.curp}
                                            maxLength={18}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            isValid={touched.curp && !errors.curp}
                                            isInvalid={touched.curp && !!errors.curp}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.curp}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>RFC*</Form.Label>

                                        <Form.Control
                                            name='rfc'
                                            type='text'
                                            placeholder='RFC'
                                            value={values.rfc}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            isValid={touched.rfc && !errors.rfc}
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            isInvalid={touched.rfc && !!errors.rfc}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.rfc}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Fecha de Nacimiento*</Form.Label>

                                        <Form.Control
                                            name='fechaNacimiento'
                                            type='text'
                                            placeholder='DD/MM/AAAA'
                                            value={values.fechaNacimiento}
                                            disabled={datos.datosGenerales !== null ? true : false}
                                            onChange={(event) => handleDateChange(event, setFieldValue)}
                                            isValid={touched.fechaNacimiento && !errors.fechaNacimiento}
                                            isInvalid={touched.fechaNacimiento && !!errors.fechaNacimiento}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.fechaNacimiento}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='secondary' disabled={datos.datosGenerales !== null ? true : false} type='submit'>GUARDAR</Button>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='link'>REGRESAR</Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
        </>
    )
}
