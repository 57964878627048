import React from 'react';
import logoDurango from "../assets/DurGobEst.png"
import instagram from "../assets/instagram.png"
import facebook from "../assets/facebook.png"
import twitter from "../assets/twitter.png"
import '../styles/Footer.css'
const Footer = () => {
    const REACT_APP_PRIVACIDAD = process.env.REACT_APP_PRIVACIDAD;
    const REACT_APP_TyC = process.env.REACT_APP_TyC;
    const REACT_APP_HOME = process.env.REACT_APP_HOME;

    return ( 
        <footer className="footer"> 
            
            <div className="footer-content" >
                <a href={REACT_APP_HOME} className="footer-left">
                    <img src={logoDurango} alt="logo-Durango" className="footer-logo" />
                </a>
                <div className="footer-right">
        
                    <a href="https://www.facebook.com/gobdgo" className="social-icon" rel="noreferrer"><img src={facebook} alt="Durango" id='facebook-logo'/></a>
                    <a href="https://www.instagram.com/gobdgo/" className="social-icon" rel="noreferrer"><img src={instagram} alt="Durango" /></a>
                    <a href="https://twitter.com/gobdgo?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" className="social-icon" target='_blank' rel="noreferrer"><img src={twitter} alt="Durango" id='twitter-logo' /></a>                
                </div>
            </div>
            <hr className="footer-divider" />
            <div className="footer-bottom">
                <span className="footer-text-left" >©2023 Gobierno del Estado de Durango</span>
                <div className='divRigth'>

                    <a className="footer-text-right" href={REACT_APP_PRIVACIDAD}  target='_blank' rel="noopener noreferrer" >Avisos de Privacidad</a>
                    <a className="footer-text-right" href={REACT_APP_TyC} target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
                </div>

            </div>
        </footer>



    );
}



export default Footer;
