import React from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
export const FormDatosEmergencia = (props) => {
    const navigate = useNavigate();
    const { datos,setChangeData, changeData}= props;
    const schema = yup.object().shape({
        tipoLicencia: yup.string(),
        tipoTramite: yup.string(),
        nombreResponsable: yup.string().required("Este Campo es Obligatorio"),
        domicilio: yup.string().required("Este valor es Obligatorio"),
        estado: yup.string().required("Este valor es Obligatorio"),
        municipio: yup.string().required("Este valor es Obligatorio"),
        telefono: yup.string().required("Este valor es Obligatorio")
    });
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "telefono") {
            values = values.replace(/[^0-9]/g, '');

            setFieldValue(name, values);
        } else if (name==="domicilio"){

            values = values.replace(/[^A-Z0-9,\s]/g, '');

            setFieldValue(name, values);
        }else{
            values = values.replace(/[^A-Z\s]/g, '');

            setFieldValue(name, values);
        }
    };
    return (
        <>


            <View className="ViewPrincipal">

                <View className="ViewContent">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            tipoTramite: datos.type,
                            tipoLicencia: datos.procedure,
                            nombreResponsable: datos.contactoEmergencia !== null ? datos.contactoEmergencia.nombreResponsable : "",
                            domicilio:datos.contactoEmergencia !== null ? datos.contactoEmergencia.domicilio : "",
                            estado: datos.contactoEmergencia !== null ? datos.contactoEmergencia.estado : "",
                            municipio: datos.contactoEmergencia !== null ? datos.contactoEmergencia.municipio : "",
                            telefono: datos.contactoEmergencia !== null ? datos.contactoEmergencia.telefono : ""
                        }}
                        onSubmit={async values => { 
                            console.log(values);
                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": "nueva",
                                "usuarioID": user.username,
                                "contactoEmergencia": {
                                    "nombre": values.nombreResponsable,
                                    "domicilio": values.domicilio,
                                    "estado": values.estado,
                                    "municipio": values.municipio,
                                    "telefono": values.telefono
                                },
                            }
                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setChangeData(!changeData)
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                            }).catch(error => {
                                console.log(error)
                            })
                         }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors ,setFieldValue}) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>
                                  <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Licencia</Form.Label>
                                        <Form.Select
                                            name='tipoLicencia'
                                            type='text'
                                            value={values.tipoLicencia}
                                            onChange={handleChange}
                                            disabled
                                            isValid={touched.tipoLicencia && !errors.tipoLicencia}
                                            isInvalid={touched.tipoLicencia && !!errors.tipoLicencia}
                                        >
                                             <OpcionesSelect id={"LICENCIAS"} valor={"categoria"}/>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoLicencia}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Tramite</Form.Label>
                                        <Form.Control
                                            name='tipoTramite'
                                            type='text'
                                            value={values.tipoTramite.toUpperCase()}
                                            onChange={handleChange}
                                            disabled
                                            isValid={touched.tipoTramite && !errors.tipoTramite}
                                            isInvalid={touched.tipoTramite && !!errors.tipoTramite}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoTramite}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Label style={{ textDecoration: "underline" }}>En caso de emergencia llamar a:</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Nombre Completo*</Form.Label>
                                        <Form.Control
                                            name='nombreResponsable'
                                            type='text'
                                            placeholder='Nombre Completo'
                                            value={values.nombreResponsable}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.contactoEmergencia !== null ? true : false}
                                            isValid={touched.nombreResponsable && !errors.nombreResponsable}
                                            isInvalid={touched.nombreResponsable && !!errors.nombreResponsable}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.nombreResponsable}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Domicilio*</Form.Label>

                                        <Form.Control
                                            name='domicilio'
                                            type='text'
                                            placeholder='Domicilio'
                                            value={values.domicilio}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.contactoEmergencia !== null ? true : false}
                                            isValid={touched.domicilio && !errors.domicilio}
                                            isInvalid={touched.domicilio && !!errors.domicilio}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.domicilio}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Estado*</Form.Label>

                                        <Form.Control
                                            name='estado'
                                            type='text'
                                            placeholder='Estado'
                                            value={values.estado}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.contactoEmergencia !== null ? true : false}
                                            isValid={touched.estado && !errors.estado}
                                            isInvalid={touched.estado && !!errors.estado}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.estado}</Form.Control.Feedback>


                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Municipio*</Form.Label>

                                        <Form.Control
                                            name='municipio'
                                            type='text'
                                            placeholder='Municipio'
                                            value={values.municipio}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.contactoEmergencia !== null ? true : false}
                                            isValid={touched.municipio && !errors.municipio}
                                            isInvalid={touched.municipio && !!errors.municipio}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.municipio}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Telefono*</Form.Label>

                                        <Form.Control
                                            name='telefono'
                                            type='text'
                                            placeholder='Telefono'
                                            value={values.telefono}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={datos.contactoEmergencia !== null ? true : false}
                                            isValid={touched.telefono && !errors.telefono}
                                            isInvalid={touched.telefono && !!errors.telefono}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.telefono}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='secondary' disabled={datos.contactoEmergencia !== null ? true : false} type='submit'>GUARDAR</Button>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='link'>REGRESAR</Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer/>
        </>
    )
}
