import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Form as BootstrapForm, Col, Button, Row, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import Iframe from "react-iframe";

export const FormDocumentos = (props) => {
    const { datos,setChangeData, changeData}= props;
    const [archivos, setArchivos] = useState([])
    const [viewDocuent, setViewDocument] = useState(false);
    const [infoDocuent, setInfoDocument] = useState("");


    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "procedureType": "LICENCIAAUTOMOVILISTA-NUEVA"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev/fileConfig/listActiveFiles", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                setArchivos(result.items)
            })
            .catch((error) => console.error(error));
    }, [])

    // Esquema de validación para un solo archivo
    const fileValidationSchema = Yup.mixed()
        .required("El archivo es obligatorio")
        .test("fileSize", "El tamaño del archivo no debe exceder 10 MB", value => value && value.size <= 4 * 1024 * 1024)
        .test("fileType", "Solo se permiten archivos PDF", value => value && value.type === "application/pdf");

    // Esquema de validación general basado en los nombres únicos
    const validationSchema = Yup.object().shape(
        archivos.reduce((acc, archivo) => {
            acc[archivo.codeName] = fileValidationSchema;
            return acc;
        }, {})
    );


    const handleSubirArchivos = async (values) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/pdf");
        const arrayArchivos = [];
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                const file = values[key];

                var requestOptions = {
                    method: "PUT",
                    headers: myHeaders,
                    body: file,
                    redirect: "follow",
                };
                await fetch(
                    `https://v0u9swbgk2.execute-api.us-east-1.amazonaws.com/dev/${key}.pdf`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {

                        console.log(result);
                        const nuevoValor = { name: key, url: result.s3_url };
                        arrayArchivos.push(nuevoValor);
                    })
                    .catch((error) => console.log("error", error));
            }
        }
        const objetoTransformado = arrayArchivos.reduce((acc, archivo) => {
            acc[archivo.name] = archivo.url;
            return acc;
        }, {});
        console.log(arrayArchivos)
        SubirDocumento(objetoTransformado);
    };

    const SubirDocumento = async (arrayArchivos) => {
        console.log(arrayArchivos)
        const user = await getCurrentUser();
        const data = {
            "id": datos.id,
            "procedure": datos.procedure,
            "type": "nueva",
            "usuarioID": user.username,
            "documentos": arrayArchivos,
        }
        CrearIteamAPI(data).then(result => {

            if (result.ok) {
                toast.success("Datos Guardados Correctamente", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setChangeData(!changeData)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
        }).catch(error => {
            console.log(error)
        })
    }
    function buscarDocumento(clave) {
        if (datos.documentos.hasOwnProperty(clave)) {
            return datos.documentos[clave];
        } else {
            return null; // O puedes devolver un mensaje indicando que no existe.
        }
    }
    const handleDocumento = (url) => {
        const valor = buscarDocumento(url);
    
        setInfoDocument(valor);
        setViewDocument(true);
      };
    return (
        <>
            <Formik
                initialValues={archivos?.reduce((acc, archivo) => {
                    acc[archivo.codeName] = null; // Inicializa cada campo de archivo como null
                    return acc;
                }, {})}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    console.log("Valores del formulario:", values);
                    handleSubirArchivos(values);
                }}
            >
                {({ setFieldValue, errors, touched, setFieldError }) => (
                    <Form>
                        <Row className='mb-3'>
                        <BootstrapForm.Label style={{textDecoration:"underline"}}>Adjutar Documentacíon</BootstrapForm.Label>
                        </Row>
                        {archivos !== null && archivos.map((archivo) => (
                            <div key={archivo.id} className="mb-3">
                                <BootstrapForm.Group className='position-relative' as={Col} name={archivo.codeName}>
                                    <BootstrapForm.Label>{archivo.documentName}</BootstrapForm.Label>
                                    {datos.documentos === null ?
                                        <>
                                            <BootstrapForm.Control
                                                type="file"
                                                required
                                                disabled={datos.documentos !== null ? true : false}
                                                onChange={(event) => {
                                                    const file = event.currentTarget.files[0];
                                                    setFieldValue(archivo.codeName, file);

                                                    // Validaciones personalizadas
                                                    if (file) {
                                                        if (file.size > archivo.size * 1024 * 1024) {
                                                            setFieldError(archivo.codeName, "El tamaño del archivo no debe exceder 10 MB");
                                                        } else if (file.type !== "application/pdf") {
                                                            setFieldError(archivo.codeName, "Solo se permiten archivos PDF");
                                                        }
                                                    }
                                                }}
                                                isInvalid={touched[archivo.codeName] && !!errors[archivo.codeName]}
                                                accept={archivo.fileType.map(type => `.${type}`).join(',')}

                                            />
                                            <BootstrapForm.Control.Feedback type='invalid' tooltip>{errors[archivo.codeName]}</BootstrapForm.Control.Feedback>
                                        </>
                                        :
                                        <BootstrapForm.Control
                                        readOnly
                                        accept=".pdf"
                                        plaintext
                                        style={{ textDecoration: "underline", color: "blue" }}
                                        onClick={() => handleDocumento(archivo.codeName)}
                                        defaultValue={"Ver Documento"}
                                      />
                                }
                                </BootstrapForm.Group>
                                {/*  <ErrorMessage name={archivo.name} component="div" className="text-danger" /> */}
                            </div>
                        ))}
                        <Row className='mb-3'>
                            <Button variant='secondary' disabled={datos.documentos !== null ? true : false} type='submit'>GUARDAR</Button>
                        </Row>
                        <Row className='mb-3'>
                            <Button variant='link'>REGRESAR</Button>
                        </Row>
                    </Form>
                )}
            </Formik>
            <ToastContainer />
            <Modal
        show={viewDocuent}
        animation={false}
        onHide={() => setViewDocument(false)}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Documentos </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Iframe
            src={infoDocuent}
            height="700px"
            width="750px"
            display="initial"
            position="relative"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setViewDocument(false)}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    );
};

