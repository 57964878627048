import React, { useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { View, } from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom'; 
 

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';
const steps = ['Información', 'Formas de pago'];

export const FormBusquedaRegistroDatos = () => {
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [metodoPago, setMetodoPago] = useState('pago1');

    const handleNext = () => {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const validationSchema = yup.object().shape({
        nombre: yup.string().required("Este Campo es Obligatorio"),
        primerApellido: yup.string().required("Este Campo es Obligatorio"),
        segundoApellido: yup.string(),
    });

    const formik = useFormik({
        validationSchema,
        onSubmit: values => { 
            handleNext()
        },
        initialValues: {
            nombre: '',
            primerApellido: '',
            segundoApellido: '',

        }

    });


    return (
      <>
        <div className="HeaderTramite">
          <View className="FormTitle">Búsqueda De Registros O Datos</View>
          <View className="FormSubTitle">Búsqueda De Registros O Datos</View>
          <View className="FormDescripcion">
            A personas físicas, personas morales, unidades económicas y
            entidades gubernamentales que generen emisiones de gases de efecto
            invernadero en sus procesos productivos y operaciones dentro del
            territorio del Estado de Durango, México.
          </View>
        </div>
        <View className="ViewPrincipal">
          <View className="FormContent">
            <Form
              onSubmit={formik.handleSubmit}
              noValidate
              className="ViewActa"
            >
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep} className="mb-3">
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      <label>
                        Descripcion: Pulsa el boton para generar tu orden de
                        pago.
                      </label>
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      style={{ justifyContent: "space-between" }}
                    >
                      <Button
                        variant="secondary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        Anterior
                      </Button>

                      <Button type="submit" variant="secondary">
                        Enviar
                      </Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {activeStep === 0 ? (
                      <Box>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Tipo *</Form.Label>
                            <Form.Control
                              type="text"
                              name="tipo"
                              onChange={formik.handleChange}
                              value={formik.values.tipo}
                              isValid={
                                formik.touched.tipo && !formik.errors.tipo
                              }
                              isInvalid={
                                formik.touched.tipo && !!formik.errors.tipo
                              }
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.tipo}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Razón Social *</Form.Label>
                            <Form.Control
                              type="text"
                              name="RazonSocial"
                              onChange={formik.handleChange}
                              value={formik.values.RazonSocial}
                              isValid={
                                formik.touched.RazonSocial && !formik.errors.RazonSocial
                              }
                              isInvalid={
                                formik.touched.RazonSocial && !!formik.errors.RazonSocial
                              }
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.RazonSocial}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Nombre (s)*</Form.Label>
                            <Form.Control
                              type="text"
                              name="nombre"
                              onChange={formik.handleChange}
                              value={formik.values.nombre}
                              isValid={
                                formik.touched.nombre && !formik.errors.nombre
                              }
                              isInvalid={
                                formik.touched.nombre && !!formik.errors.nombre
                              }
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.nombre}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Primer Apellido*</Form.Label>
                            <Form.Control
                              type="text"
                              name="primerApellido"
                              onChange={formik.handleChange}
                              value={formik.values.primerApellido}
                              isValid={
                                formik.touched.primerApellido &&
                                !formik.errors.primerApellido
                              }
                              isInvalid={
                                formik.touched.primerApellido &&
                                !!formik.errors.primerApellido
                              }
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.primerApellido}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Segundo Apellido</Form.Label>
                            <Form.Control
                              type="text"
                              name="segundoApellido"
                              onChange={formik.handleChange}
                              value={formik.values.segundoApellido}
                              isValid={
                                formik.touched.segundoApellido &&
                                !formik.errors.segundoApellido
                              }
                              isInvalid={
                                formik.touched.segundoApellido &&
                                !!formik.errors.segundoApellido
                              }
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.segundoApellido}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </Box>
                    ) : (
                      activeStep === 1 && (
                        <FormPagos
                          metodoPago={metodoPago}
                          setMetodoPago={setMetodoPago}
                        />
                      )
                    )}
                  </React.Fragment>
                )}
              </Box>
              <Row className="mb-3 mt-3">
                <Form.Group as={Col} className="d-grid gap-2">
                  <Button type="submit" variant="secondary">
                    SIGUIENTE
                  </Button>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} className="d-grid gap-2">
                  <Button onClick={() => navigate("/")} variant="light">
                    {" "}
                    Regresar
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </View>
        </View>
      </>
    );
}
