import { useEffect, useState } from "react";
import {
    Form,
    Row,
    Col,
    Modal,
    Button
} from "react-bootstrap";
import "../../App.css";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as yup from "yup";

import Select from 'react-select';
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;
const ENDPOINT_DOCUMENTOS = process.env.REACT_APP_DOCUMENTOS;
const estadosArray = [
    { value: '', label: 'Seleccione...' },
    { value: 'AGUASCALIENTES', label: 'Aguascalientes' },
    { value: 'BAJA CALIFORNIA', label: 'Baja California' },
    { value: 'BAJA CALIFORNIA SUR', label: 'Baja California Sur' },
    { value: 'CAMPECHE', label: 'Campeche' },
    { value: 'COAHUILA', label: 'Coahuila' },
    { value: 'COLIMA', label: 'Colima' },
    { value: 'CHIAPAS', label: 'Chiapas' },
    { value: 'CHIHUAHUA', label: 'Chihuahua' },
    { value: 'DISTRITO FEDERAL', label: 'Distrito Federal' },
    { value: 'GUANAJUATO', label: 'Guanajuato' },
    { value: 'GUERRERO', label: 'Guerrero' },
    { value: 'HIDALGO', label: 'Hidalgo' },
    { value: 'JALISCO', label: 'Jalisco' },
    { value: 'MEXICO', label: 'México' },
    { value: 'MICHOACAN', label: 'Michoacán' },
    { value: 'MORELOS', label: 'Morelos' },
    { value: 'NAYARIT', label: 'Nayarit' },
    { value: 'NUEVO LEON', label: 'Nuevo León' },
    { value: 'OAXACA', label: 'Oaxaca' },
    { value: 'PUEBLA', label: 'Puebla' },
    { value: 'QUERETARO', label: 'Querétaro' },
    { value: 'QUINTANA ROO', label: 'Quintana Roo' },
    { value: 'SAN LUIS POTOSI', label: 'San Luis Potosí' },
    { value: 'SINALOA', label: 'Sinaloa' },
    { value: 'SONORA', label: 'Sonora' },
    { value: 'TABASCO', label: 'Tabasco' },
    { value: 'TAMAULIPAS', label: 'Tamaulipas' },
    { value: 'TLAXCALA', label: 'Tlaxcala' },
    { value: 'VERACRUZ', label: 'Veracruz' },
    { value: 'YUCATAN', label: 'Yucatán' },
    { value: 'ZACATECAS', label: 'Zacatecas' },
].map(opcion => ({ ...opcion, label: opcion.label.toUpperCase() }));



export const ModalEditar = (props) => {
    const { showEdit, changeInfo, setChangeInfo, dataEdit, setShowEdit } = props;
    const [validarCoche, setValidarCoche] = useState(true);
    const [token, setToken] = useState("");
    const [archivos, setArchivos]= useState();
    useEffect(()=>{

        if(dataEdit !== null && dataEdit.factura !== ""){
           setArchivos( JSON.parse(dataEdit?.factura))
        }
    },[dataEdit])

    const UpdateVehiculos = (values, pass=true) => {


        const idToast = toast.loading("Actualizando Datos...")


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(
            {
                dynamoID: dataEdit.id,
                ticketType: 17,
                serie: values.numeroSerie,
                modelo: parseInt(values.modelo),
                estadoProcedencia: values.estadoProcedencia,
                origenVehiculo:values.origenVehiculo === dataEdit.origenVehiculo ? "": values.origenVehiculo

            },
        );

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/updateTicket`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                toast.update(idToast, { render: "Información Actualizada", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, });
                if (pass) {
                    formik.resetForm();
                    setShowEdit(false);
                    setValidarCoche(true)
                    setChangeInfo(!changeInfo);
                }

            })
            .catch((error) => {
                console.log("error", error);
            });

    };


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Factura: "",
            Repuve: "",
            HojaRecepcion: "",
            tituloAmericano: "",
            confirmNumeroSerie: dataEdit === null ? "" : dataEdit.numeroSerie,
            estadoProcedencia: dataEdit === null ? "" : dataEdit.estadoProcedencia,
            modelo: dataEdit === null ? "" : dataEdit.modelo,
            numeroSerie: dataEdit === null ? "" : dataEdit.numeroSerie,
            origenVehiculo: dataEdit === null ? "" : dataEdit.origenVehiculo
        },
        validationSchema: yup.object({
            numeroSerie: yup
                .string()
                .required("Este campo es obligatorio")
                .matches(/^[A-HJ-NPR-Z0-9]*$/, "No puede contener caracteres especiales ni O,Q,I,Ñ"),
            confirmNumeroSerie: yup
                .string()
                .required("Este campo es obligatorio")
                .oneOf(
                    [yup.ref("numeroSerie"), null],
                    "Los Números de serie no coinciden"
                ),
            estadoProcedencia: yup.string(),
            origenVehiculo: yup.string(),
            modelo: yup
                .string()
                .required("Este campo es obligatorio")
                .max(9999, "Valor no valido"),
            Factura: yup
                .mixed()
                .test('fileType', 'Solo se permiten archivos PDF', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    return value.type === 'application/pdf';
                })
                .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                    return value.size <= maxSize;
                }),
            tituloAmericano: yup
                .mixed()
                .test('fileType', 'Solo se permiten archivos PDF', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    return value.type === 'application/pdf';
                })
                .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                    return value.size <= maxSize;
                }),
            Repuve: yup
                .mixed()
                .test('fileType', 'Solo se permiten archivos PDF', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    return value.type === 'application/pdf';
                })
                .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                    return value.size <= maxSize;
                }),
            HojaRecepcion: yup
                .mixed()
                .test('fileType', 'Solo se permiten archivos PDF', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    return value.type === 'application/pdf';
                })
                .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                    return value.size <= maxSize;
                }),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            (async () => {
                try {
                    if (values.numeroSerie === dataEdit.numeroSerie && values.modelo === dataEdit.modelo && values.estadoProcedencia === dataEdit.estadoProcedencia && values.origenVehiculo === dataEdit.origenVehiculo && values.Factura === ""&& values.Repuve ===""&& values.HojaRecepcion === "" && values.tituloAmericano ==="") {
                        setShowEdit(false);
                    } else if (values.Factura === ""&& values.Repuve ===""&& values.HojaRecepcion === "" && values.tituloAmericano ==="" && (values.numeroSerie !== dataEdit.numeroSerie || values.modelo !== dataEdit.modelo || values.estadoProcedencia !== dataEdit.estadoProcedencia || values.origenVehiculo !== dataEdit.origenVehiculo)) {
                        if (values.numeroSerie === dataEdit.numeroSerie && values.modelo === dataEdit.modelo && (values.estadoProcedencia !== dataEdit.estadoProcedencia || values.origenVehiculo !== dataEdit.origenVehiculo)) {
                           if(values.origenVehiculo !== dataEdit.origenVehiculo){
                            toast.warn('Favor de subir todos los documentos', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                });
                        }else{
                                UpdateVehiculos(values);

                            }
                        } else {
                            const validarCoche = await ValidarAuto(values);
                           
                            if (validarCoche === false) {
                                if(values.origenVehiculo !== dataEdit.origenVehiculo){
                                    toast.warn('Favor de subir todos los documentos', {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                        });
                                }else{
                                        UpdateVehiculos(values);
        
                                    }
                            }
                        }
                    } else if ((values.Factura !== "" || values.Repuve !==""|| values.HojaRecepcion !== "" || values.tituloAmericano !=="") && (values.numeroSerie !== dataEdit.numeroSerie || values.modelo !== dataEdit.modelo || values.estadoProcedencia !== dataEdit.estadoProcedencia || values.origenVehiculo !== dataEdit.origenVehiculo)) {
                        if (values.numeroSerie === dataEdit.numeroSerie && values.modelo === dataEdit.modelo && (values.estadoProcedencia !== dataEdit.estadoProcedencia || values.origenVehiculo !== dataEdit.origenVehiculo)) {
                           if(values.origenVehiculo !== dataEdit.origenVehiculo){
                            if(values.origenVehiculo === "NACIONAL" && values.Factura !== ""){
                                 UpdateVehiculos(values, false);
                                 subirFactura(values);
                                }else if(values.origenVehiculo === "EXTRANJERO" && values.Repuve !==""&& values.HojaRecepcion !== "" && values.tituloAmericano !==""){
                                UpdateVehiculos(values, false);
                                subirFactura(values);

                            }else{
                                toast.warn('Favor de subir todos los documentos', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                            }
                           }
                           
                        } else {
                            const validarCoche = await ValidarAuto(values);
                            console.log("Resultado de ValidarAuto:", validarCoche);
                            if (validarCoche === false) {
                                UpdateVehiculos(values, false);
                                subirFactura(values);
                            }
                        }
                    } else {
                        subirFactura(values);
                    }

                    setSubmitting(false); // Marca el formulario como "enviado"
                } catch (error) {
                    console.error("Ocurrió un error:", error);
                    setSubmitting(false); // Marca el formulario como "enviado" en caso de error
                }
            })();
        },
    });
    const subirFactura = async (values) => {
        console.log("----VALUES", values)
        const idToast = toast.loading("Subiendo Factura")
        const arrayArchivos = [];
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                if (key === `Repuve` || key === `Factura` || key === `HojaRecepcion` || key === `tituloAmericano`) {
                    if (values[key] !== "") {

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/pdf");

                        var file = values[key];

                        var requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: file,
                            redirect: "follow",
                        };

                        try {
                            const response = await fetch(`${ENDPOINT_DOCUMENTOS}/subir/?documentName=${key}`, requestOptions);
                            const result = await response.json();
                          
                            if (result.s3_url !== undefined) {
                                const nuevoValor = { name: key, url: result.s3_url };
                                arrayArchivos.push(nuevoValor);
                            } else {
                                toast.update(idToast, { render: "No se pudo subir el archivo", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
                            }
                        } catch (error) {
                            console.log("error", error);
                        }
                    }
                }
            }
        }

        ActualizaFacturadynamo(arrayArchivos, idToast, values.origenVehiculo);
    };

/*     const subirFactura = (values) => {
        const idToast = toast.loading("Subiendo Factura...")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/pdf");

        var file = values;

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: file,
            redirect: "follow",
        };

        fetch(`${ENDPOINT_DOCUMENTOS}/subir/?documentName=Factura`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.s3_url !== undefined) {
                    ActualizaFacturadynamo(result.s3_url, idToast);

                } else {
                    toast.update(idToast, { render: "No se pudo subir el archivo", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });

                }
            })
            .catch((error) => console.log("error", error));
    }; */
    function buscarURLPorNombre(objeto, nombreBuscado) {
        for (const key in objeto) {
            if (objeto[key].name === nombreBuscado) {
                return objeto[key].url;
            }
        }
        return null; // Retorna null si no se encuentra ninguna coincidencia
    }
    const ActualizaFacturadynamo = (values, idToast, origen) => {
        console.log(values, origen);
        let paths=[];
        if(origen==="EXTRANJERO"){
            paths =[
                {
                    name:"Repuve" ,
                    url: buscarURLPorNombre(values,"Repuve") !== null ? buscarURLPorNombre(values,"Repuve") : buscarURLPorNombre(archivos, "Repuve")
                },
                {
                    name:"HojaRecepcion",
                    url: buscarURLPorNombre(values,"HojaRecepcion") !== null ? buscarURLPorNombre(values,"HojaRecepcion") : buscarURLPorNombre(archivos, "HojaRecepcion"),
                },
                {
                    name:"tituloAmericano",
                    url: buscarURLPorNombre(values,"tituloAmericano") !== null ? buscarURLPorNombre(values,"tituloAmericano") : buscarURLPorNombre(archivos, "tituloAmericano"),
                }
            ]
        }else{
            paths=[
                {
                    name:"Factura",
                    url:buscarURLPorNombre(values,"Factura") !== null ? buscarURLPorNombre(values,"Factura") : buscarURLPorNombre(archivos, "Factura")
                }
            ]
        }
         var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "paths": paths,
            dynamoID: dataEdit.id,
            ticketType: 17,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/attachments`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                console.log(result);
                toast.update(idToast, { render: `La Factura del Ticket #${dataEdit.ticketID} se ha actualizado`, type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, });
                formik.resetForm();
                setShowEdit(false);
                setValidarCoche("");
                setChangeInfo(!changeInfo);
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        const fetchData = async () => {
            const url = `${ENDPOINT}/auth`;

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        // Puedes agregar otros encabezados según sea necesario
                    },
                    // Puedes agregar datos al cuerpo de la solicitud si es necesario
                    // body: JSON.stringify({ key: 'value' }),
                });

                if (!response.ok) {
                    throw new Error(`Error en la solicitud: ${response.status}`);
                }

                const data = await response.json();
                setToken(data.token);
                // Puedes manejar la respuesta según tus necesidades
            } catch (error) {
                console.error("Error:token", error.message);
                // Puedes manejar el error según tus necesidades
            }
        };

        // Llama a la función para realizar la solicitud
        fetchData();
    }, []);
    const ValidarAuto = async (values) => {
        const idToast = toast.loading("Verificando Número de Serie...");
        if (values.modelo < 1900) {
            toast.update(idToast, { render: "Favor de verificar el modelo ingresado.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
            return false; // Aquí devolvemos false ya que la validación ha fallado
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                serie: values.numeroSerie,
                modelo: values.modelo.toString(),
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                const response = await fetch(`${ENDPOINT}/check-vehicle`, requestOptions);
                const result = await response.json();
                if (result.exists === true) {
                    setValidarCoche(result.exists);
                    toast.update(idToast, { render: result.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
                    return result.exists;
                } else if (result.error !== undefined) {
                    setValidarCoche(result.exists);
                    toast.update(idToast, { render: result.error, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
                    return result.exists;
                } else {
                    toast.dismiss(idToast.current);
                    setValidarCoche(result.exists);
                    return result.exists;
                }
            } catch (error) {
                console.error("error", error);
                return false; // Aquí devolvemos false en caso de error
            }
        }
    };
    const handleChange = event => {
        const { name, value } = event.target;
        const uppercaseValue = value.toUpperCase();

        formik.handleChange({
            target: {
                name,
                value: uppercaseValue,
            },
        });
    };
    const handleSelectChange = (selectedOption) => {
        const name = "estadoProcedencia";
        const value = selectedOption ? selectedOption.value : null;

        if (value === undefined) {
            formik.setFieldValue(name, null);
        } else {
            // Actualizar el valor del campo en Formik
            formik.setFieldValue(name, value);
        }
    };
    const handleInputChangeCodigo = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, "");
        if (value.length <= 4) {
            formik.setFieldValue(name, numericValue);
        }
    };
    return (
        <Modal show={showEdit} size="lg">
            <Modal.Header>
                <Modal.Title className="w-full text-center">
                    <div className="w-full text-center font-bold text-4xl">Editar</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Form.Group
                        as={Col}

                        className="position-relative"
                    >
                        <Form.Label>Número de Serie:</Form.Label>

                        <Form.Control
                            type="text"
                            autoComplete="off"
                            name="numeroSerie"
                            maxLength="17"
                            placeholder="No. Serie"
                            value={formik.values.numeroSerie}
                            onChange={handleChange}
                            isValid={
                                formik.touched.numeroSerie &&
                                !formik.errors.numeroSerie
                            }
                            isInvalid={

                                !!formik.errors.numeroSerie
                            }
                        />
                        <Form.Control.Feedback type="invalid" tooltip>{formik.errors.numeroSerie}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        as={Col}

                        className="position-relative"
                    >

                        <Form.Label>Confirmar Número de Serie:</Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="off"
                            name="confirmNumeroSerie"
                            placeholder="No. Serie"
                            maxLength="17"
                            /*  disabled={!disabledInput(data.isComplete, data.numeroSerie)} */
                            /*  onBlur={() => handleBlur(`Confirmar-NoSerie-${data.formularioID}`)} */
                            value={
                                formik.values.confirmNumeroSerie
                            }
                            onChange={handleChange}
                            isValid={
                                formik.touched.confirmNumeroSerie &&
                                !formik.errors.confirmNumeroSerie
                            }
                            isInvalid={
                                !!formik.errors.confirmNumeroSerie
                            }
                        />

                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.confirmNumeroSerie}
                        </Form.Control.Feedback>
                    </Form.Group>

                </Row>
                <Row className="mb-3">
                    <Form.Group
                        as={Col}

                        className="position-relative"
                    >
                        <Form.Label>Modelo:</Form.Label>
                        <Form.Control
                            /*   disabled={!disabledInput(data.isComplete, data.modelo)} */
                            type="text"
                            /* disabled={formik.errors.confirmNumeroSerie} */
                            name="modelo"
                            placeholder="Modelo"
                            autoComplete="off"
                            /*   onBlur={() => {
                                  //formik.values[`EstadoPro-${data.formularioID}`]
                                  if (!formik.errors.modelo) {
                                      ValidarAuto(
                                          formik.values.modelo,
                                          formik.values.numeroSerie
                                      );
                                  } else {
                                      formik.values.estadoProcedencia = "";
                                  }
                              }} */
                            value={formik.values.modelo ?? ""}
                            onChange={handleInputChangeCodigo}
                            isValid={
                                formik.touched.modelo &&
                                !formik.errors.modelo
                            }
                            isInvalid={
                                formik.touched.modelo &&
                                !!formik.errors.modelo
                            }
                        />

                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.modelo}
                        </Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group
                        as={Col}

                    >
                        <Form.Label>Estado de Procedencia</Form.Label>
                        <Select
                            name="estadoProcedencia"
                            defaultValue={dataEdit !== null && estadosArray.find(option => option.value === dataEdit.estadoProcedencia)}
                            /* isDisabled={disbledFormEstado(active, data.isComplete)} */
                            onChange={handleSelectChange}
                            autoComplete="off"
                            /*  isDisabled={validarCoche} */
                            /* onBlur={() => formik.handleSubmit()}
                            onFocus={() => formik.handleSubmit()} */
                            onInvalid={formik.touched.estadoProcedencia && !formik.errors.estadoProcedencia}
                            onError={formik.touched.estadoProcedencia && !!formik.errors.estadoProcedencia}
                            options={estadosArray}
                            isSearchable={true}
                            placeholder="Seleccione..."
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.estadoProcedencia}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group>
                    <Form.Label>Origen del Vehículo</Form.Label>
                        <Form.Select
                            type="text"
                            placeholder="Estado de Procedencia"
                            name={`origenVehiculo`}
                            value={formik.values.origenVehiculo ?? ""}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            isValid={
                                formik.touched.origenVehiculo &&
                                !formik.errors.origenVehiculo
                            }
                            isInvalid={
                                formik.touched.origenVehiculo &&
                                !!formik.errors.origenVehiculo
                            }
                        >
                            <option value="">Seleciona</option>
                            <option value="NACIONAL">NACIONAL</option>
                            <option value="EXTRANJERO">EXTRANJERO</option>

                        </Form.Select>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.origenVehiculo}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                {formik.values.origenVehiculo === "EXTRANJERO" ?
                    <>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId={`Formik-Factura`} className="position-relative">
                                <Form.Label>Decreto de regularización o repuve:</Form.Label>
                                <Form.Control
                                    type="file"
                                    placeholder="factura"
                                    name={`Repuve`}
                                    accept=".pdf"
                                    /*       disabled={validarCoche} */
                                    autoComplete="off"
                                    isInvalid={formik.touched.Repuve && !!formik.errors.Repuve}
                                    onChange={(event) => {

                                        formik.setFieldValue(`Repuve`, event.currentTarget.files[0]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.Repuve}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId={`Formik-Factura`} className="position-relative">
                                <Form.Label>Hoja de recepción de información:</Form.Label>
                                <Form.Control
                                    type="file"
                                    placeholder="factura"
                                    name={`HojaRecepcion`}
                                    accept=".pdf"
                                    /*       disabled={validarCoche} */
                                    autoComplete="off"
                                    isInvalid={formik.touched.HojaRecepcion && !!formik.errors.HojaRecepcion}
                                    onChange={(event) => {

                                        formik.setFieldValue(`HojaRecepcion`, event.currentTarget.files[0]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.HojaRecepcion}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>


                        <Row className="mb-3">
                            <Form.Group as={Col} controlId={`Formik-Factura`} className="position-relative">
                                <Form.Label>Titulo Americano:</Form.Label>
                                <Form.Control
                                    type="file"
                                    placeholder="factura"

                                    name={`tituloAmericano`}
                                    accept=".pdf"
                                    /*       disabled={validarCoche} */
                                    autoComplete="off"
                                    isInvalid={formik.touched.tituloAmericano && !!formik.errors.tituloAmericano}
                                    onChange={(event) => {

                                        formik.setFieldValue(`tituloAmericano`, event.currentTarget.files[0]);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.tituloAmericano}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </>
                    :
                <Row className="mb-3">
                    <Form.Group as={Col} controlId={`Formik-Factura`} className="position-relative">
                        <Form.Label>Factura:</Form.Label>
                        <Form.Control
                            type="file"
                            placeholder="factura"
                            name={`Factura`}
                            accept=".pdf"
                            /*       disabled={validarCoche} */
                            autoComplete="off"
                            isInvalid={formik.touched.Factura && !!formik.errors.Factura}
                            onChange={(event) => {
                                
                                formik.setFieldValue(`Factura`, event.currentTarget.files[0]);
                            }}
                            />
                        <Form.Control.Feedback type="invalid" tooltip>{formik.errors.Factura}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                        }
            </Modal.Body>
            <Modal.Footer>
                <div className="w-full flex justify-center">
                    <button
                        onClick={() => {
                            setShowEdit(false);
                            setValidarCoche(true);
                            formik.resetForm()
                        }}
                        className={`min-w-[400px] h-[50px] font-bold rounded-full`}
                    >
                        Cancelar
                    </button>
                    <Button
                        onClick={() => {

                            formik.submitForm();
                        }}
                        style={{ minWidth: "400px", height: "50px" }}
                        variant="secondary"
                    /* disabled={validarCoche} */
                    >
                        Aceptar
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
