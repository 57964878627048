import { useState, useEffect } from 'react';
import { View } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, TablePagination } from '@mui/material';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { FaArrowLeft } from "react-icons/fa";
import { Backdrop, CircularProgress } from '@mui/material';
import { getCurrentUser } from 'aws-amplify/auth';
import { useTramiteDataGeneral } from '../../TramiteContext';

/* const datosTramite = {
  "items": [
      {
          "TIPO": "PARTICULAR",
          "CATEGORIA": "LICENCIAS",
          "TIPO_SERVICIO": "PARTICULAR",
          "VIGENCIA": 3,
          "LEYENDA": "AUTOMOVILISTA",
          "ID": 1,
          "LETRA": "A",
          "SUBLEYENDA": "AUTO-CAMIONETA",
          "DESCRIPCION": "LICENCIA AUTOMOVILISTA"
      },
      {
          "TIPO": "PARTICULAR",
          "CATEGORIA": "LICENCIAS",
          "TIPO_SERVICIO": "PARTICULAR",
          "VIGENCIA": 3,
          "LEYENDA": "MOTOCICLISTA",
          "ID": 2,
          "LETRA": "D",
          "SUBLEYENDA": "",
          "DESCRIPCION": "LICENCIA MOTOCICLISTA"
      }
  ]
} */
export const HistorialLicencias = () => {
  const navigate = useNavigate();
  const [datos, setDatos] = useState("");
  const [loading, setLoading]= useState(false);
  const { saveJsonToLocalStorage } = useTramiteDataGeneral();
  const [datosTramite,setDatosTramite]= useState(null)
  const handleEditar=(data)=>{
    saveJsonToLocalStorage("DatosLicencias", data)
    navigate("/tramite/DPC-Licencias/DatosGenerales")
  }
  useEffect(()=>{
    const ObtenerDatosSelect = async ()=> {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      const raw = JSON.stringify({
          "categoria": "LICENCIAS"
      });
  
      const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
      };
  
      try {
          const response = await fetch("https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev/catalog/queryCategoryItems", requestOptions);
          const result = await response.json();
          console.log(result)
          if (Array.isArray(result.items)) {
              setDatosTramite( result);
          } else {
              console.error("result.items is not an array:", result.items);
              return [];
          }
      } catch (error) {
          console.error(error);
          return [];
      }
    };
    ObtenerDatosSelect()

  },[])
  function buscarPorValor( valorBuscado) {
    // Encuentra el objeto en el array que coincide con el valorBuscado
    if(datosTramite !== null){

      const objetoEncontrado = datosTramite?.items.find(item => item.ID === valorBuscado);
      
      // Devuelve el valor específico del campoADevolver si el objeto fue encontrado
      return objetoEncontrado ? objetoEncontrado.DESCRIPCION : null;
    }
    
  }
  useEffect(() => {
    const obtenerData = async () => {
      setLoading(true)
      const user = await getCurrentUser();
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "usuarioID": user.username
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev/procedures/list", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          result.items.sort(function (a, b) {
            return new Date(b.creationDate) - new Date(a.creationDate)
        });;
          console.log(result)
          setLoading(false)
          setDatos(result.items)
        })
        .catch((error) => {
          setLoading(false)
          
          console.error(error)});
    }
    obtenerData()
  }, [])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const RenderTable = (items) => {

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return (
      <View className="ViewPrincipal">
        <View className="ViewContent">
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>

            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-center">No.</TableCell>
                    <TableCell className="text-center">ID de Registro</TableCell>
                    <TableCell className="text-center">Nombre</TableCell>
                    <TableCell className="text-center">Tipo</TableCell>
                    <TableCell align="center">Tipo de Trámite</TableCell>
                    <TableCell align="center">Estatus</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderRows(items, page, rowsPerPage)}</TableBody>
              </Table>


            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={datos.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </View>
      </View>
    );
  };
  const renderRows = (items, page, rowsPerPage) => {

    return items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => {

      return (
        <TableRow key={data.id}>
          <TableCell className="text-center">{data.creationDate}</TableCell>
          <TableCell className="text-center">{data.creationDate}</TableCell>
          <TableCell className="text-center">{data.nombre}</TableCell>
          <TableCell className="text-center">{buscarPorValor(data.procedure)}</TableCell>
          <TableCell className="text-center">{`${data.type.toUpperCase()}`}</TableCell>
          <TableCell className="text-center">
            {data.estatus.toUpperCase()}
          </TableCell>
          <TableCell className="text-center">
            {data.estatus === "Aprobado" ? 
            <Button variant='link' onClick={()=> navigate("/tramite/DPC-Licencias/Pago", { state: data })}>PROCEDER AL PAGO</Button>
            :

            <Button variant='link' onClick={()=>handleEditar(data)}>EDITAR</Button>
            }
          </TableCell>

        </TableRow >

      );
    });
  };
  return (
    <>
      <div className='HeaderTramite'>
        <View className='FormTitle'>Mis Trámites</View>
      </div>
      <View className="ViewPrincipal">
        <Row className='mb-3'>
          <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
            <button type="button" onClick={() => navigate("/tramite/DPC-Licencias")}
              className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
              <FaArrowLeft /> Volver al Inicio
            </button>
          </Form.Group>

          <Form.Group as={Col} md="6">
          </Form.Group>
          <Form.Group className='d-grid gap-2' as={Col} md="3">
            <Button variant='secondary' onClick={() => navigate("/tramite/DPC-Licencias/Verificacion", {state:1})}>Crear Nueva Licencia</Button>
          </Form.Group>
        </Row>
        <View className="ViewContent">
          {datos !== "" &&

            RenderTable(datos)
          }
        </View>
      </View>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <h1>Cargando Registros</h1>
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  )
}
