import UserLayout from "./components/UserLayout";
import TramiteLayout from "./components/TramiteLayout";
//import { CreateForm } from "./views/CreateForm";
//import DynamicFormViewer from "./views/DynamicFormViewer";
import FreshServiceTicket from "./views/FreshServiceTicket";
import { Tramites } from "./views/Tramites";
import FormGases from "./components/Static_Forms/FormGases";
import { FormEmisionCon } from "./components/Static_Forms/FormEmisionCon";
import { FormDepositoResiduos } from "./components/Static_Forms/FormDepositoResiduos";
import { ConfigCuenta } from "./views/ConfigCuenta";
import { Creditos } from "./views/Creditos";
import { FormAutosNuevos } from "./components/Static_Forms/FormAutosNuevos";
import { FormExtraccionMateriales } from "./components/Static_Forms/FormExtraccionMateriales";
import { FormInscImpuestoNomina } from "./components/Static_Forms/FormInscImpuestoNomina";
import { FormRentaInmuebles } from "./components/Static_Forms/FormRentaInmuebles";
import { FormBebidasAlcoholicas } from "./components/Static_Forms/FormBebidasAlcoholicas";
import { FormImpuestoHospedaje } from "./components/Static_Forms/FormImpuestoHospedaje";
import { FormLicenciaAuto } from "./components/Static_Forms/FormLicenciaAuto";
import { FormLicenciaChoferParticular } from "./components/Static_Forms/FormLicenciaChoferParticular";
import { FormLicenciaChoferPublico } from "./components/Static_Forms/FormLicenciaChoferPublico";
import { FormLicenciaMotocicleta } from "./components/Static_Forms/FormLicenciaMotociclista";
import { FormActaNacimiento } from "./components/Static_Forms/FormActaNacimiento";
import { FormActaMatrimonio } from "./components/Static_Forms/FormActaMatrimonio";
import { FormActaDefuncion } from "./components/Static_Forms/FormActaDefuncion";
import { FormFirmasAsentadasOtrosFuncionarios } from "./components/Static_Forms/FormFirmasAsentadasOtrosFuncionarios";
import { FormCorreccionActas } from "./components/Static_Forms/FormCorreccionActas";
import { FormAvaluoCatastral } from "./components/Static_Forms/FormAvaluoCatastral";
import { FormCertificadoLibertadExistenciaGravamen } from "./components/Static_Forms/FormCertificadoLibertadExistenciaGravamen";
import { FormaActaReconocimiento } from "./components/Static_Forms/FormaActaReconocimiento";
import { FormaActaDivorcio } from "./components/Static_Forms/FormaActaDivorcio";
import { FormaActaRegistroCivil } from "./components/Static_Forms/FormaActaRegistroCivil";
import { FormaRefrendo } from "./components/Static_Forms/FormaRefrendo";
import { FormaConstanciaPagoVehicular } from "./components/Static_Forms/FormaConstanciaPagoVehicular";
import { FormaReplaqueo } from "./components/Static_Forms/FormaReplaqueo";
import { FormAcreditacionPersonalidad } from "./components/Flotillas/FormEditPersonalidad";
import { FormEditPersonalidaFisica } from "./components/Flotillas/FormEditPersonalidaFisica";
import { FormCapturaFlotilla } from "./components/Flotillas/FormCapturaFlotilla";
import { FormCapturaVehiculos } from "./components/Flotillas/FormCapturaVehiculos";
import { HistoralFlotillas } from "./components/Flotillas/HistoralFlotillas";
import { DetallesFlotillas } from "./components/Flotillas/DetallesFlotillas";
import { HistorialPersonalidades } from "./components/Flotillas/HistorialPersonalidades";
import { FormContratoCompraventa } from "./components/Static_Forms/FormContratoCompraventa";
import { FormContratoCredito } from "./components/Static_Forms/FormContratoCredito";
import { FormBusquedaRegistroDatos } from "./components/Static_Forms/FormBusquedaRegistroDatos";
import { DetallesVehiculos } from "./components/Flotillas/DetallesVehiculos";
import { FormAcreditacioPersonalidad2 } from "./components/Flotillas/FormAcreditacioPersonalidad2";
import { FormGenerador } from "./components/Static_Forms/FormGenerador";
import { HomeAltaVehicular } from "./components/Flotillas/HomeAltaVehicular";
import { FormPruebaGen } from "./components/Static_Forms/FormPruebaGen";
import { FormDireccion } from "./components/Flotillas/FormDireccion";
import { FormPagosMIT } from "./components/Flotillas/FormPagosMIT";
import { FormTramitePredial } from "./components/Static_Forms/FormTramitePredial";
import PantallaExitoImpuestos from "./components/PantallaExitoImpuestos";
import { FormPersonaFisica } from "./components/Flotillas/FormPersonaFisica";
import { FormArrendamiento } from "./components/Static_Forms/FormArrendamiento";
import { PaginaMantenimiento } from "./views/PaginaMantenimiento";
import { FormRifasloteriasysorteos } from "./components/Static_Forms/rifasloteriasysorteos";
import ValidatorQR from "./components/Static_Forms/ValidatorQR"
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
import ValidadorActas from "./components/Static_Forms/ValidadorActas";
import PruebasLive from "./components/Static_Forms/PruebasLive";
import { FormLicenciaStripe } from "./components/Static_Forms/FormLicenciaStripe";
import { MenuLicencias } from "./components/Licencias/MenuLicencias";
import { HistorialLicencias } from "./components/Licencias/HistorialLicencias";
import { TramiteLicencias } from "./components/Licencias/TramiteLicencias";
import { FormContribuyente } from "./components/Licencias/FormContribuyente";
import { FormPagos } from "./components/Licencias/FormPagos";

const AppRouter = () => (
  <Routes>

    <Route path="/Validador/ValidadorActas/:Folio" element={<ValidadorActas />} />


    <Route path="/Validador/ValidadorActas" element={<ValidadorActas />} />
    <Route element={<PrivateRoute />}>
      <Route path='/' element={<UserLayout />}>
        <Route index element={<Tramites />} />
        <Route path='/tramite-service/DPC-Alta-vehicular' element={<FreshServiceTicket />} />
        <Route path='/tramites-servicios' element={<Tramites />} />
        <Route path='/Configuracion' element={<ConfigCuenta />} />
        <Route path='/creditos-y-convenios' element={<Creditos />} />
        <Route path='/validadorQR' element={<ValidatorQR />} />
      </Route>
      <Route path='/tramite/DPC-Licencias' element={<TramiteLayout />}>
      <Route path='/tramite/DPC-Licencias' element={<MenuLicencias/>} />
      <Route path='/tramite/DPC-Licencias/Verificacion' element={<FormContribuyente/>} />
      <Route path='/tramite/DPC-Licencias/Historial' element={<HistorialLicencias/>} />
      <Route path='/tramite/DPC-Licencias/DatosGenerales' element={<TramiteLicencias/>} />
      <Route path='/tramite/DPC-Licencias/Pago' element={<FormPagos/>} />
      </Route>
      <Route path='/tramite' element={<TramiteLayout />}>
        <Route path='/tramite/PDCIE-Impuesto-emision-gases' element={<FormGases />} />
        <Route path='/tramite/PDCIE-PagoEmisionContaminantes' element={<FormEmisionCon />} />
        <Route path='/tramite/PDCIE-PagoImpuestoDepositoResiduos' element={<FormDepositoResiduos />} />
        <Route path='/tramite/PDCIE-ImpuestoAutosNuevos' element={<FormAutosNuevos />} />
        <Route path='/tramite/PDCIE-PagoImpuestoExtraccionMateriales' element={<FormExtraccionMateriales />} />
        <Route path='/tramite/PDCIE-PagoImpuestoRentaInmuebles' element={<FormRentaInmuebles />} />
        <Route path='/tramite/PDCIE-PagoImpuestoNomina' element={<FormInscImpuestoNomina />} />
        <Route path='/tramite/PDCIE-PagoImpuestoBebidasAlcoholicas' element={<FormBebidasAlcoholicas />} />
        <Route path='/tramite/PDCIE-PagoImpuestoHospedaje' element={<FormImpuestoHospedaje />} />
        <Route path='/tramite/DPC-LicenciaAutomovilista' element={<FormLicenciaAuto />} />
        <Route path='/tramite/DPC-LicenciaChoferServicioParticular' element={<FormLicenciaChoferParticular />} />
        <Route path='/tramite/DPC-LicenciaChoferServicioPublico' element={<FormLicenciaChoferPublico />} />
        <Route path='/tramite/DPC-LicenciaMotociclista' element={<FormLicenciaMotocicleta />} />
        <Route path='/tramite/DGRC-CopiaActaNacimiento' element={<FormActaNacimiento />} />
        <Route path='/tramite/DGRC-CopiaActaMatrimonio' element={<FormActaMatrimonio />} />
        <Route path='/tramite/DGRC-CopiaActaDefuncion' element={<FormActaDefuncion />} />
        <Route path='/tramite/SGG-LegalizacionFirmasOtrosFuncionarios' element={<FormFirmasAsentadasOtrosFuncionarios />} />
        <Route path='/tramite/DGRC-CorreccionActa' element={<FormCorreccionActas />} />
        <Route path='/tramite/DGC-AvaluoCatastral1a1875' element={<FormAvaluoCatastral />} />
        <Route path='/tramite/RPP-CertificadoLibreGravamen' element={<FormCertificadoLibertadExistenciaGravamen />} />
        <Route path='/tramite/DGRC-CopiaActaReconocimiento' element={<FormaActaReconocimiento />} />
        <Route path='/tramite/DGRC-CopiaActaDivorcio' element={<FormaActaDivorcio />} />
        <Route path='/tramite/DGRC-FormatoUnicoActa' element={<FormaActaRegistroCivil />} />
        <Route path='/tramite/DPC-Refrendo' element={<FormaRefrendo />} />
        <Route path='/tramite/DPC-ConstanciaVehicular' element={<FormaConstanciaPagoVehicular />} />
        <Route path='/tramite/Captura-vehiculos' element={<FormCapturaVehiculos />} />
        <Route path='/tramite/Info-Propietario' element={<FormAcreditacionPersonalidad />} />
        <Route path='/tramite/Info-Propietario-Fisica' element={<FormEditPersonalidaFisica />} />
        <Route path='/tramite/Alta-Propietario' element={<FormAcreditacioPersonalidad2 />} />
        <Route path='/tramite/Captura-Flotilla' element={<FormCapturaFlotilla />} />
        <Route path='/tramite/Historial-Flotillas' element={<HistoralFlotillas />} />
        <Route path='/tramite/Detalle-Flotillas' element={<DetallesFlotillas />} />
        <Route path='/tramite/Historial-Propietarios' element={<HistorialPersonalidades />} />
        <Route path='/tramite/DPC-Replaqueo' element={<FormaReplaqueo />} />
        <Route path='/tramite/RPP-ContratoCompraventa' element={<FormContratoCompraventa />} />
        <Route path='/tramite/RPP-ContratoCredito' element={<FormContratoCredito />} />
        <Route path='/tramite/DGRC-BusquedaRegistroDatos' element={<FormBusquedaRegistroDatos />} />
        <Route path='/tramite/Detalle-Vehiculos' element={<DetallesVehiculos />} />
        <Route path='/tramite/PDCIE-Arrendamiento' element={<FormArrendamiento />} />
        <Route path='/tramite/Generador-Autos' element={<FormGenerador />} />
        <Route path='/tramite/Alta-Vehicular' element={<HomeAltaVehicular />} />
        <Route path='/tramite/Prueba-Generador' element={<FormPruebaGen />} />
        <Route path='/tramite/Direccion-Vehicular' element={<FormDireccion />} />
        <Route path='/tramite/Pagos-MIT' element={<FormPagosMIT />} />
        <Route path='/tramite/Pruebas-Live' element={<PruebasLive/>} />
        <Route path='/tramite/Exito' element={<PantallaExitoImpuestos />} />
        <Route path='/tramite/PDCIE-PagoImpuestoPredial' element={<FormTramitePredial />} />
        <Route path='/tramite/FormPersonaFisica' element={<FormPersonaFisica />} />
        <Route path='/tramite/DRRIE-rifasloteriasysorteos' element={<FormRifasloteriasysorteos />} />
        <Route path='/tramite/DPC-LicenciaAutomovilistaStripe' element={<FormLicenciaStripe/>} />
 
        <Route path='*' element={<PaginaMantenimiento />} />
      </Route>
    </Route>
  </Routes>
);
export default AppRouter;
