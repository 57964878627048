const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;
export function CambiarStatus(data) {
    const url = `${ENDPOINT}/flotilla/updateStatus`
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    };
    return fetch(url, params).then(response => {
        return response.json();
    }).then(result => {
      
        if(result.error === undefined){

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }else{
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err => {
        return {
            ok: false,
            message: err.message
        };
    })
}
export function ObtenerExcel(id) {
    const url = `${ENDPOINT}/descargarExcel`
    const params = {
        method: "POST",
        body: JSON.stringify({
            "flotillaID": id
        }),
        headers: {
            "Content-Type": "application/json",
        }
    };
    return fetch(url, params).then(response => {
        return response.json();
    }).then(result => {
      
        if(result.error === undefined){

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }else{
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err => {
        return {
            ok: false,
            message: err.message
        };
    })
}
export function SubirExcelAPI(id) {
    const url = `${ENDPOINT}/subirCSV?flotillaID=${id}`
    const params = {
        method: "POST",
        body: JSON.stringify({
            "flotillaID": id
        }),
        headers: {
            "Content-Type": "application/json",
        }
    };
    return fetch(url, params).then(response => {
        return response.json();
    }).then(result => {
      
        if(result.error === undefined){

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }else{
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err => {
        return {
            ok: false,
            message: err.message
        };
    })
}
