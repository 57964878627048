import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const ENDPOINT_MARCAS = process.env.REACT_APP_MARCAS;

export const FormPruebaGen = () => {
  const navigate = useNavigate();
  const [sublineaRes, setSublineaRes] = useState([]);

  const fetchCodigoInfo = async (codigo) => {
    try {
      const response = await fetch(`${ENDPOINT_MARCAS}/clave?clave=${codigo}`);

      if (response.ok) {
        const data = await response.json();

        return data; // Retornar los datos obtenidos desde el endpoint
      } else {
        throw new Error("Error al obtener la información");
      }
    } catch (error) {
      throw new Error("Error al realizar la solicitud: " + error.message);
    }
  };

  const handleCodigoChange = async (codigo) => {
    // console.log('codigo',codigo);
    try {
      const data = await fetchCodigoInfo(codigo);

      if (data) {
        const sublineData = data.body;
        const sublineDataR = JSON.parse(sublineData);

        setSublineaRes(sublineDataR);
      } else {
        console.error("Error al obtener la información:");
      }
    } catch (error) {
      console.error("Error al realizar la solicitud:");
    }
  };

  return (
    <>
      <div className="ViewActa mx-8">
        <div sx={{ width: "100%" }}>
          <div className="mb-3">
            <div lassName="position-relative">
              <h2>Ingrese la Clave</h2>
              <input
                type="text"
                name="CodigoVehiculo"
                onChange={(e) => handleCodigoChange(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-3">
            <div className="position-relative">
              <h2>Marca</h2>
              <input
                name="marca"
                id="copy-target-3"
                type="text"
                value={
                  sublineaRes.sublineData && sublineaRes.markData.NOMBRE
                    ? sublineaRes.markData.NOMBRE
                    : ""
                }
              />
            </div>
          </div>

          <div className="mb-3">
            <div className="position-relative">
              <h2>Linea</h2>
              <input
                name="Linea"
                id="copy-target-4"
                type="text"
                value={
                  sublineaRes.sublineData && sublineaRes.lineData.NOMBRE
                    ? sublineaRes.lineData.NOMBRE
                    : ""
                }
              />
            </div>
          </div>

          <div className="mb-3">
            <div className="position-relative">
              <h2>Sublinea</h2>
              <input
                name="Sublinea"
                id="copy-target-5"
                type="text"
                value={
                  sublineaRes.sublineData && sublineaRes.sublineData.NOMBRE
                    ? sublineaRes.sublineData.NOMBRE
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

{
  /* <Form
            className="ViewActa mx-8"
          >
            <Box sx={{ width: "100%" }}>


                <React.Fragment>
              
                    <Box>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          
                          className="position-relative"
                        >
                          <Form.Label> Marca</Form.Label>
                          {marcaOptions?.Items &&
                          marcaOptions?.Items.length > 0 ? (
                            <Form.Select
                              name="marca"
                              value={marca}
                              onChange={(e) => {
                                console.log(e);
                                setMarca(e.target.value);
                              }}
                            >
                              <option>Seleccione...</option>
                              {marcaOptions.Items.map((option, index) => (
                                <option
                                  key={`${option.NOMBRE.S}-${index}`}
                                  value={option.sk.N}
                                >
                                  {option.NOMBRE.S}
                                </option>
                              ))}
                            </Form.Select>
                          ) : (
                            <p>No se encontraron opciones de marca.</p>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          className="position-relative"
                        >
                          <Form.Label> Linea</Form.Label>
                          {lineaOptions?.Items &&
                          lineaOptions?.Items.length > 0 ? (
                            <Form.Select
                              name="Linea"
                              value={linea}
                              onChange={(e) => setLinea(e.target.value)}
                            >
                              <option>Seleccione...</option>
                              {lineaOptions.Items.map((option, index) => (
                                <option
                                  key={`${option.NOMBRE.S}-${index}`}
                                  value={option.sk.N}
                                >
                                  {option.NOMBRE.S}
                                </option>
                              ))}
                            </Form.Select>
                          ) : (
                            <p>No se encontraron opciones de línea.</p>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          className="position-relative"
                        >
                          <Form.Label> Sublinea</Form.Label>
                          {sublineaOptions?.Items &&
                          sublineaOptions?.Items.length > 0 ? (
                            <Form.Select
                              name="Sublinea"
                              value={sublinea}
                              onChange={(e) => setSublinea(e.target.value)}
                            >
                              <option>Seleccione...</option>
                              {sublineaOptions.Items.map((option, index) => (
                                <option
                                  key={`${option.NOMBRE.S}-${index}`}
                                  value={option.sk.N}
                                >
                                  {option.NOMBRE.S}
                                </option>
                              ))}
                            </Form.Select>
                          ) : (
                            <p>No se encontraron opciones de sublínea.</p>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="position-relative">
                          <Form.Label>Resultado</Form.Label>
                          <Form.Control
                            type="text"
                            name="ClaveGenerada"
                            id="copy-target-2"
                            value={
                              (sublineaOptions.Items &&
                                sublineaOptions.Items.find(
                                  (option) => option.sk.N === sublinea
                                )?.CLAVE.S) ||
                              ""
                            }
                            onChange={(e) => setSublinea(e.target.value)}
                          />
                        </Form.Group>
                      </Row>
                    </Box>
                    </React.Fragment>
              </Box>
          </Form> */
}

{
  /* <Form
            className="ViewActa mx-8"
          >
            <Box sx={{ width: "100%" }}>
              <Row className="mb-3">
                <Form.Group as={Col} className="position-relative">
                  <Form.Label>Ingrese la Clave</Form.Label>
                  <Form.Control
                    type="text"
                    name="CodigoVehiculo"
                    id="copy-target-2"
                    onChange={(e) => handleCodigoChange(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  className="position-relative"
                >
                  <Form.Label> Marca</Form.Label>

                  <Form.Control
                    name="marca"
                    id="copy-target-3"
                    type="text"
                    value={
                      sublineaRes.sublineData && sublineaRes.markData.NOMBRE
                        ? sublineaRes.markData.NOMBRE
                        : ""
                    }
                    readOnly
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  className="position-relative"
                >
                  <Form.Label> Linea</Form.Label>
                  <Form.Control
                    name="Linea"
                    id="copy-target-4"
                    type="text"
                    value={
                      sublineaRes.sublineData && sublineaRes.lineData.NOMBRE
                        ? sublineaRes.lineData.NOMBRE
                        : ""
                    }
                    readOnly
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="position-relative">
                  <Form.Label> Sublinea</Form.Label>
                  <Form.Control
                    name="Sublinea"
                    id="copy-target-5"
                    type="text"
                    value={
                      sublineaRes.sublineData && sublineaRes.sublineData.NOMBRE
                        ? sublineaRes.sublineData.NOMBRE
                        : ""
                    }
                    readOnly
                  />
                </Form.Group>
              </Row>
            </Box>
            <script
              type="text/javascript"
              src="../node_modules/tw-elements/dist/js/tw-elements.umd.min.js"
            ></script>
          </Form> */
}

// <div>
//   <h2>Generador</h2>
//   <div className="mb-3">
//     <div className="position-relative">
//       <h2>Ingrese la Clave</h2>
//       <input
//         type="text"
//         name="CodigoVehiculo"
//         id="copy-target-2"
//         onChange={(e) => handleCodigoChange(e.target.value)}
//       />
//     </div>
//   </div>

//   <div className="mb-3">
//     <div className="position-relative">
//       <h2>Marca</h2>
//       <input
//         name="marca"
//         id="copy-target-3"
//         type="text"
//         value={
//           sublineaRes.sublineData && sublineaRes.markData.NOMBRE
//             ? sublineaRes.markData.NOMBRE
//             : ""
//         }
//         readOnly
//       />
//     </div>
//   </div>

//   <div className="mb-3">
//     <div className="position-relative">
//       <h2>Linea</h2>
//       <input
//         name="Linea"
//         id="copy-target-4"
//         type="text"
//         value={
//           sublineaRes.sublineData && sublineaRes.lineData.NOMBRE
//             ? sublineaRes.lineData.NOMBRE
//             : ""
//         }
//         readOnly
//       />
//     </div>
//   </div>

//   <div className="mb-3">
//     <div className="position-relative">
//       <h2>Sublinea</h2>
//       <input
//         name="Sublinea"
//         id="copy-target-5"
//         type="text"
//         value={
//           sublineaRes.sublineData && sublineaRes.sublineData.NOMBRE
//             ? sublineaRes.sublineData.NOMBRE
//             : ""
//         }
//         readOnly
//       />
//     </div>
//   </div>
// </div>
