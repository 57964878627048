import React from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
export const FormDatosContacto = (props) => {
    const navigate = useNavigate();
    const { datos,setChangeData, changeData}= props;
    const schema = yup.object().shape({
        tipoLicencia: yup.string().required("Este valor es Obligatorio"),
        tipoTramite: yup.string().required("Este valor es Obligatorio"),
        numTelefono: yup.string().required("Este Campo es Obligatorio"),
        email: yup.string().required("Este valor es Obligatorio"),
    });
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "numTelefono") {
            values = values.replace(/[^0-9]/g, '');

            setFieldValue(name, values);
        } else {

            values = values.toUpperCase();

            setFieldValue(name, values);
        }
    };
    return (
        <>

            <View className="ViewPrincipal">
                <View className="ViewContent">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            tipoTramite: datos.type,
                            tipoLicencia: datos.procedure,
                            numTelefono: datos.datosContacto !== null ? datos.datosContacto.telefono : "",
                            email: datos.datosContacto !== null ? datos.datosContacto.correo : "",
                        }}
                        onSubmit={async values => {

                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": "nueva",
                                "usuarioID": user.username,
                                "datosContacto": {
                                    "telefono": values.numTelefono,
                                    "correo": values.email
                                },

                            }
                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setChangeData(!changeData)
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                            }).catch(error => {
                                console.log(error)
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Licencia</Form.Label>
                                        <Form.Select
                                            name='tipoLicencia'
                                            type='text'
                                            value={values.tipoLicencia}
                                            onChange={handleChange}
                                            disabled
                                            isValid={touched.tipoLicencia && !errors.tipoLicencia}
                                            isInvalid={touched.tipoLicencia && !!errors.tipoLicencia}
                                        >
                                            <OpcionesSelect id={"LICENCIAS"} valor={"categoria"}/>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoLicencia}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Tramite</Form.Label>
                                        <Form.Control
                                            name='tipoTramite'
                                            type='text'
                                            disabled
                                            value={values.tipoTramite.toUpperCase()}
                                            onChange={handleChange}
                                            isValid={touched.tipoTramite && !errors.tipoTramite}
                                            isInvalid={touched.tipoTramite && !!errors.tipoTramite}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoTramite}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Label style={{ textDecoration: "underline" }}>Datos de Contacto</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Número de Telefono*</Form.Label>

                                        <Form.Control
                                            name='numTelefono'
                                            type='text'
                                            placeholder='Número de Telefono'
                                            value={values.numTelefono}
                                            disabled={datos.datosContacto !== null ? true : false}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            isValid={touched.numTelefono && !errors.numTelefono}
                                            isInvalid={touched.numTelefono && !!errors.numTelefono}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.numTelefono}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Correo*</Form.Label>
                                        <Form.Control
                                            name='email'
                                            type='email'
                                            placeholder='Correo'
                                            disabled={datos.datosContacto !== null ? true : false}
                                            value={values.email}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={touched.email && !!errors.email}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className='mb-3'>
                                    <Button variant='secondary'  disabled={datos.datosContacto !== null ? true : false} type='submit'>GUARDAR</Button>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='link'>REGRESAR</Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
        </>
    )
}
