import React, { useState } from 'react'
import { Modal, Form, Button, Col } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;
export const ModalCSV = (props) => {
    const { show, setShow, location, changeInfo, setChangeInfo, rowsLength } = props;
    const [eliminar, setEliminar] = useState("No")
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formik = useFormik({
        initialValues: {
            CSV: "",
        },
        validationSchema: yup.object({
            CSV: yup
                .mixed()
                .test('fileType', 'Solo se permiten archivos CSV', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    return value.type === 'text/csv';
                })
                .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                    if (!value) {
                        return true; // Permitir si no hay archivo
                    }

                    const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                    return value.size <= maxSize;
                }),
        }),
        onSubmit: (values) => {
            subirFacturaCsv(values);
        },
    });


    const subirFacturaCsv = (values) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/csv");

        var file = values.CSV;

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: file,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/subirCSV?flotillaID=${location.id.S}/${eliminar}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.s3_url !== undefined) {
                    setShow(false);
                    setTimeout(()=>{
                        setChangeInfo(!changeInfo)
                    },5000)
                    Swal.fire({
                        title: "Archivo enviado correctamente",
                        text: "El proceso continuará en segundo plano. Al finalizar, recibirás un correo electrónico con los detalles de los vehículos.",
                        icon: "success",
                        confirmButtonColor: "#6c757d"
                    })
                    // .then((result) => {
                    //     if (result.isConfirmed) {
                    //         setShow(false);
                    //         setTimeout(()=>{
                    //             setChangeInfo(!changeInfo)
                    //         },5000)
                    //     }
                    // })
                    /*     let timerInterval;
                       Swal.fire({
                           title: "Creación de Flotilla",
                           html: "Será redirigido a la pantalla de inicio del trámite Emplacamiento Exprés y recibirá una notificación con el resultado de los datos procesados.",
                           timer:10000,
                           timerProgressBar: true,
                           didOpen: () => {
                             Swal.showLoading();
                           },
                           willClose: () => {
                               clearInterval(timerInterval);
                             }
                         }).then((result) => {
                           if (result.dismiss === Swal.DismissReason.timer) {
                               navigate("/tramite/Historial-Flotillas")
                        }
                       });  */

                } else {
                    toast.error("Hubo un error al cargar el CSV", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
            .catch((error) => console.log("error", error));
          
            
    };

    const handleCerrar = () => {
        setShow(false);
        formik.resetForm()
    }

    const handleEliminar = () => {
        Swal.fire({
            title: "¿Desea eliminar los vehículos registrados?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#6c757d",


        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setEliminar("Si")
                setIsSubmitting(true);
                formik.handleSubmit()
            } else if (result.isDenied) {
                setEliminar("No")
                setIsSubmitting(true);
                formik.handleSubmit()
            }
        });
    }
    return (
        <Modal show={show} animation={false}>
            <Modal.Header>
                <Modal.Title>Flotilla CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Col} className='position-relative'>
                    <Form.Label>CSV de Flotilla</Form.Label>
                    <Form.Control
                        type="file"
                        name='CSV'
                        accept='.csv'
                        isInvalid={formik.touched.CSV && !!formik.errors.CSV}
                        onChange={(event) => {
                            formik.setFieldValue('CSV', event.currentTarget.files[0]);
                        }}
                    />
                    <Form.Control.Feedback type='invalid' tooltip>{formik.errors.CSV}</Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "space-between" }}>
                <Button variant='link' onClick={handleCerrar}>Cancelar</Button>
                <Button
                    variant="secondary"
                    onClick={() => {
                        if (!isSubmitting) { 
                            setIsSubmitting(true); 
                            if (rowsLength !== 0) {
                                handleEliminar();
                            } else {
                                setEliminar("No");
                                formik.handleSubmit();
                            }
                        }
                    }}
                    disabled={isSubmitting}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}