import { useState, useEffect } from 'react'
import { Button, Form, Row, Col, Modal, InputGroup } from 'react-bootstrap';
import { View } from '@aws-amplify/ui-react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from 'axios';
import { Loader } from '@aws-amplify/ui-react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import Revisado from '../../assets/Revision.png'
import Bloqueado from '../../assets/Bloqueado.png'
import Inactivo from '../../assets/Inactivo.png'
import Rechazado from '../../assets/Rechazado.png'
import Aprobado from '../../assets/Aprobado.png'
import Pagado from '../../assets/Pagado.png'
import Captura from '../../assets/Captura.png'
import ListoPago from '../../assets/ListoParaPago.png'
import Asignacion from '../../assets/AsignaciondeFormas.png'
import ListoEnvio from '../../assets/ListoParaEnvio.png'
import { FaArrowLeft } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import "../../App.css";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;
export const HistoralFlotillas = () => {
    const [info, setInfo] = useState([]);
    const { user } = useAuthenticator((context) => [context.user]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [reload, setReload] = useState(false);
    const [helpView, setHelpView] = useState(false);
    const [openAccordion, setOpenAccordion] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;/* 
    const [numPages, setNumPages]= useState(currentItems.length !== 0 ? currentItems.length :  info.Items.length ) */
    useEffect(() => {
        const payload = {

            "ticketType": "16",
            "usuarioID": user.username
        }

        axios.post(`${ENDPOINT}/listaPersonalidad`, payload).then(response => {
            response.data.Items.sort(function (a, b) {
                return new Date(b.createdAt.S) - new Date(a.createdAt.S)
            });
            setInfo(response.data.Items);
            setLoading(false);
            setReload(false)
           
        }).catch(error => {
            setLoading(false);
            console.log(error);
        })
    }, [reload])
    const [filters, setFilters] = useState({
        ticketID: '',
        rfc: '',
        /*  razon_social: '', */
        status: ""
        // Agrega más campos según tus necesidades
    });
    if (!info) {
        // Manejo de casos donde info o info.Items es undefined
        return null; // o algún otro manejo de error que prefieras
    }

    const handleFilterChange = (filterName, value) => {
        setFilters({ ...filters, [filterName]: value });
        setCurrentPage(1)
    };

    const filteredData = info.filter(item => {
        
        return (
          item.ticketID.N.toLowerCase().includes(filters.ticketID.toLowerCase()) &&
          item.rfc.S.toLowerCase().includes(filters.rfc.toLowerCase()) &&
         /*  item.razon_social.S.toLowerCase().includes(filters.razon_social.toLowerCase())&& */
          item.status.S.toLowerCase().includes(filters.status.toLowerCase())
    
          // Agrega más condiciones según tus necesidades
        );
      });

   
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    const handleChange = (event, value) => {
        setCurrentPage(value);

    };

    const handleAccordionToggle = (index) => {
        const updatedAccordion = [...openAccordion];
        updatedAccordion[index] = !updatedAccordion[index];
        setOpenAccordion(updatedAccordion);
    };


    const GoToDetails = (data) => {
        navigate("/tramite/Detalle-Flotillas", { state: data })
    }
    const getIconStatus = (state) => {
        switch (state) {
            case 'Revisión':
                return <img src={Revisado} alt='Status' />;
            case 'Aprobado':
                return <img src={Aprobado} alt='Status' />;
            case 'Rechazado':
                return <img src={Rechazado} alt='Status' />;
            case 'Bloqueado':
                return <img src={Bloqueado} alt='Status' />;
            case 'Inactivo':
                return <img src={Inactivo} alt='Status' />;
            case 'Pagado':
                return <img src={Pagado} alt='Status' />;
            case 'Captura':
                return <img src={Captura} alt='Status' />
            case 'Generar presupuestación':
                return <img src={ListoPago} alt='Status' />
            case 'Asignación de formas':
                return <img src={Asignacion} alt='Status' />
            case 'Listo para envío':
                return <img src={ListoEnvio} alt='Status' />
            default:
                // You can return a default icon or handle the default case as needed
                return null;
        }
    }

    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Emplacamiento Exprés</View>
                <View className='FormSubTitle'>Mis Flotillas</View>
                <View className='FormDescripcion'>
                    En esta pantalla podrá consultar el estado del proceso de Acreditación Vehícular y seguimiento de sus Flotillas registradas.
                    <HelpOutlineIcon style={{ padding: '5px', width: "30px", height: "30px" }} className='cursor- cursor-pointer' onClick={() => setHelpView(true)} />
                </View>
            </div>

            <View className="ViewPrincipal">
                <View className="ViewContent" >

                    <Row className='mb-3'>
                        <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                            <button type="button" onClick={() => navigate("/tramite/Alta-Vehicular")}
                                className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                                <FaArrowLeft /> Volver al Inicio
                            </button>
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                        </Form.Group>
                        <Form.Group className='d-grid gap-2' as={Col} md="3">
                            <Button variant='secondary' onClick={() => navigate("/tramite/Captura-Flotilla")}>
                                Registrar Nueva Flotilla
                            </Button>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="3" className='mb-2'>



                       
                                <Form.Control
                                    type="text"
                                    name="Folio"
                                    value={filters.ticketID}
                                    onChange={e => handleFilterChange('ticketID', e.target.value)}
                                    placeholder="Folio"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                />

                             
                        </Form.Group>

                        <Form.Group as={Col} md="4" className='mb-2'>
                            <Form.Control
                                type="text"
                                value={filters.rfc}
                                onChange={e => handleFilterChange('rfc', e.target.value)}
                                placeholder="RFC"
                                name="rfc"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Select
                                type="text"
                                onChange={e => handleFilterChange('status', e.target.value)}
                                value={filters.status}
                                name="status"
                            >
                                <option value="">Seleccionar Estado</option>
                                <option value="Captura">Captura</option>
                                <option value="En Proceso">En Proceso</option>
                                <option value="Rechazado">Rechazado</option>
                                <option value="Listo para presupuestar">Aprobada</option>
                                <option value="Pre-alta">Pre-alta</option>
                                <option value="Generar presupuestación">Listo para pago</option>
                                <option value="Pendiente de Pago">Pendiente de Pago</option>
                                <option value="Procesando el pago">Procesando pago</option>
                                <option value="Pagado">Pagado</option>
                                <option value="Enviado">Enviado</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="1" style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                            <Button variant="link" onClick={() => setReload(true)} ><TfiReload /></Button>
                        </Form.Group>
                    </Row>
                    <div className={`accordion-list ${!reload ? 'open' : ''}`}>
                        {currentItems.length !== 0 &&
                            currentItems.map((data, index) => (
                                data.ticketID !== undefined && (
                                    <div key={index} className="mb-3 border border-gray-300 rounded overflow-hidden">
                                        <div
                                            className="cursor-pointer bg-[#e0e6e0] py-2 px-4 flex justify-between items-center"
                                            onClick={() => handleAccordionToggle(index)}
                                        >
                                            <label className="text-lg font-bold underline">Flotilla: {data.ticketID.N ? `#${data.ticketID.N}` : 'Pendiente'}</label>
                                            <span className="flex items-center font-bold underline">
                                                Estado: {getIconStatus(data.status.S)} {data.status.S === "Generar presupuestación" ? "Listo para pago" : data.status.S === "Listo para presupuestar" ? "Aprobada" : data.status.S}
                                            </span>
                                        </div>
                                        {!openAccordion[index] && (
                                            <div className="p-4 flex justify-between" id="DivAcordeon">
                                                <div className="mb-3" >
                                                    <label className="block font-bold underline">RFC del Propietario</label>
                                                    <span>{data.rfc.S}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="block font-bold underline">Número de vehículos en flotilla</label>
                                                    <span>{data.cantidadVehiculos.N}</span>
                                                </div>
                                                {data.status.S === "Captura" ?

                                                    <div className="flex justify-end">
                                                        <Button className="h-10" variant='secondary' onClick={() => navigate("/tramite/Captura-vehiculos", { state: data })}> Continuar</Button>
                                                    </div>
                                                    : data.status.S === "Generar presupuestación" ?
                                                        <div>
                                                            <div className="flex justify-center mb-1">
                                                                <Button className="h-10" variant='secondary' onClick={() => navigate("/tramite/Direccion-Vehicular", { state: data })}> Continuar al Pago</Button>
                                                            </div>
                                                            <div className="flex justify-center">
                                                                <Button className="h-10" variant='secondary' disabled={data.status.S === "En Proceso" ? true : data.status.S ==="Plantilla" ? true : false} onClick={() => GoToDetails(data)}> Ver Detalles</Button>
                                                            </div>
                                                        </div>

                                                        : data.status.S === "Plantilla" ?
                                                        <div className="flex justify-end">
                                                        <Button className="h-10" variant='secondary' onClick={() => navigate("/tramite/Captura-vehiculos", { state: data })}> Continuar</Button>
                                                    </div>
                                                        :
                                                        <div className="flex justify-end">
                                                            <Button className="h-10" variant='secondary' disabled={data.status.S === "En Proceso" ? true : data.status.S ==="Plantilla" ? true : false} onClick={() => GoToDetails(data)}> Ver Detalles</Button>
                                                        </div>

                                                }
                                            </div>
                                        )}
                                    </div>
                                )
                            ))}
                    </div>

                    {filteredData.length > itemsPerPage && (
                        <Stack spacing={2} justifyContent="center" alignItems="center" direction="row">
                            <Pagination count={Math.ceil(filteredData.length / itemsPerPage)} page={currentPage} onChange={handleChange} />
                        </Stack>
                    )}
                </View>
            </View>
          {/*   <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <h1>Cargando Información</h1>
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <Modal show={helpView} animation={false} onHide={() => setHelpView(false)} centered size='lg'>
                <Modal.Header >
                    <Modal.Title>La flotilla puede transitar por los siguientes estados como parte del Programa de Emplacamiento Exprés: </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Captura</strong> – La información de los vehículos de la flotilla se encuentra en proceso de captura por parte del contribuyente<br></br>
                        <strong>En Proceso</strong> – La flotilla está en proceso de generación de su expediente digital<br></br>
                        <strong>Pre-Alta</strong> - La flotilla se encuentra asignada a un equipo de analistas vehiculares y se encuentra en proceso de acreditación<br></br>
                        <strong>Rechazado</strong> – Uno de los vehículos de la flotilla ha recibido retroalimentación como parte de su proceso de acreditación<br></br>
                        <strong>Aprobada</strong> –La flotilla ha finalizado el proceso de acreditación<br></br>
                        <strong>Listo para Pago</strong> – Se ha generado el presupuesto de pago de los vehículos de la flotilla que han sido aprobados <br></br>
                        <strong>Pendiente de Pago</strong> – Se espera confirmación del pago o transferencia de la flotilla <br></br>
                        <strong>Procesando el Pago</strong> –El pago de la flotilla está siendo procesado <br></br>
                        <strong>Pagado</strong> – El pago de la flotilla ha sido procesado exitosamente y la información de la flotilla será enriquecida con las referencias de facturación correspondientes<br></br>
                        <strong>Enviado</strong> -Las placas y formas de la flotilla han sido enviadas a través de una empresa de paquetería y los números de rastreo pueden ser consultados mediante la página de seguimiento de la flotilla<br></br>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setHelpView(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}
