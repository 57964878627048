import React, { useEffect } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
export const FormDatosMedicos = (props) => {
    const navigate = useNavigate();
    const { datos, setChangeData, changeData } = props;
    const schema = yup.object().shape({
        tipoLicencia: yup.string(),
        tipoTramite: yup.string(),
        tipoSangre: yup.string().required("Este valor es Obligatorio"),
        estatura: yup.string().required("Este valor es Obligatorio"),
        complexion: yup.string().required("Este valor es Obligatorio"),
        colorOjos: yup.string().required("Este Campo es Obligatorio"),
        piel: yup.string().required("Este Campo es Obligatorio"),
        requiereLentes: yup.string().required("Este Campo es Obligatorio"),
        donadorOrganos: yup.string().required("Este valor es Obligatorio"),
    });



    return (
        <>


            <View className="ViewPrincipal">

                <View className="ViewContent">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            tipoTramite: datos.type,
                            tipoLicencia: datos.procedure,
                            tipoSangre: datos.mediaFiliacion !== null ? datos.mediaFiliacion.tipoSangre : "",
                            estatura: datos.mediaFiliacion !== null ? datos.mediaFiliacion.estatura : "",
                            complexion: datos.mediaFiliacion !== null ? datos.mediaFiliacion.complexion : "",
                            colorOjos: datos.mediaFiliacion !== null ? datos.mediaFiliacion.colorOjos : "",
                            piel: datos.mediaFiliacion !== null ? datos.mediaFiliacion.piel : "",
                            requiereLentes: datos.mediaFiliacion !== null ? datos.mediaFiliacion.requiereLentes : "",
                            donadorOrganos: datos.mediaFiliacion !== null ? datos.mediaFiliacion.donadorOrganos : "",
                        }}
                        onSubmit={async values => {
                            console.log(values);
                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": "nueva",
                                "usuarioID": user.username,
                                "mediaFiliacion": {
                                    "tipoSangre": values.tipoSangre,
                                    "estatura": values.estatura,
                                    "complexion": values.complexion,
                                    "colorOjos": values.colorOjos,
                                    "piel": values.piel,
                                    "requiereLentes": values.requiereLentes === "SI" ? true : false,
                                    "donadorOrganos": values.donadorOrganos === "SI" ? true : false
                                },
                            }
                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setChangeData(!changeData)
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                            }).catch(error => {
                                console.log(error)
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors }) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Licencia</Form.Label>
                                        <Form.Select
                                            name='tipoLicencia'
                                            type='text'
                                            value={values.tipoLicencia}
                                            onChange={handleChange}
                                            disabled
                                            isValid={touched.tipoLicencia && !errors.tipoLicencia}
                                            isInvalid={touched.tipoLicencia && !!errors.tipoLicencia}
                                        >
                                            <OpcionesSelect id={"LICENCIAS"} valor={"categoria"} />
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoLicencia}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Tramite</Form.Label>
                                        <Form.Control
                                            name='tipoTramite'
                                            type='text'
                                            value={values.tipoTramite.toUpperCase()}
                                            onChange={handleChange}
                                            disabled
                                            isValid={touched.tipoTramite && !errors.tipoTramite}
                                            isInvalid={touched.tipoTramite && !!errors.tipoTramite}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoTramite}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Label style={{ textDecoration: "underline" }}>Media Filiación</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Tipo de Sangre*</Form.Label>
                                        <Form.Select
                                            name='tipoSangre'
                                            type='text'
                                            value={values.tipoSangre}
                                            onChange={handleChange}
                                            disabled={datos.mediaFiliacion !== null ? true : false}
                                            isValid={touched.tipoSangre && !errors.tipoSangre}
                                            isInvalid={touched.tipoSangre && !!errors.tipoSangre}
                                        >
                                            {datos.mediaFiliacion === null ?

                                                <option value="">SELECCIONA</option>


                                                :
                                                null
                                            }
                                            <OpcionesSelect id={8} />

                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.tipoSangre}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Estatura*</Form.Label>
                                        <Form.Select
                                            name='estatura'
                                            type='text'
                                            placeholder='Estatura'
                                            value={values.estatura}
                                            onChange={handleChange}
                                            disabled={datos.mediaFiliacion !== null ? true : false}
                                            isValid={touched.estatura && !errors.estatura}
                                            isInvalid={touched.estatura && !!errors.estatura}
                                        >
                                            {datos.mediaFiliacion === null ?
                                                <option value="">SELECCIONA</option>
                                                :
                                                null
                                            }
                                            <OpcionesSelect id={9} />
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.estatura}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Complexion*</Form.Label>
                                        <Form.Select
                                            name='complexion'
                                            type='text'
                                            placeholder='Complexion'
                                            value={values.complexion}
                                            onChange={handleChange}
                                            disabled={datos.mediaFiliacion !== null ? true : false}
                                            isValid={touched.complexion && !errors.complexion}
                                            isInvalid={touched.complexion && !!errors.complexion}
                                        >
                                            {datos.mediaFiliacion === null ?

                                                <option value="">SELECCIONA</option>

                                                :
                                                null
                                            }
                                            <OpcionesSelect id={10} />
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.complexion}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Color de Ojos*</Form.Label>
                                        <Form.Select
                                            name='colorOjos'
                                            type='text'
                                            placeholder='Color de Ojos'
                                            value={values.colorOjos}
                                            onChange={handleChange}
                                            disabled={datos.mediaFiliacion !== null ? true : false}
                                            isValid={touched.colorOjos && !errors.colorOjos}
                                            isInvalid={touched.colorOjos && !!errors.colorOjos}
                                        >
                                            {datos.mediaFiliacion === null ?

                                                <option value="">SELECCIONA</option>
                                                :
                                                null
                                            }
                                            <OpcionesSelect id={11} />
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.colorOjos}</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Color de Piel*</Form.Label>
                                        <Form.Select
                                            name='piel'
                                            type='text'
                                            value={values.piel}
                                            onChange={handleChange}
                                            disabled={datos.mediaFiliacion !== null ? true : false}
                                            isValid={touched.piel && !errors.piel}
                                            isInvalid={touched.piel && !!errors.piel}
                                        >
                                            {datos.mediaFiliacion === null ?

                                                <option value="">SELECCIONA</option>
                                                :
                                                null}
                                            <OpcionesSelect id={12} />
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.piel}</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Requiere Lentes*</Form.Label>
                                        <Form.Select
                                            name='requiereLentes'
                                            type='text'
                                            value={values.requiereLentes}
                                            onChange={handleChange}
                                            disabled={datos.mediaFiliacion !== null ? true : false}
                                            isValid={touched.requiereLentes && !errors.requiereLentes}
                                            isInvalid={touched.requiereLentes && !!errors.requiereLentes}
                                        >
                                            {datos.mediaFiliacion === null ?
                                                <>
                                                    <option value="">SELECCIONA</option>
                                                </>
                                                :
                                                null
                                            }
                                            <OpcionesSelect id={21} />

                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.requiereLentes}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Donador de Organos*</Form.Label>
                                        <Form.Select
                                            name='donadorOrganos'
                                            type='text'
                                            value={values.donadorOrganos}
                                            onChange={handleChange}
                                            disabled={datos.mediaFiliacion !== null ? true : false}
                                            isValid={touched.donadorOrganos && !errors.donadorOrganos}
                                            isInvalid={touched.donadorOrganos && !!errors.donadorOrganos}
                                        >
                                            {datos.mediaFiliacion === null ?
                                                    <option value="">SELECCIONA</option>
                                                :
                                                null
                                            }
                                            <OpcionesSelect id={22} />

                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.donadorOrganos}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className='mb-3'>
                                    <Button variant='secondary' disabled={datos.mediaFiliacion !== null ? true : false} type='submit'>GUARDAR</Button>
                                </Row>
                                <Row className='mb-3'>
                                    <Button variant='link'>REGRESAR</Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
        </>
    )
}
