import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const NameFieldValid = ({ nombre, domicilio = null }) => {
    return (
        <div className='flex flex-col border-solid border border-[#ecf0f4] rounded-md h-10 mb-8' >
            <div className='flex justify-between mt-2' style={{display:"flex", alignItems:"center"}}>
                <div className='font-semibold text-xs text-[#a1afc1] ml-2' style={{overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>*{nombre}</div>
                <div style={{display:"flex", alignItems:"center"}} >
                    <span className='text-[#a0aec0] text-sm font-semibold'>VALIDADO</span>
                    <CheckCircleOutlineIcon  sx={{ color: '#767676' }} />
                </div>
            </div>
            {domicilio && <div >
                <div className='font-semibold text-xs text-[#a1afc1] mt-2 ml-2'>Domicilio Fiscal: {domicilio}</div>
            </div>}

        </div>
    );
};

export default NameFieldValid;