const ENDPOINT_SEI = "https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev"


export function CrearIteamAPI( data) {
    const url = `${ENDPOINT_SEI}/procedures/upsert`
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    };
    return fetch(url, params).then(response => {
        return response.json();
    }).then(result => {
      
        if(result.error === undefined){

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }else{
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err => {
        return {
            ok: false,
            message: err.message
        };
    })
}