import React from 'react'
import { View } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'
import IconAuto from "../../assets/licenciaAuto.png"
import IconMoto from "../../assets/LicenciaMoto.png"
import IconTramites from "../../assets/Tramites.png"
import '../../App.css'
export const MenuLicencias = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Trámite de Licencias</View>
                <View className='FormSubTitle'>Selección del tipo de trámite</View>
                <View className="FormDescripcion" textDecoration={'underline'}>Selecciona el tipo de licencia a tramitar</View>
            </div>

            <View className="ViewPrincipal">
                <View className="ViewContent">
                    <ul className='DivMenuLicencias'>
                        <li onClick={() => navigate("/tramite/DPC-Licencias/Verificacion", {state:1})}>
                            <div >
                                <img src={IconAuto} alt="LOGO" width={80} />
                            </div>
                            <label >Licencia de Automovilista</label>
                        </li>
                        <li onClick={() => navigate("/tramite/DPC-Licencias/Verificacion", {state:2})}>
                            <div >
                                <img src={IconMoto} alt="LOGO" width={80} />
                            </div>
                            <label>Licencia de Motociclista</label>
                        </li>
                        <li onClick={() => navigate("/tramite/DPC-Licencias/Historial")}>
                            <div >
                                <img src={IconTramites} alt="LOGO" width={80} />
                            </div>
                            <label>Mi Historial de Licencias</label>
                        </li>
                    </ul>
                </View>
            </View>
        </>
    )
}
