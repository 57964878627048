import React from 'react';
import TimelineEvent from './TimelineEvent';

const Timeline = ({ events }) => {
   

  return (
    <div className="chat-timeline">
      {events !== "" &&
        JSON.parse(events).reverse().map((event, index) => (
          
          <TimelineEvent key={index} date={event.date} message={event.content} />
          ))
        }
    </div>
  );
};

export default Timeline;
