/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const receiveMessage = /* GraphQL */ `
  mutation ReceiveMessage(
    $id: ID!
    $payload: AWSJSON
    $updatedAt: AWSDateTime
  ) {
    receiveMessage(id: $id, payload: $payload, updatedAt: $updatedAt) {
      id
      payload
      updatedAt
    }
  }
`;
export const publishMessage = /* GraphQL */ `
  mutation PublishMessage(
    $id: ID!
    $payload: AWSJSON
    $updatedAt: AWSDateTime
  ) {
    publishMessage(id: $id, payload: $payload, updatedAt: $updatedAt) {
      id
      payload
      updatedAt
    }
  }
`;
export const createFormularios = /* GraphQL */ `
  mutation CreateFormularios(
    $input: CreateFormulariosInput!
    $condition: ModelFormulariosConditionInput
  ) {
    createFormularios(input: $input, condition: $condition) {
      id
      dependencia
      tramite
      api
      campos
      metodo
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      payload
      updatedAt
    }
  }
`;
export const deleteFormularios = /* GraphQL */ `
  mutation DeleteFormularios(
    $input: DeleteFormulariosInput!
    $condition: ModelFormulariosConditionInput
  ) {
    deleteFormularios(input: $input, condition: $condition) {
      id
      dependencia
      tramite
      api
      campos
      metodo
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      payload
      updatedAt
    }
  }
`;
export const updatePerfiles = /* GraphQL */ `
  mutation UpdatePerfiles(
    $input: UpdatePerfilesInput!
    $condition: ModelPerfilesConditionInput
  ) {
    updatePerfiles(input: $input, condition: $condition) {
      id
      owner
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deletePerfiles = /* GraphQL */ `
  mutation DeletePerfiles(
    $input: DeletePerfilesInput!
    $condition: ModelPerfilesConditionInput
  ) {
    deletePerfiles(input: $input, condition: $condition) {
      id
      owner
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createTransacciones = /* GraphQL */ `
  mutation CreateTransacciones(
    $input: CreateTransaccionesInput!
    $condition: ModelTransaccionesConditionInput
  ) {
    createTransacciones(input: $input, condition: $condition) {
      id
      owner
      tramite
      amount
      status
      paymenturl
      createdAt
      updatedAt
    }
  }
`;
export const updateTransacciones = /* GraphQL */ `
  mutation UpdateTransacciones(
    $input: UpdateTransaccionesInput!
    $condition: ModelTransaccionesConditionInput
  ) {
    updateTransacciones(input: $input, condition: $condition) {
      id
      payload
      updatedAt
    }
  }
`;
export const deleteTransacciones = /* GraphQL */ `
  mutation DeleteTransacciones(
    $input: DeleteTransaccionesInput!
    $condition: ModelTransaccionesConditionInput
  ) {
    deleteTransacciones(input: $input, condition: $condition) {
      id
      owner
      tramite
      amount
      status
      paymenturl
      createdAt
      updatedAt
    }
  }
`;
